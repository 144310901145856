import { call, put, takeEvery } from "redux-saga/effects";
import { API_CODE } from "../../helpers/enum";
import { postContactUS } from "../../helpers/fakebackend_helper";
import { contactUSSuccessful, contactUSField } from "./action";
import { CONTACT_US } from "./actionTypes";

function* contactUSDetail({ payload: { user } }) {

  try {
    const response = yield call(postContactUS, user);
    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(contactUSSuccessful(response.data));
    } else {
      yield put(contactUSField(response.message));
    }
  } catch (error) {
    yield put(contactUSField(error));
  }
}

function* contactUSSaga() {
  yield takeEvery(CONTACT_US, contactUSDetail);
}

export default contactUSSaga;
