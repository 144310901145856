import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { GET_HOST_ORDER_DETAILS } from "./actionTypes";
import { hostGetOrderDetailsSuccessful, hostGetOrderDetailsFailed, } from "./action";
import { postHostOrderDetails, } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* getHostOrderDetails({ payload: { user } }) {

    try {
        const response = yield call(postHostOrderDetails, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(hostGetOrderDetailsSuccessful(response.data));
        } else {
            yield put(hostGetOrderDetailsFailed(response.message));
        }
    } catch (error) {
        yield put(hostGetOrderDetailsFailed(error));
    }
}

export function* watchOnGetHostOrderDetails() {
    yield takeEvery(GET_HOST_ORDER_DETAILS, getHostOrderDetails);
}

function* HostOrderDetailSaga() {

    yield all([
        fork(watchOnGetHostOrderDetails),
    ]);
}

export default HostOrderDetailSaga;
