import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProfileCard from '../../../Pages/Renter/ProfileCard';
import { toast } from 'react-toastify';
import { renterAddReviewDetails } from "../../../../Redux/renter/addReview/action"
import { renterUpdateReviewDetails } from "../../../../Redux/renter/updateReview/action"
import { clearLocalStorage } from '../../../../helpers/commonfunction'
import { useFormik } from "formik"
import * as Yup from "yup";


const RenterReview = () => {

  const dispatch = useDispatch();
  const navigation = useNavigate();

  //*state
  const [reviewId, setReviewId] = useState("");
  const [loggedUserName, setLoggedUserName] = useState("");

  const initialValues = {
    isAddValue: false,
    bioReview: "",
    rating: 0,
  }

  const validateSchema = Yup.object({
    isAddValue: Yup.boolean(),
    bioReview: Yup.string().min(5, "Review minimum length should be 5").max(200).required('Please enter bio.'),
    rating: Yup.number()
      .test('atLeastOneRating', 'Please select at least one rating', function (value) {
        // Check if the docList array is not empty
        return value > 0;
      }),
  })

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, resetForm, setFieldValue, } = useFormik({
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: (values) => {
      onClickAddAndUpdateReview();
    },
  })

  const loading = useSelector(state => state?.RenterAddReviewReducer?.loading);
  const addReviewData = useSelector(state => state?.RenterAddReviewReducer?.addReviewData);
  const success = useSelector(state => state?.RenterAddReviewReducer?.success);
  const errorMessage = useSelector(state => state?.RenterAddReviewReducer?.errorMessage);
  const error = useSelector(state => state?.RenterAddReviewReducer?.error);

  const updateReviewErrorMessage = useSelector(state => state?.RenterUpdateReviewReducer?.updateReviewErrorMessage);
  const isUpdateLoading = useSelector(state => state?.RenterUpdateReviewReducer?.isUpdateLoading);
  const updateReviewData = useSelector(state => state?.RenterUpdateReviewReducer?.updateReviewData);
  const successUpdate = useSelector(state => state?.RenterUpdateReviewReducer?.successUpdate);
  const errorUpdate = useSelector(state => state?.RenterUpdateReviewReducer?.errorUpdate);

  useEffect(() => {
    let firstName = localStorage.getItem("firstName");
    let lastName = localStorage.getItem("lastName");
    let fullName = firstName + " " + lastName; setLoggedUserName(fullName);
  }, []);

  useEffect(() => {
    let isAddValue = localStorage.getItem("isAddRenter")
    if (isAddValue === "true") {
      setFieldValue("isAddValue", true);
    } else {
      setFieldValue("isAddValue", false);
      const user = JSON.parse(localStorage.getItem('renterReviewUpdateData'));
      if (user) {
        setFieldValue("bioReview", user.text);
        setReviewId(user._id);
        setFieldValue("rating", user.rate);
      }
    }
  }, []);

  useEffect(() => {
    if (success && addReviewData && !loading) {
      toast.success('Review added successfully.');
      onClickCancel();
    }
  }, [success, addReviewData]);

  useEffect(() => {
    if (errorMessage && !loading) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successUpdate && updateReviewData && !isUpdateLoading) {
      toast.success('Update review successfully.');
      onClickCancel();
    }
  }, [successUpdate, updateReviewData]);

  useEffect(() => {
    if (updateReviewErrorMessage && !isUpdateLoading) {
      toast.error(updateReviewErrorMessage);
    }
  }, [updateReviewErrorMessage]);

  //*expire token
  useEffect(() => {
    if ((error && !loading) || (errorUpdate && !isUpdateLoading)) {
      if (errorMessage === "Request failed with status code 401" || updateReviewErrorMessage === "Request failed with status code 401") {
        navigation("/login");
        clearLocalStorage();
        toast.error('JWT Expire.');
      }
    }
  }, [error, errorUpdate, errorMessage, updateReviewErrorMessage]);

  const onClickCancel = () => {
    navigation("/renter/order");
    resetForm();
    localStorage.removeItem("orderIdForRenter");
    localStorage.removeItem("productIdForRenter");
    localStorage.removeItem("renterReviewUpdateData");
  };

  const onClickAddAndUpdateReview = () => {
    let isAddValue = localStorage.getItem("isAddRenter")
    if (isAddValue === "true") { onClickAddReview(); } else { onClickUpdateReview(); }
  };

  //* Add Review
  const onClickAddReview = () => {

    let orderId = localStorage.getItem("orderIdForRenter");
    let productId = localStorage.getItem("productIdForRenter");
    let dataObj = {
      "order_id": String(orderId),
      "product_id": String(productId),
      "text": values.bioReview,
      "rate": values.rating,
    }
    dispatch(renterAddReviewDetails(dataObj));
  };

  //* Update Review
  const onClickUpdateReview = () => {

    let dataObj = {
      "product_id": String(reviewId),
      "text": values.bioReview,
      "rate": values.rating,
    }
    dispatch(renterUpdateReviewDetails(dataObj));
  };

  //* handle Star click
  const handleStarClick = (starValue) => {
    setFieldValue("rating", starValue);
  };


  return (
    <div>
      <form >
        <section className="innerBanner-wrapper">
          <div className="innerBanner-img img-obj cover">
            <img src="images/header.png" alt="header_image" />
          </div>
          <div className="container h-100 z1 flex-all">
            <h1>{values.isAddValue === true ? "Add review" : "Update Review"}</h1>
          </div>
        </section>
        <section className="profile-wrapper host-review-btn py32-wrapper px-18">
          <div className="container-fluid">
            <div className="row g-4">
              {/* <div className="col-md-auto"> */}
                <ProfileCard />
              {/* </div> */}
              <div className="col-md-9 col-sm-8 profile-main">
                <div className="card ms-xl-1">
                  <div className="card-header">
                    <h3>REVIEW ORDER</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive mb-large">
                      <table className="table-themeBr table-reviewOrders">
                        <tbody>
                          <tr>
                            <th>RENTER'S NAME:</th>
                            <td>{loggedUserName}</td>
                          </tr>
                          <tr>
                            <th>TEXT<label style={{ color: '#FF0000' }}> * </label>:</th>
                            {/* <td>Lorem ipsum is simply dummy text</td> */}
                            <td>
                              <input
                                name="bioReview"
                                value={values.bioReview}
                                onChange={(e) => {
                                  setFieldValue("bioReview", e.target.value);
                                }}
                                type="text"
                                id="email"
                                onBlur={handleBlur}
                                className={errors.bioReview && touched.bioReview ? 'form-control form-control-error' : 'form-control'}
                                placeholder="Enter text.." />
                              {errors.bioReview && touched.bioReview ? <p className="error-text">{errors.bioReview}</p> : null}
                            </td>
                          </tr>
                          <tr>
                            <th className="mt-2">REVIEWS<label style={{ color: '#FF0000' }}> * </label>:</th>
                            <td>
                              <div className={errors.rating && touched.rating ? 'rating' : 'rating'}>
                                {[1, 2, 3, 4, 5].map((starValue) => (
                                  <i
                                    key={starValue}
                                    name="rating"
                                    className={`fa-solid fa-star ${starValue <= values.rating ? 'selected' : ''}`}
                                    onClick={() => handleStarClick(starValue)}
                                    onBlur={handleBlur}
                                  ></i>
                                ))}
                                {errors.rating && touched.rating ? <p className="error-text">{errors.rating}</p> : null}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr></hr>
                    <button type="button" onClick={() => {
                      handleSubmit();
                    }} className="btn btn-green">
                      <span>{values.isAddValue === true ? "Add review" : "Update Review"}</span>
                    </button>
                    <button onClick={() => {
                      onClickCancel();
                    }} className="btn btn-black">
                      <span>CANCEL</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
};

export default RenterReview;
