import {
    GET_PROFILE_DETAILS,
    GET_PROFILE_DETAILS_SUCCESSFUL,
    GET_PROFILE_DETAILS_FAILED,
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESSFUL,
    UPDATE_PROFILE_FAILED,
    UPDATE_PROFILE_RESET,
} from "./actionTypes";

const initialState = {
    errorMessage: null,
    loading: false,
    user: null,
    success: false,
    error: false,
    isUpdateSuccess: false,
    isUpdateError: false,
    isUpdateSuccessMsg: null,
    isUpdateErrorMsg: null,
};


const UserProfile = (state = initialState, action) => {

    switch (action.type) {
        case GET_PROFILE_DETAILS:
            state = {
                ...state,
                loading: true,
                errorMessage: null,
            };
            break;
        case GET_PROFILE_DETAILS_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                user: action.payload,
                success: true,
                errorMessage: null,
                error: false,
            };
            break;
        case GET_PROFILE_DETAILS_FAILED:
            state = {
                ...state,
                user: null,
                loading: false,
                errorMessage: action.payload,
                error: true,
                success: false,

            };
            break;
        case UPDATE_PROFILE:
            state = {
                ...state,
                loading: true,
                isUpdateSuccessMsg: null,
                isUpdateErrorMsg: null,
                isUpdateSuccess: false,
                isUpdateError: false,
                errorMessage: null,
                user: null,
                success: false,
                error: false,
            };
            break;
        case UPDATE_PROFILE_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                isUpdateSuccessMsg: action.payload,
                isUpdateSuccess: true,
                isUpdateError: false,
                isUpdateErrorMsg: null,
                errorMessage: null,
                user: null,
                success: false,
                error: false,
            };
            break;
        case UPDATE_PROFILE_FAILED:
            state = {
                ...state,
                loading: false,
                isUpdateSuccessMsg: null,
                isUpdateSuccess: false,
                isUpdateError: true,
                isUpdateErrorMsg: action.payload,
                errorMessage: null,
                user: null,
                success: false,
                error: false,
            };
            break;
        case UPDATE_PROFILE_RESET:
            state = {
                ...state,
                errorMessage: null,
                loading: false,
                user: null,
                success: false,
                error: false,
                isUpdateSuccess: false,
                isUpdateError: false,
                isUpdateSuccessMsg: null,
                isUpdateErrorMsg: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default UserProfile;
