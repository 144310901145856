export const initFacebookSdk = () => {

    return new Promise((resolve, reject) => {

        // Load the Facebook SDK asynchronously
        window.fbAsyncInit = () => {

            //1120397198938125
            //693197782639109 ---- D
            window.FB.init({
                appId: '693197782639109',
                cookie: true,
                xfbml: true,
                version: 'v18.0'
            });
            // Resolve the promise when the SDK is loaded
            resolve();
        }
    })

}

export const getFacebookLoginStatus = () => {
    return new Promise((resolve, reject) => {
        window.FB.getLoginStatus((response) => {
            resolve(response);
        });
    })
}

export const fbLogin = async () => {
    return new Promise((resolve, reject) => {
        window.FB.login((response) => {
            resolve(response)
        },)
    })
}

export const fbLogout = () => {
    return new Promise((resolve, reject) => {
        window.FB.logout(function (response) {
            // user is now logged out
        });
    })
}

export const fbGetUserDetails = () => {
    return new Promise((resolve, reject) => {
        window.FB.api('/me', { fields: 'first_name, last_name, picture, email, id' }, function (response) {
            resolve(response)
        });
    })
}