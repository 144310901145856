import { call, put, takeEvery, } from "redux-saga/effects";
import { HOST_VIEW_PAYMENT } from "./actionTypes";
import { hostViewPaymentSuccessful, hostViewPaymentFailed } from "./action";
import { getPaymentList } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'

function* hostViewPayment({ payload: { user } }) {

    try {
        const response = yield call(getPaymentList, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(hostViewPaymentSuccessful(response.data));
        } else {
            yield put(hostViewPaymentFailed(response.message));
        }
    } catch (error) {
        yield put(hostViewPaymentFailed(error));
    }
}

function* HostViewPaymentSaga() {
    yield takeEvery(HOST_VIEW_PAYMENT, hostViewPayment);
}

export default HostViewPaymentSaga;
