import {
    HOST_VIEW_PAYMENT,
    HOST_VIEW_PAYMENT_SUCCESSFUL,
    HOST_VIEW_PAYMENT_FAILED
} from "./actionTypes";

const initialState = {
    viewPaymentError: null,
    loading: false,
    paymentListData: null,
    isViewPaymentSuccess: false,
    isViewPaymentError: false
};


const HostViewPaymentReducer = (state = initialState, action) => {

    switch (action.type) {
        case HOST_VIEW_PAYMENT:
            state = {
                ...state,
                loading: true,
                viewPaymentError: null,
            };
            break;
        case HOST_VIEW_PAYMENT_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                paymentListData: action.payload,
                isViewPaymentSuccess: true,
                viewPaymentError: null,
                isViewPaymentError: false,
            };
            break;
        case HOST_VIEW_PAYMENT_FAILED:
            state = {
                ...state,
                paymentListData: null,
                loading: false,
                viewPaymentError: action.payload,
                isViewPaymentError: true,
                isViewPaymentSuccess: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default HostViewPaymentReducer;
