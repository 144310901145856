import {
    UPDATE_ORDER_STATUS,
    UPDATE_ORDER_STATUS_SUCCESSFUL,
    UPDATE_ORDER_STATUS_FAILED,
    UPDATE_ORDER_STATUS_RESET,
} from "./actionTypes";


export const updateOrderStatus = user => {
    return {
        type: UPDATE_ORDER_STATUS,
        payload: { user },
    }
}

export const updateOrderStatusSuccessful = user => {
    return {
        type: UPDATE_ORDER_STATUS_SUCCESSFUL,
        payload: user,
    }
}

export const updateOrderStatusFailed = user => {
    return {
        type: UPDATE_ORDER_STATUS_FAILED,
        payload: user,
    }
}

export const updateOrderStatusReset = user => {
    return {
        type: UPDATE_ORDER_STATUS_RESET,
        payload: user,
    }
}
