import {
    HOST_ADD_REVIEW,
    HOST_ADD_REVIEW_SUCCESSFUL,
    HOST_ADD_REVIEW_FAILED,
    HOST_ADD_REVIEW_RESET
} from "./actionTypes";

const initialState = {
    hostAddReviewErrorMessage: null,
    hostLoading: false,
    hostAddReviewData: null,
    hostSuccess: false,
    hostError: false,
};


const HostAddReviewReducer = (state = initialState, action) => {

    switch (action.type) {
        case HOST_ADD_REVIEW:
            state = {
                ...state,
                hostLoading: true,
                hostAddReviewErrorMessage: null,
            };
            break;
        case HOST_ADD_REVIEW_SUCCESSFUL:
            state = {
                ...state,
                hostLoading: false,
                hostAddReviewData: action.payload,
                hostSuccess: true,
                errorMessage: null,
                hostError: false,
            };
            break;
        case HOST_ADD_REVIEW_FAILED:
            state = {
                ...state,
                hostAddReviewData: null,
                hostLoading: false,
                errorMessage: action.payload,
                hostError: true,
                hostSuccess: false,
            };
            break;

        case HOST_ADD_REVIEW_RESET:
            state = {
                ...state,
                hostAddReviewErrorMessage: null,
                hostLoading: true,
                hostAddReviewData: null,
                hostSuccess: false,
                hostError: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default HostAddReviewReducer;
