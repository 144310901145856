import {
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESSFUL,
    FORGOT_PASSWORD_FAILED,
    FORGOT_PASSWORD_RESET,
} from "./actionTypes";

const initialState = {
    forgotPasswordError: "",
    forgotPasswordMessage: "",
    loading: false,
    isForgotPasswordSuccess: false,
    isForgotPasswordError: false
};

const ForgotPassword = (state = initialState, action) => {

    switch (action.type) {
        case FORGOT_PASSWORD:
            state = {
                ...state,
                loading: true,
                isForgotPasswordSuccess: false,
                isForgotPasswordError: false,
            };
            break;
        case FORGOT_PASSWORD_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                forgotPasswordMessage: action.payload,
                forgotPasswordError: "",
                isForgotPasswordSuccess: true,
                isForgotPasswordError: false,
            };
            break;
        case FORGOT_PASSWORD_FAILED:
            state = {
                ...state,
                loading: false,
                forgotPasswordMessage: "",
                forgotPasswordError: action.payload,
                isForgotPasswordSuccess: false,
                isForgotPasswordError: true,
            };
            break;
        case FORGOT_PASSWORD_RESET:
            state = {
                ...state,
                forgotPasswordError: "",
                forgotPasswordMessage: "",
                loading: false,
                isForgotPasswordSuccess: false,
                isForgotPasswordError: false
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default ForgotPassword;
