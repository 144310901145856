import {
    HOST_UPDATE_REVIEW,
    HOST_UPDATE_REVIEW_SUCCESSFUL,
    HOST_UPDATE_REVIEW_FAILED,
    HOST_UPDATE_REVIEW_RESET,
} from "./actionTypes";

const initialState = {
    updateReviewErrorMessage: null,
    isUpdateLoading: false,
    updateReviewData: null,
    successUpdate: false,
    errorUpdate: false,
};


const HostUpdateReviewReducer = (state = initialState, action) => {

    switch (action.type) {
        case HOST_UPDATE_REVIEW:
            state = {
                ...state,
                isUpdateLoading: true,
                updateReviewErrorMessage: null,
            };
            break;
        case HOST_UPDATE_REVIEW_SUCCESSFUL:
            state = {
                ...state,
                isUpdateLoading: false,
                updateReviewData: action.payload,
                successUpdate: true,
                updateReviewErrorMessage: null,
                errorUpdate: false,
            };
            break;
        case HOST_UPDATE_REVIEW_FAILED:
            state = {
                ...state,
                updateReviewData: null,
                isUpdateLoading: false,
                updateReviewErrorMessage: action.payload,
                errorUpdate: true,
                successUpdate: false,
            };
            break;

        case HOST_UPDATE_REVIEW_RESET:
            state = {
                ...state,
                updateReviewErrorMessage: null,
                isUpdateLoading: true,
                updateReviewData: null,
                successUpdate: false,
                errorUpdate: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default HostUpdateReviewReducer;
