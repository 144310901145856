import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { GET_PROFILE_DETAILS, UPDATE_PROFILE, UPDATE_KYC } from "./actionTypes";
import { getUserDetailsSuccessful, getUserDetailsFailed, updateProfileSuccessful, updateProfileFailed, } from "./action";
import { getUserProfileDetails, putProfileUser } from "../../helpers/fakebackend_helper"
import { API_CODE } from '../../helpers/enum'


function* getUserDetails({ payload: { user } }) {

    try {
        const response = yield call(getUserProfileDetails);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(getUserDetailsSuccessful(response.data));

        } else {
            yield put(getUserDetailsFailed(response.message));
        }
    } catch (error) {
        yield put(getUserDetailsFailed(error));
    }
}

function* updateUserDetails({ payload: { user } }) {

    try {
        const response = yield call(putProfileUser, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(updateProfileSuccessful(response.message));

        } else {
            yield put(updateProfileFailed(response.message));
        }
    } catch (error) {
        yield put(updateProfileFailed(error));
    }
}

function* updateKYCUserDetails({ payload: { user } }) {
    try {
        // const response = yield call(postProfileKYC, user);
        // if (response.statusCode === API_CODE.SUCCESS) {
        //     yield put(updateKYCSuccessful(response.message));
        // } else {
        //     yield put(updateKYCFailed(response.message));
        // }
    } catch (error) {
        // yield put(updateKYCFailed(error));
    }
}

export function* watchOnGetUserDetails() {
    yield takeEvery(GET_PROFILE_DETAILS, getUserDetails);
}

export function* watchOnUpdateUser() {
    yield takeEvery(UPDATE_PROFILE, updateUserDetails);
}

export function* watchOnUpdateKYC() {
    yield takeEvery(UPDATE_KYC, updateKYCUserDetails);
}

function* ProfileSaga() {

    yield all([
        fork(watchOnGetUserDetails),
        fork(watchOnUpdateUser),
        fork(watchOnUpdateKYC),
    ]);
}

export default ProfileSaga;
