import { call, put, takeEvery } from "redux-saga/effects";
import { LOGIN_WITH_FACEBOOK } from "./actionTypes";
import { postLoginUserWithFacebook } from "../../../helpers/fakebackend_helper";
import { loginWithFacebookSuccessful, loginWithFacebookField } from "./action";
import { API_CODE } from "../../../helpers/enum";
import { setAuthorization } from "../../../helpers/api_helper";


function* loginWithFacebookUser({ payload: { user } }) {
  try {
    const response = yield call(postLoginUserWithFacebook, user);
    if (response.statusCode === API_CODE.SUCCESS) {
      //* Set User data to Local storage
      sessionStorage.setItem("loggedAuthUser", JSON.stringify(response.data));
      localStorage.setItem("loggedAuthUser", JSON.stringify(response.data));
      //*set Token
      localStorage.setItem("accessToken", response.data.token);
      let token = response.data.token;
      // console.log("token-----", token)
      setAuthorization(token);
      let Role = response.data.user.role
      if (Role === 1) { localStorage.setItem("role", "renter"); } else { localStorage.setItem("role", "host"); }
      localStorage.setItem("firstName", response.data.user.first_name);
      localStorage.setItem("lastName", response.data.user.last_name);
      localStorage.setItem("email", "");
      localStorage.setItem("loggedUserId", response.data.user.fb_id);
      localStorage.setItem("qRImage", "");
      localStorage.setItem("qrUrl", "");
      localStorage.setItem("isLoginWithFacebook", true);
      yield put(loginWithFacebookSuccessful(response.data));

    } else {
      yield put(loginWithFacebookField(response.message));
    }
  } catch (error) {
    yield put(loginWithFacebookField(error));
  }
}

function* LoginWithFacebookSaga() {
  yield takeEvery(LOGIN_WITH_FACEBOOK, loginWithFacebookUser);
}

export default LoginWithFacebookSaga;
