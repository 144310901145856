import {
  LOGIN_WITH_FACEBOOK,
  LOGIN_WITH_FACEBOOK_SUCCESSFUL,
  LOGIN_WITH_FACEBOOK_FAILED,
  LOGIN_WITH_FACEBOOK_RESET_STATE,
} from "./actionTypes";

const initialState = {
  loginWithFacebookErrorMessage: null,
  loginWithFacebookMessage: null,
  loginWithFacebookLoading: false,
  loginWithFacebookData: null,
  loginWithFacebookSuccess: false,
  loginWithFacebookError: false,
};

const LoginWithFacebookReducer = (state = initialState, action) => {

  switch (action.type) {
    case LOGIN_WITH_FACEBOOK:
      state = {
        ...state,
        loginWithFacebookLoading: true,
        loginWithFacebookError: false,
        loginWithFacebookSuccess: false,
      };
      break;

    case LOGIN_WITH_FACEBOOK_SUCCESSFUL:
      state = {
        ...state,
        loginWithFacebookLoading: false,
        loginWithFacebookData: action.payload,
        loginWithFacebookSuccess: true,
        loginWithFacebookError: false,
        loginWithFacebookErrorMessage: null,
      };
      break;

    case LOGIN_WITH_FACEBOOK_FAILED:
      state = {
        ...state,
        loginWithFacebookLoading: false,
        loginWithFacebookData: null,
        loginWithFacebookSuccess: false,
        loginWithFacebookError: true,
        loginWithFacebookErrorMessage: action.payload,
      };
      break;

    case LOGIN_WITH_FACEBOOK_RESET_STATE:
      state = {
        ...state,
        loginWithFacebookErrorMessage: null,
        loginWithFacebookMessage: null,
        loginWithFacebookLoading: false,
        loginWithFacebookData: null,
        loginWithFacebookSuccess: false,
        loginWithFacebookError: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LoginWithFacebookReducer;
