import React, { useEffect, useRef, useState } from "react";
import ProfileCard from "./Renter/ProfileCard";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { State, City } from "country-state-city";
import { 
  ConvertResponseForCountry, 
  ConvertResponseForCountryAllCities, 
 } from "../../helpers/commonfunction";
import { api } from "../../config";
import * as url from "../../helpers/url_helper";
import { clearLocalStorage } from '../../helpers/commonfunction'
import specificationProductData from '../../localJSONFiles/specificationProductDependsProductType.json'
import specificationProductDatas from '../../localJSONFiles/productTypetoChageSaftygear.json'
// import safetyList from '../../localJSONFiles/safetyGearProduct.json';
import { useFormik } from "formik"
import * as Yup from "yup";
import Modal from 'react-modal';
import "./Cart.css";
import { INSURANCE_OPTION, PRODUCT_STATUS, } from "../../helpers/enum"
import SignatureCanvas from 'react-signature-canvas';


const HostAddProduct = () => {

  const ref = useRef();
  const signatureRef = useRef();
  const insuranceRef = useRef();
  const navigation = useNavigate();

  const [isCaptain, setIsCaptain] = useState(false);
  const [whoCaptain, setWhoCaptain] = useState("owner");
  const [isCaptainShow, setIsCaptainShow] = useState(false);

  const [isAddHostProductSuccess, setIsAddHostProductSuccess] = useState(false);
  const [productTypesOption] = useState([
    {
      label: "Select Vehicle Type", value: "",
    },
    {
      label: "UTV’s", value: "Utv’s",
    },
    {
      label: "ATV’s", value: "Atv’s",
    },
    {
      label: "Campers/RVs", value: "Campers/Rv’s",
    },
    {
      label: "WaveRunners", value: "Wave runners",
    },
    {
      label: "Boats", value: "Boats",
    },
    {
      label: "Trailers", value: "Trailers",
    },
    {
      label: "Trucks", value: "Trucks",
    },
    {
      label: "SUVs", value: "SUV",
    },
    {
      label: "E-Bikes", value: "EBikes",
    },
    {
      label: "Snowmobiles", value: "Snowmobiles",
    },
    {
      label: "Overland", value: "Overland",
    },
    {
      label: "Dirt Bike’s", value: "DirtBikes",
    },
    {
      label: "Others", value: "Others",
    },
  ]);

  const [stateArray, setStateArray] = useState([]);
  const [docList, setDocList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const [specificationProductList, setSpecificationProductList] = useState([]);
  const [specificationProductDisplay, setSpecificationProductDisplay] = useState([]);
  const [safetyGearList, setSafetyGearList] = useState([]);
  const [safetyGearListProductDisplay, setSafetyGearListProductDisplay] = useState([]);
  const [specificationDataNews, setSpecificationDataNews] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [productName, setProductName] = useState('');
  const [isShowLoader, setIsShowLoader] = useState(true);

  //*insurance
  const [insuranceOptionOne, setInsuranceOptionOne] = useState(false);
  const [insuranceOptionTwo, setInsuranceOptionTwo] = useState(false);
  const [selectedInsuranceOption, setSelectedInsuranceOption] = useState(null)
  const [uploadFileName, setUploadFileName] = useState(false);
  const [docSelectError, setDocSelectError] = useState(false);
  const [insuranceOptionTwoValue, setInsuranceOptionTwoValue] = useState(null);
  const [insuranceOptionTwoSelectError, setInsuranceOptionTwoSelectError] = useState(false);
  const [insuranceOptionTwoDocError, setInsuranceOptionTwoDocError] = useState(false);
  const [signature, setSignature] = useState(null);

  const [uploadFileNames, setUploadFileNames] = useState([]);
  const insurancePricePerDayArray = [
    {
      label: "Outdoorshare protection policy", value: "OutdoorshareProtectionPolicy",
    },
    {
      label: "I have my own Commercial insurance", value: "IhaveMyOwnCommercialInsurance",
    }
  ]
  const insurancePriceOptionTwoArray = [
    { label: "$0", value: "0" },
    { label: "$19", value: "19" },
    { label: "$29", value: "29" },
    { label: "$39", value: "39" },
  ]

  const initialValues = {
    attachment: 0,
    productType: null,
    productName: "",
    price: "",
    state: null,
    city: [],
    make: "",
    model: "",
    isAvailableForDelivery: false,
    deliveryCostPerMil: "",
    securityDeposit: "",
    insurancePricePerDay: "",
    insurancePricePerDaySelect: null,
    isSafetyGear: false,
    safetyGear: [],
    vin: "",
    description: "",
    specificationOfProduct: null,
    is_verified: false,
    product_status: PRODUCT_STATUS.DISABLE,
  }
  // const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const AddProductSchema = Yup.object({
    attachment: Yup.number()
      .test('atLeastOneDoc', 'Please select at least one document.', function (value) {
        // Check if the docList array is not empty
        return value > 0;
      }),
    productType: Yup.object().required('Please select product type.'),
    productName: Yup.string().min(2, "Product name must be at least 2 characters.").max(30).required('Please enter product name.'),
    price: Yup.string().required('Please enter price.'),
    state: Yup.object().required('Please select state.'),
    city: Yup.array().min(1, 'Please select city.'),
    make: Yup.string().min(2, "Make must be at least 2 characters").max(30).required('Please enter make.'),
    model: Yup.string().min(2, "Model must be at least 2 characters").max(30).required('Please enter model.'),
    isAvailableForDelivery: Yup.boolean(),
    //   .test('oneOfRequired', 'Enable delivery cost per miles.', function (item) {
    //     const isDeliveryChecked = this.parent.isAvailableForDelivery;
    //     if (isDeliveryChecked === false) {
    //       return false; // Return false to trigger the validation error
    //     }
    //     return true; // No error
    //   }),
    deliveryCostPerMil: Yup.number()
      .when('isAvailableForDelivery', {
        is: true,
        then: () =>
          Yup.number().min(1).required('Please enter delivery cost per miles.'),
      }),
    securityDeposit: Yup.number().min(2, "Security deposit must be at least 2 characters").required('Please enter security deposit.'),
    // insurancePricePerDay: Yup.number().min(2, "Insurance price per day must be at least 2 characters").required('Please enter insurance price per day.'),
    insurancePricePerDaySelect: Yup.object().required('Please select insurance price per day.'),
    isSafetyGear: Yup.boolean(),
    safetyGear: Yup.array()
      .when('isSafetyGear', {
        is: true,
        then: () =>
          Yup.array().min(1, 'Please select safety gear.'),
      }),
    vin: Yup.string().min(2, "VIN must be at least 2 characters").max(30).required('Please enter vin.'),
    description: Yup.string().min(2, "Description must be at least 2 characters").required('Please enter description.'),
    // specificationOfProduct: Yup.array().min(1, 'Please select specification of product.'),
  })

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, resetForm, setFieldValue, } = useFormik({
    initialValues: initialValues,
    validationSchema: AddProductSchema,
    onSubmit: (values) => {
      setDisabled(true);
      onClickAddHostProduct(values);
    },
  })

  const loading = useSelector(state => state?.HostProductCrud?.loading);
  const isAddSuccess = useSelector(state => state?.HostProductCrud?.isAddSuccess);
  const isAddError = useSelector(state => state?.HostProductCrud?.isAddError);
  const isAddSuccessMsg = useSelector(state => state?.HostProductCrud?.isAddSuccessMsg);
  const isAddErrorMsg = useSelector(state => state?.HostProductCrud?.isAddErrorMsg);

  useEffect(() => {
    var specificationProductResponse = specificationProductData?.equipments;
    setSpecificationProductList(specificationProductResponse);
    // setSafetyGearList(safetyList);
    var specificationProductResponses = specificationProductDatas?.equipments;
    setSafetyGearList(specificationProductResponses);
  }, []);

  useEffect(() => {
    if (isAddSuccess && isAddHostProductSuccess === true) {
      setIsAddHostProductSuccess(true);
      toast.success("Host product add successfully.");
      setIsShowLoader(true);
      // navigation("/host/productlist")
      onClickClearState();
    } else {
      toast.error(isAddError);
      setIsShowLoader(true);
    }
  }, [isAddSuccess]);

  useEffect(() => {
    setIsAddHostProductSuccess(false);
    toast.error(isAddErrorMsg);
    onClickClearState();
  }, [isAddError]);

  useEffect(() => {
    toast.success(isAddSuccessMsg);
  }, [isAddSuccess]);

  useEffect(() => {
    let stateArrayResponse = State.getStatesOfCountry("US");
    let finalStateArray = ConvertResponseForCountry(stateArrayResponse);
    setStateArray(finalStateArray);
  }, []);

  //*expire token
  useEffect(() => {
    if (isAddError && !loading) {
      if (isAddErrorMsg === "Request failed with status code 401") {
        // navigation("/login");
        clearLocalStorage();
        toast.error('JWT Expire.');
      }
    }
  }, [isAddError, isAddErrorMsg]);

  const onClickAddHostProduct = (values) => {

    let specificationProducts = values?.specificationOfProduct.map((item) => item?.label);
    let specificationList = specificationProducts.join(',  ');
    let safetyGearData;
    if (values.safetyGear !== null) { safetyGearData = values?.safetyGear.map((item) => item?.label).join(', '); } else { safetyGearData = "" }

    //get Doc List
    let finalDocList = docList.map((item) => item?.file);

    let dataObj = {
      product_type: values?.productType?.value,
      product_name: values?.productName,
      product_location: values?.city,
      vin: values?.vin,
      is_captain: isCaptain ? isCaptain : false,
      who_captain: whoCaptain ? whoCaptain : 'owner',
      description: values?.description,
      specification: specificationList,
      product_images: finalDocList,
      state: values?.state?.label,
      make: values?.make,
      model: values?.model,
      insurance_type: values?.insurancePricePerDaySelect?.value,
      insurance_amount: values?.insurancePricePerDay,
      security_deposit: values?.securityDeposit,
      price_per_day: values?.price,
      safety_gear: safetyGearData,
      // delivery_charge: values.deliveryCostPerMil,
      is_delivery: Boolean(values?.isAvailableForDelivery),
      is_verified: values?.is_verified,
      product_status: values?.product_status
    };

    setIsShowLoader(false);

    if (values?.isAvailableForDelivery && values?.deliveryCostPerMil) {
      dataObj.delivery_charge = values?.deliveryCostPerMil;
    }

    let dataAppend = new FormData();

    //append images
    for (let [key, itm] of Object.entries(selectedImages)) {
      dataAppend.append("product_images", itm, itm?.name);
    }

    dataAppend.append('signature', signature);

    //append remaining data
    for (let [key, itm] of Object.entries(dataObj)) {
      if (key !== "product_images") {
        if (key === "product_location") {
          // dataAppend.append(key, itm);
          // dataAppend.append("product_location", JSON.stringify(itm));
          for (let [idx, city] of Object.entries(itm)) {
            dataAppend.append("product_location", city);
          }
        } else {
          dataAppend.append(key, itm);
        }
      }
    }

    //Token set
    let token = localStorage.getItem("accessToken");
    let headers = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    // Add Host Product
    var requestOptions = {
      method: "POST",
      headers,
      body: dataAppend,
      redirect: "follow",
    };

    fetch(`${api.API_URL}${url?.ADD_LIST_HOST_PRODUCT}`, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        let msg = 'Something wrong !';
        if (result && result?.statusCode === 200) {
          if (values?.insurancePricePerDaySelect?.value === INSURANCE_OPTION.INSURANCE_OPTION_TWO && signature !== null) {
            let Product_id = result?.data.product_Id
            await uploadProductProtectionProof(Product_id)
          }
          setIsShowLoader(true);
          setIsModal(true);
          setProductName(values?.productName);
          // toast.success("Product added successfully.");
          // navigation("/host/productlist");
          // onClickClearState();
          return;
        } else if (result && result?.success === false) {
          if (result?.missing_param) {
            msg = result?.missing_param[0]?.msg;
          }
        }
        toast.error(msg);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error('Something went wrong !');
      });
  };

  //*Clear State
  const onClickClearState = () => {
    setIsCaptain(false);
    setWhoCaptain("owner");
    setDocList([]);
    setFileList([]);
    setSelectedImages([]);
    resetForm();
  };

  const handleProductAdd = async (e) => {
    e.preventDefault();
    setIsModal(false);
    navigation("/host/productlist");
    onClickClearState();
  }

  const [selectedImages, setSelectedImages] = useState([]);

  //Select Doc
  async function selectDocuments(e) {
    let file = e.target.files;
    let imageFiles = [];
    if (selectedImages.length > 0) {
      for (let i = 0; i < file.length; i++) {
        imageFiles.push(file[i]);
        if (i === file.length - 1) {
          let finalData = [...selectedImages, ...imageFiles];
          setSelectedImages(finalData);
          setFieldValue("attachment", finalData.length);
        }
      }
    } else {
      for (let i = 0; i < file.length; i++) {
        imageFiles.push(file[i]);
        if (i === file.length - 1) {
          let finalData = [...imageFiles];
          setSelectedImages(finalData);
          setFieldValue("attachment", finalData.length);
        }
      }
    }
  }


  const onClickRemoveDocuments = (index) => {
    let data = [...selectedImages];
    data.splice(index, 1)
    setSelectedImages(data)
  }

  const handleClick = (e) => {
    ref.current.click();
  };


  //selectDocumentsForInsuranceProof
  async function selectDocumentsForInsuranceProof(e) {
    let file = e.target.files;
    let imageFiles = [];
    if (uploadFileNames.length > 0) {
      for (let i = 0; i < file.length; i++) {
        imageFiles.push(file[i]);
        if (i === file.length - 1) {
          let finalData = [...uploadFileNames, ...imageFiles];
          setUploadFileNames(finalData);
        }
      }
    } else {
      for (let i = 0; i < file.length; i++) {
        imageFiles.push(file[i]);
        if (i === file.length - 1) {
          let finalData = [...imageFiles];
          setUploadFileNames(finalData);
        }
      }
    }
  }

  const onClickRemoveInsuranceProof = (index) => {
    let data = [...uploadFileNames];
    data.splice(index, 1)
    setUploadFileNames(data)
  }

  const handleClickInsuranceProof = (e) => {
    insuranceRef.current.click();
  };

  //Select Multi Location
  const handleProductLocationChange = (selectedOptions) => {
    const selectedValues = selectedOptions?.map((option) => option.value);
    const combinedArray = [...cityArray, ...selectedOptions]; // to allow custom values in city dropdown
    const uniqueArray = Array.from(new Set(combinedArray.map(option => JSON.stringify(option)))).map(option => JSON.parse(option));
    setCityArray(uniqueArray);
    setFieldValue("city", selectedValues);
};

  //*get city
  const onClickState = (e) => {
    let stateValue = e.value
    let cityArrayResponse = City.getCitiesOfState("US", stateValue);
    let finalCityArray = ConvertResponseForCountryAllCities(cityArrayResponse);
    setCityArray(finalCityArray);
  }

  const changeProductType = (e) => {
    setFieldValue("specificationOfProduct", []);
    if (e.value === 'Boats' || e.value === 'Utv’s') {
      setIsCaptain(true);
      setIsCaptainShow(true);
    } else {
      setIsCaptain(false);
      setIsCaptainShow(false);
    }

    if (e.value !== "Select Vehicle Type" && e.value !== null && e.value !== "" && e.value !== undefined) {
      let specificationData = specificationProductList?.filter((item) => item?.equipmentId === e.value);
      let specificationDataNew = specificationData[0]?.specificationsData;
      let sortArray = specificationDataNew?.sort((a, b) => (a.label > b.label ? 1 : -1))
      setSpecificationProductDisplay(sortArray);
    }

    // safty gear
    setFieldValue("safetyGear", []);

    if (e.value !== "Select Vehicle Type" && e.value !== null && e.value !== "" && e.value !== undefined) {
      let specificationData = safetyGearList?.filter((item) => item?.equipmentId === e.value);
      let specificationDataNews = specificationData[0]?.specificationsData;
      setSpecificationDataNews(specificationDataNews);
      let sortArrays = specificationDataNews?.sort((a, b) => (a.label > b.label ? 1 : -1))
      setSafetyGearListProductDisplay(sortArrays);
    }

  }

  //*handle Enter price validation
  const handleNumberValidation = (e, name) => {
    const inputValue = e.target.value;
    // Validate the input
    if ((inputValue === '' || /^\d+$/.test(inputValue))) {
      if (parseInt(inputValue) > 0 || inputValue === '') {
        if (name === "PRICE") { setFieldValue("price", inputValue); }
        else if (name === "Delivery Charge") { setFieldValue("deliveryCostPerMil", inputValue); }
        else if (name === "Security Deposit") { setFieldValue("securityDeposit", inputValue); }
        else if (name === "Insurance price by day") { setFieldValue("insurancePricePerDay", inputValue); }
      }
    }
  };

  //*onClick Insurance Select
  const onClickInsuranceSelect = (event) => {
    let insuranceValue = event.value
    if (insuranceValue === INSURANCE_OPTION.INSURANCE_OPTION_ONE) {
      setInsuranceOptionOne(true);
      setInsuranceOptionTwo(false);
    } else if (insuranceValue === INSURANCE_OPTION.INSURANCE_OPTION_TWO) {
      setInsuranceOptionTwo(true);
      setInsuranceOptionOne(false);
    }
  };

  //*onClick Signature
  const onClicksSignatures = () => {
    const canvas = signatureRef.current.getCanvas();
    // Get the 2D rendering context of the canvas
    const ctx = canvas.getContext('2d');
    // Get the pixel data from the canvas
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    // Check if any pixels are painted (indicating drawing has occurred)
    const isCanvasDrawn = Array.from(imageData.data).some(value => value !== 0);

    if (!isCanvasDrawn) {
      toast.error("Please Sign Signature."); return false;
    } else {
      const signatureImageBlob = canvas.toBlob((blob) => {

        const file = new File([blob], 'signature.png', { type: 'image/png' });
        setSignature(file);

        if (insuranceOptionOne) {
          onClickInsuranceUploadOptionOne();
        } else {
          onClickInsuranceUpload();
        }

      });
    }
  };

  //*onClick Insurance Select
  const onClickInsuranceUpload = (event) => {
    if (insuranceOptionTwoValue === null) { toast.error("Please select protection plan"); return false };
    if (uploadFileNames.length === 0) { toast.error("Please select atleast one protection proof"); return false };
    setFieldValue("insurancePricePerDay", insuranceOptionTwoValue.value);
    setFieldValue("is_verified", false);
    setFieldValue("product_status", PRODUCT_STATUS.DISABLE);
    setInsuranceOptionTwo(false);
    setSelectedInsuranceOption("two")
  };

  //*onClick Insurance Select Option one
  const onClickInsuranceUploadOptionOne = () => {
    setFieldValue("insurancePricePerDay", "39");
    setFieldValue("is_verified", true);
    setFieldValue("product_status", PRODUCT_STATUS.ENABLE);
    setInsuranceOptionOne(false);
    setSelectedInsuranceOption("one")
  };

  //*upload product protection prrof
  const uploadProductProtectionProof = (id) => {

    let dataAppend = new FormData();
    for (let [key, itm] of Object.entries(uploadFileNames)) {
      dataAppend.append("protection_proof", itm, itm?.name);
    }

    let token = localStorage.getItem("accessToken");
    let headers = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    var protectionRequestOptions = {
      method: "POST",
      headers,
      body: dataAppend,
      redirect: "follow",
    };

    if (Array.from(dataAppend.keys()).length) {
      return fetch(`${api.API_URL}${url?.ADD_PROTECTION_PROOF}/${id}`, protectionRequestOptions)
        .then((response) => response.json())
        .then((result) => {
          let msg = 'Something wrong !';
          if (result && result?.statusCode === 200) {
            // toast.success("Product updated successfully.");
            return true;
          } else if (result && result?.success === false) {
            if (result?.missing_param) {
              msg = result?.missing_param[0]?.msg;
            }
          }
          toast.error(msg);
          return false;
        })
        .catch((error) => {
          console.log("error", error);
          toast.error('Something went wrong !');
          return false;
        });
    } else {
      return true;
    }
  };

  return (
    <form>
      <div>
        <section className="innerBanner-wrapper">
          <div className="innerBanner-img img-obj cover">
            <img src="images/header.png" alt="header_image" />
          </div>
          <div className="container h-100 z1 flex-all">
            <h1>Products</h1>
          </div>
        </section>
        <section className="profile-wrapper host-product-btn py32-wrapper px-18">
          <div className="container-fluid">
            <div className="row g-4">
              {/* <div className="col-md-auto"> */}
              <ProfileCard />
              {/* </div> */}
              <div className="col-md-9 col-sm-8 profile-main">
                <div className="card ms-xl-1">
                  <div className="card-header">
                    <h3>ADD NEW PRODUCT</h3>
                  </div>
                  <div className="card-body">
                    {isShowLoader === false &&
                      <div id="loader" data-wordload="Please Wait"></div>
                    }
                    <div action="#">
                      <div className="row gy-md-4 g-3">
                        <div className="col-12">
                          <label>PRODUCT IMAGE{" "}<label style={{ color: "#FF0000" }}> * </label></label>
                          <div className="flex-all justify-content-start cartImagesBlock">
                            <div className="image-scrollbarUpdateProduct">
                              {selectedImages.length === 0 ? (
                                <>
                                  <div className="upload-img no-img cover flex-wrap">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="40"
                                      height="33.091"
                                      viewBox="0 0 40 33.091"
                                    >
                                      <g
                                        id="camera"
                                        transform="translate(0 -2)"
                                        opacity="0.35"
                                      >
                                        <path
                                          id="Path_6230"
                                          data-name="Path 6230"
                                          d="M39,30.636a3.455,3.455,0,0,1-3.455,3.455H4.455A3.455,3.455,0,0,1,1,30.636v-19A3.455,3.455,0,0,1,4.455,8.182h6.909L14.818,3H25.182l3.455,5.182h6.909A3.455,3.455,0,0,1,39,11.636Z"
                                          transform="translate(0 0)"
                                          fill="none"
                                          stroke="#000"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                        />
                                        <circle
                                          id="Ellipse_22"
                                          data-name="Ellipse 22"
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          transform="translate(13 13.545)"
                                          fill="none"
                                          stroke="#000"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                        />
                                      </g>
                                    </svg>
                                    <p>NO IMAGE AVAILABLE</p>
                                  </div>
                                </>
                              ) :
                                (
                                  selectedImages.map((image, index) => (
                                    <div key={index} className="image-preview">
                                      <div className="upload-img no-img cover w-auto">
                                        <img
                                          src={URL.createObjectURL(image)}
                                          alt={`prd_Image ${index}`}
                                        />
                                        <img src="images/cancelImage.png" alt="header_image" style={{ position: 'absolute', top: -10, right: -10, height: 20, width: 20, borderRadius: '50%' }}
                                          onClick={() => {
                                            onClickRemoveDocuments(index);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ))
                                )
                              }
                            </div>
                            <div className="upload-btn">
                              <input
                                type="file"
                                accept=".jpg, .jpeg, .png, .webp"
                                ref={ref}
                                onChange={(e) => {
                                  selectDocuments(e);
                                }}
                                multiple
                                style={{ display: "none" }}
                              />
                              <button
                                name="attachment"
                                type="button"
                                onClick={() => {
                                  handleClick();
                                }}
                                className={errors.attachment && touched.attachment ? "btn btn-green form-control-error" : "btn btn-green"}
                              >
                                <span>Add Images</span>
                              </button>
                              {errors.attachment && touched.attachment ? <p className="error-text">{errors.attachment}</p> : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="product_name">PRODUCT Type{" "}<label style={{ color: "#FF0000" }}> * </label></label>
                          <Select
                            name="productType"
                            value={values.productType}
                            options={productTypesOption}
                            onChange={(e) => {
                              setFieldValue("productType", e);
                              changeProductType(e);
                            }}
                            onBlur={handleBlur}
                            placeholder={"Select product type"}
                            className={errors.productType && touched.productType ? 'form-select form-control-error' : 'form-select'}
                            classNamePrefix="my-react-select"
                            id="productType"
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                          />
                          {errors.productType && touched.productType ? <p className="error-text">{errors.productType}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="product_name">PRODUCT NAME{" "}<label style={{ color: "#FF0000" }}> * </label></label>
                          <input
                            name="productName"
                            type="text"
                            id="productName"
                            className={errors.productName && touched.productName ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Product name"
                            value={values.productName}
                            onChange={(e) => {
                              setFieldValue("productName", e.target.value);
                            }}
                            onBlur={handleBlur}
                            maxLength={30}
                          />
                          {errors.productName && touched.productName ? <p className="error-text">{errors.productName}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="price">PRICE ($){" "}<label style={{ color: "#FF0000" }}> * </label></label>
                          <input
                            name="price"
                            type="text"
                            id="pricePerDay"
                            className={errors.price && touched.price ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Price"
                            value={values.price}
                            onChange={(e) => {
                              handleNumberValidation(e, "PRICE")
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.price && touched.price ? <p className="error-text">{errors.price}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="state">STATE{" "}<label style={{ color: "#FF0000" }}> * </label></label>
                          <Select
                            name="state"
                            value={values.state}
                            options={stateArray}
                            onChange={(e) => {
                              setFieldValue("state", e);
                              onClickState(e);
                            }}
                            onBlur={handleBlur}
                            placeholder={"Select state"}
                            className={errors.state && touched.state ? 'form-select form-control-error' : 'form-select'}
                            classNamePrefix="my-react-select"
                            id="state"
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                          />
                          {errors.state && touched.state ? <p className="error-text">{errors.state}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="location">CITY{" "}<label style={{ color: "#FF0000" }}> * </label></label>
                          <CreatableSelect
                            name="city"
                            value={cityArray.filter((option) =>
                              values.city.includes(option.value)
                            )}
                            onChange={handleProductLocationChange}
                            options={cityArray}
                            placeholder={"Select location"}
                            className={errors.city && touched.city ? 'form-select form-control-error' : 'form-select'}
                            classNamePrefix="my-react-select"
                            id="productLocation"
                            isMulti // Add this line to enable multi-select
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            isClearable
                            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                          />
                          {errors.city && touched.city ? <p className="error-text">{errors.city}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="make">MAKE{" "}<label style={{ color: "#FF0000" }}> * </label></label>
                          <input
                            name="make"
                            value={values.make}
                            onChange={(e) => {
                              setFieldValue("make", e.target.value);
                            }}
                            onBlur={handleBlur}
                            type="text"
                            id="make"
                            className={errors.make && touched.make ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Make"
                          />
                          {errors.make && touched.make ? <p className="error-text">{errors.make}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="model">Model{" "}<label style={{ color: "#FF0000" }}> * </label></label>
                          <input
                            name="model"
                            value={values.model}
                            onChange={(e) => {
                              setFieldValue("model", e.target.value);
                            }}
                            onBlur={handleBlur}
                            type="text"
                            id="model"
                            className={errors.model && touched.model ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Model"
                            maxLength={30}
                          />
                          {errors.model && touched.model ? <p className="error-text">{errors.model}</p> : null}
                        </div>
                        <div className="col-xl-3">
                          <label htmlFor="is_delivery">
                            AVAILABLE FOR DELIVERY{" "}<label style={{ color: "#FF0000" }}> * </label>
                          </label>
                          <div className="form-check form-switch mb-0">
                            <input
                              name="isAvailableForDelivery"
                              value={values.isAvailableForDelivery}
                              onChange={(e) => {
                                if (e.target.checked === false) { setFieldValue("deliveryCostPerMil", ''); }
                                setFieldValue("isAvailableForDelivery", e.target.checked);
                              }}
                              checked={values.isAvailableForDelivery}
                              onBlur={handleBlur}
                              className={errors.isAvailableForDelivery && touched.isAvailableForDelivery ? 'form-check-input form-control-error' : 'form-check-input'}
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                            />
                            <label
                              className="form-check-label mb-0"
                              htmlFor="flexSwitchCheckChecked"
                            >
                              Yes
                            </label>
                          </div>
                          {errors.isAvailableForDelivery && touched.isAvailableForDelivery ? <p className="error-text">{errors.isAvailableForDelivery}</p> : null}
                        </div>
                        {values.isAvailableForDelivery &&
                          <div className="col-xl-3 col-sm-6">
                            <label htmlFor="deliveryCharge">Delivery cost per mile ($){" "}<label style={{ color: "#FF0000" }}> * </label></label>
                            <input
                              name="deliveryCostPerMil"
                              value={values.deliveryCostPerMil}
                              onChange={(e) => {
                                handleNumberValidation(e, "Delivery Charge")
                              }}
                              type="text"
                              id="deliveryCharge"
                              className={errors.deliveryCostPerMil && touched.deliveryCostPerMil ? 'form-control form-control-error' : 'form-control'}
                              placeholder="Delivery cost per mile"
                            />
                            {errors.deliveryCostPerMil && touched.deliveryCostPerMil ? <p className="error-text">{errors.deliveryCostPerMil}</p> : null}
                          </div>
                        }
                        <div className="col-sm-6">
                          <label htmlFor="security_deposit">
                            Security Deposit ($){" "}<label style={{ color: "#FF0000" }}> * </label>
                          </label>
                          <input
                            name="securityDeposit"
                            value={values.securityDeposit}
                            onChange={(e) => {
                              handleNumberValidation(e, "Security Deposit")
                            }}
                            type="text"
                            id="securityDeposit"
                            className={errors.securityDeposit && touched.securityDeposit ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Security deposit"
                          />
                          {errors.securityDeposit && touched.securityDeposit ? <p className="error-text">{errors.securityDeposit}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="insurance">
                            Protection price per day ($){" "}<label style={{ color: "#FF0000" }}> * </label>
                          </label>
                          <Select
                            name="insurancePricePerDaySelect"
                            value={values.insurancePricePerDaySelect}
                            options={insurancePricePerDayArray}
                            onChange={(e) => {
                              setFieldValue("insurancePricePerDaySelect", e);
                              onClickInsuranceSelect(e)
                            }}
                            onBlur={handleBlur}
                            placeholder={"Select Protection Price Per Day Select"}
                            className={errors.insurancePricePerDaySelect && touched.insurancePricePerDaySelect ? 'form-select form-control-error' : 'form-select'}
                            classNamePrefix="my-react-select"
                            id="insurancePricePerDaySelect"
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                          />
                          {errors.insurancePricePerDaySelect && touched.insurancePricePerDaySelect ? <p className="error-text">{errors.insurancePricePerDaySelect}</p> : null}
                        </div>
                        <div className="col-xl-3">
                          <label htmlFor="is_delivery">
                            Enable Safety Gear{" "}
                          </label>
                          <div className="form-check form-switch mb-0">
                            <input
                              name="isSafetyGear"
                              value={values.isSafetyGear}
                              onChange={(e) => {
                                if (e.target.checked === false) { setFieldValue("safetyGear", []); }
                                setFieldValue("isSafetyGear", e.target.checked);
                              }}
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                              checked={values.isSafetyGear && specificationDataNews.length > 0}
                              disabled={!specificationDataNews.length}
                            />
                            <label
                              className="form-check-label mb-0"
                              htmlFor="flexSwitchCheckChecked"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-6">
                          {specificationDataNews.length > 0 && values.isSafetyGear &&
                            <div>
                              <label htmlFor="safety_gear">Safety Gear{" "}</label>
                              <Select
                                name="safetyGear"
                                value={values.safetyGear}
                                onChange={(e) => {
                                  setFieldValue("safetyGear", e);
                                }}
                                onBlur={handleBlur}
                                options={safetyGearListProductDisplay}
                                placeholder={"Select safety gear"}
                                className={errors.safetyGear && touched.safetyGear ? 'form-select form-control-error' : 'form-select'}
                                classNamePrefix="my-react-select"
                                id="safetyGear"
                                isMulti
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                              />
                              {errors.safetyGear && touched.safetyGear ? <p className="error-text">{errors.safetyGear}</p> : null}
                            </div>
                          }
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="vin">Vin{" "}<label style={{ color: "#FF0000" }}> * </label></label>
                          <input
                            name="vin"
                            value={values.vin}
                            onChange={(e) => {
                              setFieldValue("vin", e.target.value);
                            }}
                            type="text"
                            id="vin"
                            className={errors.vin && touched.vin ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Vin"
                            maxLength={30}
                          />
                          {errors.vin && touched.vin ? <p className="error-text">{errors.vin}</p> : null}
                        </div>
                        {isCaptainShow &&
                          <div className="col-xl-2">
                            <label htmlFor="is_captain">
                              Is Captain ?
                            </label>
                            <div className="form-check form-switch mb-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="is_captain"
                                name="is_captain"
                                // value={isCaptain}
                                checked={(isCaptain) ? 'checked' : ""}
                                onChange={(e) => {
                                  setIsCaptain(e.target.checked)
                                  if (e.target.checked === false) { setWhoCaptain(""); }
                                }}
                              />
                              <label
                                className="form-check-label mb-0"
                                htmlFor="is_captain"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        }
                        {isCaptain &&
                          <div className="col-xl-4 col-sm-6">
                            <div className="col">
                              <label htmlFor="who_captain">Who Is Captain ?</label>
                              <select className="form-select form-control" value={whoCaptain}
                                onChange={(e) =>
                                  setWhoCaptain(e.target.value)
                                }
                                id="who_captain"
                                name="who_captain" >
                                <option value="owner">Owner/Host</option>
                                <option value="self">Self/Renter</option>
                              </select>
                            </div>
                            <div className="col mt-2">
                              <b>The Captain drives, directs and leads the crew on their journey. This feature allows for you to take control of your rental and be the captain of your toy. The Captain drives, directs and leads the crew on their journey. You help them have fun.</b>
                              <br />
                            </div>
                          </div>
                        }
                        <div className="col-12">
                          <label htmlFor="safety_equ">
                            DESCRIPTION{" "}<label style={{ color: "#FF0000" }}> * </label>
                          </label>
                          <textarea
                            name="description"
                            value={values.description}
                            onChange={(e) => {
                              setFieldValue("description", e.target.value);
                            }}
                            rows="2"
                            id="description"
                            className={errors.description && touched.description ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Type here..."
                            maxLength={1000}
                          ></textarea>
                          {errors.description && touched.description ? <p className="error-text">{errors.description}</p> : null}
                        </div>
                        <div className="col-12">
                          <label htmlFor="safety_equ">
                            PRODUCT FEATURES
                          </label>
                          <Select
                            name="specificationOfProduct"
                            value={values.specificationOfProduct}
                            onChange={(e) => {
                              setFieldValue("specificationOfProduct", e);
                            }}
                            onBlur={handleBlur}
                            options={specificationProductDisplay}
                            placeholder={"Select specification of product"}
                            className={errors.specificationOfProduct && touched.specificationOfProduct ? 'form-select form-control-error' : 'form-select'}
                            classNamePrefix="my-react-select"
                            id="specificationOfProduct"
                            isMulti
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                          />
                          {errors.specificationOfProduct && touched.specificationOfProduct ? <p className="error-text">{errors.specificationOfProduct}</p> : null}
                        </div>
                      </div>
                      <hr></hr>
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            handleSubmit();
                          }}
                          className="btn btn-green"
                          disabled={isDisabled}
                        >
                          <span>SUBMIT</span>
                        </button>
                        <Link
                          to={"/host/productlist"}
                          onClick={() => {
                            onClickClearState();
                          }}
                          className="btn btn-black"
                        >
                          <span>CANCEL</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          isOpen={isModal}
          onRequestClose={() => {
            setIsModal(false);
          }}
          contentLabel="Success"
          style={{
            content: {
              width: "500px",
              height: "250px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            },
          }}
        >
          <h3 className='my-4'>
            {selectedInsuranceOption === 'one' 
              ? `Congrats! You have successfully submitted your "${productName}" and it is on the market ready to be rented!`
              : `Thank you for posting your "${productName}". We will review your proof of insurance and verify your listing shortly.`
            }
          </h3>
          <button className='btns btn-lg btn-danger mx-2' onClick={handleProductAdd}>{"OK"}</button>
        </Modal>

        {/* Insurance Option One */}
        <Modal
          isOpen={insuranceOptionOne}
          onRequestClose={() => { setInsuranceOptionOne(false); }}
          contentLabel="Success"
          style={{
            content: {
              width: "1000px",
              height: "800px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            },
          }}
        >
          <h4 className='my-4'>This policy protects you  (only show the one for the applicable product being protected)</h4>
          <h6 className='my-4'>Up to $40,000 in damage for UTV's including your trailer.</h6>
          <h6 className='my-4'>Up to $12,000 in damage for ATV's including your trailer.</h6>
          <h6 className='my-4'>Up to $12,000 in damage for Wave Runners including your trailer.</h6>
          <h6 className='my-4'>Up to $55,000 in damage for Camper’s.</h6>
          <h6 className='my-4'>Up to $55,000 in damage for RV’s.</h6>
          <h6 className='my-4'>Up to $30,000 in damage for Overland.</h6>
          <h6 className='my-4'>Up to $12,000 in damage for Snowmobile including your trailer.</h6>
          <h6 className='my-4'>Up to $10,000 in damage for Dirt Bikes including your trailer.</h6>
          <h6 className='my-4'>Up to $3,000 in damage for E-Bikes including your trailer and racks.</h6>

          <h5 className='my-4'>There is no personal liability to the renter and they will be required to sign a liability waiver to protect everyone.</h5>
          <h6 className='my-4'>There will be a required $39 fee per day from your renter when using this policy.</h6>
          <h6 className='my-4'>The renter will be required to pay all deductibles upon any claim being submitted</h6>
          <h6 className='my-4'>All claims must be submitted 48 hours after the final check in. (any claims submitted later will forfeit the potential protection reimbursement)</h6>

          <h5 className='my-4'>It is important that all Hosts take responsibility in helping the renter understand appropriate driving behaviors when they pick up your product, even if they claim to have experience with your particular product. There are safety rules and policies during checkout to ensure a safe and smooth adventure.”</h5>
          <h5 className='my-4'>By signing you are agreeing to the terms and understand the organization and risk of the outdoorShare protection plan described above.</h5>

          <h6 className=''>Signature Box</h6>
          <div className="signatureCanvasContainerOptionOne" style={{ width: '298px', height: '98px', border: '1px solid black' }}>
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 298, height: 98,  }}
            />
          </div>
          <button className='btns btn-lg btn-upload mx-2' onClick={() => {
            onClicksSignatures();
          }}>{"OK"}</button>
          <button className='btns btn-lg btn-danger mx-2' onClick={() => {
            setFieldValue("insurancePricePerDay", "");
            setFieldValue("insurancePricePerDaySelect", null);
            setInsuranceOptionOne(false);
          }}>{"Cancel"}</button>
          <button className='btns btn-lg btn-secondary' onClick={() => {
            signatureRef.current.clear();
          }}>{"Clear"}</button>
        </Modal>

        {/* Insurance Option Two */}
        <Modal
          isOpen={insuranceOptionTwo}
          onRequestClose={() => { setInsuranceOptionTwo(false) }}
          // className="proofOfProPlanModal"
          style={{
            content: {
              width: "620px", height: uploadFileNames.length > 0 ? "550px" : "450px", top: "50%", left: "50%", transform: "translate(-50%,-50%)",
              display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "top",
            },
          }}
        >
          <div className="proofOfProPlanModal">
            <h2>Proof of protection plan</h2>
            <h6 className='my-4'>Please choose your protection fee you would like to collect per day from the renter.</h6>

            <div className="select-box">
              <Select
                name="insuranceOptionTwoValue"
                value={insuranceOptionTwoValue}
                options={insurancePriceOptionTwoArray}
                onChange={(e) => {
                  setInsuranceOptionTwoValue(e)
                }}
                onBlur={handleBlur}
                placeholder={"Select protection price"}
                // className={'form-select'}
                className={insuranceOptionTwoSelectError === null ? 'form-control h-auto form-control-error' : 'form-control h-auto'}
                classNamePrefix="my-react-select"
                id="insuranceOptionTwoValue"
              // menuPortalTarget={document.body}
              // menuPosition={'fixed'}
              />
              {insuranceOptionTwoSelectError === null ? <p className="error-text">{"Select insurance price"}</p> : null}
            </div>
            {
              !uploadFileName &&
              <div>
                <Link onClick={() => {
                  handleClickInsuranceProof();
                }}
                  className={docSelectError ? "btn btn-green mt-2 mb-2 form-control-error" : "btn btn-green mt-2 mb-2 btn-noHover"}
                >Upload Proof Of Insurance</Link>
                <input ref={insuranceRef} type="file"
                  // accept=".pdf,.doc,.docx" 
                  accept={".pdf,.doc,.docx,.jpg, .jpeg, .png, .webp"}
                  onChange={(e) => { selectDocumentsForInsuranceProof(e) }} multiple="multiple" style={{ display: 'none' }} />
                {docSelectError && <p className="error-text">{"Please Select Image."}</p>}
              </div>
            }
            {

              <div className="cartImagesBlock cartImages-protectionPlanModal">
                <div className="upload-box">
                  {
                    uploadFileNames.length > 0 &&
                    uploadFileNames.map((image, index) => (
                      <>
                        <div className="uploaded">
                          <img src="images/doc.svg" alt="icon" />
                          <div>
                            <div className="d-flex">
                              <p>{image.name}</p>
                              <img src="images/delet.svg" alt="icon" className="check" onClick={() => {
                                onClickRemoveInsuranceProof(index);
                              }} />
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  }
                </div>
              </div>
            }
            <h6 className='my-4'>Signature Box</h6>
            <div className="signatureCanvasContainer" style={{ width: '298px', height: '98px', border: '1px solid black' }}>
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{ width: 298, height: 98,  }}
              />
            </div>
            <div className="flex">
              <button className='btns btn-lg btn-upload mx-2'
                onClick={() => {
                  onClicksSignatures();
                }}><span>Send</span></button>
              <button className='btns btn-lg btn-danger mx-2' onClick={() => {
                setInsuranceOptionTwo(false);
                setFieldValue("insurancePricePerDay", "");
                setInsuranceOptionTwoValue(null);
                setUploadFileName("");
                setUploadFileNames([]);
              }}><span>Cancel</span></button>
              <button className='btns btn-lg btn-secondary' onClick={() => {
                signatureRef.current.clear();
              }}>{"Clear"}</button>
            </div>
          </div>
        </Modal>
      </div>
    </form>
  );
};

export default HostAddProduct;
