import {
    VERIFY_OTP,
    VERIFY_OTP_SUCCESSFUL,
    VERIFY_OTP_FAILED,
    VERIFY_OTP_RESET,
} from "./actionTypes";

const initialState = {
    verifyOtpLoading: false,
    verifyOtpSuccess: false,
    verifyOtpError: false,
    verifyOtpErrorMsg: null,
    verifyOtpData: null,
};

const VerifyOTPReducer = (state = initialState, action) => {

    switch (action.type) {
        case VERIFY_OTP:
            state = {
                ...state,
                verifyOtpLoading: true,
                verifyOtpSuccess: false,
                verifyOtpError: false,
                verifyOtpErrorMsg: null,
                verifyOtpData: null,
            };
            break;
        case VERIFY_OTP_SUCCESSFUL:
            state = {
                ...state,
                verifyOtpLoading: false,
                verifyOtpSuccess: true,
                verifyOtpError: null,
                verifyOtpErrorMsg: null,
                verifyOtpData: action.payload,
            };
            break;
        case VERIFY_OTP_FAILED:
            state = {
                ...state,
                verifyOtpLoading: false,
                verifyOtpSuccess: false,
                verifyOtpError: true,
                verifyOtpErrorMsg: action.payload,
                verifyOtpData: null,
            };
            break;
        case VERIFY_OTP_RESET:
            state = {
                ...state,
                verifyOtpLoading: false,
                verifyOtpSuccess: false,
                verifyOtpError: false,
                verifyOtpErrorMsg: null,
                verifyOtpData: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default VerifyOTPReducer;
