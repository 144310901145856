import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { GET_SITE_SETTING_DETAILS } from "./actionTypes";
import { API_CODE } from '../../helpers/enum'
import { getSiteSettingDetailsFaield, getSiteSettingDetailsSuccessful } from "./action";
import { getSiteSettingDetail } from "../../helpers/fakebackend_helper";

function* getSiteSettingsData({ payload: { user } }) {

    try {
        const response = yield call(getSiteSettingDetail);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(getSiteSettingDetailsSuccessful(response.data));

        } else {
            yield put(getSiteSettingDetailsFaield(response.message));
        }
    } catch (error) {
        yield put(getSiteSettingDetailsFaield(error));
    }
}

export function* watchOnGetSiteSettingDetails() {
    yield takeEvery(GET_SITE_SETTING_DETAILS, getSiteSettingsData);
}

function* siteSettingSaga() {

    yield all([
        fork(watchOnGetSiteSettingDetails),
    ]);
}

export default siteSettingSaga;
