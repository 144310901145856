import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { PLACE_ORDER_CHECKOUT } from "./actionTypes";
import { placeOrderCheckoutSuccessful, placeOrderCheckoutFailed, } from "./action";
import { postPaymentCheckout, } from "../../helpers/fakebackend_helper"
import { API_CODE } from '../../helpers/enum'


function* placeOrderCheckOut({ payload: { user } }) {

    try {
        const response = yield call(postPaymentCheckout, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(placeOrderCheckoutSuccessful(response.data));
        } else {
            yield put(placeOrderCheckoutFailed(response.message));
        }
    } catch (error) {
        yield put(placeOrderCheckoutFailed(error));
    }
}

export function* watchOnPlaceOrderCheckout() {
    yield takeEvery(PLACE_ORDER_CHECKOUT, placeOrderCheckOut);
}

function* PlaceOrderSaga() {

    yield all([
        fork(watchOnPlaceOrderCheckout),
    ]);
}

export default PlaceOrderSaga;
