import {
    VERIFY_OTP,
    VERIFY_OTP_SUCCESSFUL,
    VERIFY_OTP_FAILED,
    VERIFY_OTP_RESET,
} from "./actionTypes";


export const userVerifyOtp = user => {
    return {
        type: VERIFY_OTP,
        payload: { user },
    }
}

export const verifyOtpSuccessful = user => {
    return {
        type: VERIFY_OTP_SUCCESSFUL,
        payload: user,
    }
}

export const verifyOtpFailed = user => {
    return {
        type: VERIFY_OTP_FAILED,
        payload: user,
    }
}

export const verifyOtpReset = user => {
    return {
        type: VERIFY_OTP_RESET,
        payload: user,
    }
}
