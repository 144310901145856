import {
    HOST_UPDATE_REVIEW,
    HOST_UPDATE_REVIEW_SUCCESSFUL,
    HOST_UPDATE_REVIEW_FAILED,
    HOST_UPDATE_REVIEW_RESET,
} from "./actionTypes";

export const hostUpdateReviewDetails = user => {
    return {
        type: HOST_UPDATE_REVIEW,
        payload: { user },
    }
}

export const hostUpdateReviewDetailsSuccessful = user => {
    return {
        type: HOST_UPDATE_REVIEW_SUCCESSFUL,
        payload: user,
    }
}

export const hostUpdateReviewDetailsFailed = user => {
    return {
        type: HOST_UPDATE_REVIEW_FAILED,
        payload: user,
    }
}

export const hostUpdateReviewDetailsReset = user => {
    return {
        type: HOST_UPDATE_REVIEW_RESET,
        payload: user,
    }
}






