import {
    HOST_ORDER_LIST,
    HOST_ORDER_LIST_SUCCESSFUL,
    HOST_ORDER_LIST_FAILED
} from "./actionTypes";

const initialState = {
    OrderError: null,
    loading: false,
    orderListData: null,
    isOrderSuccess: false,
    isOrderError: false
};


const HostOrderReducer = (state = initialState, action) => {

    switch (action.type) {
        case HOST_ORDER_LIST:
            state = {
                ...state,
                loading: true,
                OrderError: null,
                orderListData: null,
                isOrderError: false,
                isOrderSuccess: false,
            };
            break;
        case HOST_ORDER_LIST_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                orderListData: action.payload,
                isOrderSuccess: true,
                OrderError: null,
                isOrderError: false,
            };
            break;
        case HOST_ORDER_LIST_FAILED:
            state = {
                ...state,
                orderListData: null,
                loading: false,
                OrderError: action.payload,
                isOrderError: true,
                isOrderSuccess: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default HostOrderReducer;
