import { call, put, takeEvery } from "redux-saga/effects";
import { RESET_PASSWORD } from "./actionTypes";
import { resetPasswordSuccessful, resetPasswordFailed } from "./action";
import { postResetPasswordUser } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* userResetPassword({ payload: { user } }) {

    try {
        const response = yield call(postResetPasswordUser, user);
        if (response.success === API_CODE.SUCCESS_BOOL) {
            yield put(resetPasswordSuccessful(response));

        } else {
            yield put(resetPasswordFailed(response.resp_message));
        }
    } catch (error) {
        yield put(resetPasswordFailed(error));
    }
}


function* ResetPasswordSaga() {
    yield takeEvery(RESET_PASSWORD, userResetPassword);
}

export default ResetPasswordSaga;
