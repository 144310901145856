import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../Redux/auth/resetPassword/action";
import { toast } from "react-toastify";
import { useFormik } from "formik"
import * as Yup from "yup";
import { otp_timeout, otp_timeout_minute } from '../../config';


const ResetPasswordPassword = (props) => {

    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConformPassword, setShowConformPassword] = useState(false);
    const [seconds, setSeconds] = useState(60 * (otp_timeout_minute / 1000));
    const [formattedTime, setFormattedTime] = useState(convertSecondsToHMS(seconds));

    const initialValuesResetPassword = {
        password: "",
        confirmPassword: "",
        otp: "",
    }
    const resetPasswordSchema = Yup.object({
        otp: Yup.string().min(4, "Otp minimum length should be 4").required('Please enter Otp.'),
        password: Yup.string().min(6, "Password minimum length should be 6").required("Please enter password."),
        confirmPassword: Yup.string().oneOf([Yup.ref('password')], "Password not matched").required("Please enter confirm password."),
    })

    const { values, errors, touched, handleBlur, handleSubmit, resetForm, setFieldValue, } = useFormik({
        initialValues: initialValuesResetPassword,
        validationSchema: resetPasswordSchema,
        onSubmit: (values) => {
            onClickResetPassword(values);
        },
    })

    const resetLoading = useSelector(state => state?.ResetPasswordReducer?.resetLoading);
    const isResetPasswordSuccess = useSelector(state => state?.ResetPasswordReducer?.isResetPasswordSuccess);
    const isResetPasswordError = useSelector(state => state?.ResetPasswordReducer?.isResetPasswordError);

    useEffect(() => {
        if (isResetPasswordSuccess && !resetLoading) {
            toast.success("Password reset.");
            resetForm();
            setFieldValue("isResetPassword", false);
            navigation("/login");
        }
    }, [isResetPasswordSuccess]);

    useEffect(() => {
        if (isResetPasswordError && !resetLoading) { toast.error("Invalid otp/ otp not verified."); }
    }, [isResetPasswordError]);

    function convertSecondsToHMS(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    useEffect(() => {
        if (props.isResetPasswordProps) {
            const timer = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                    setFormattedTime((prev) => {
                        return convertSecondsToHMS(seconds - 1);
                    });
                } else {
                    setFormattedTime('');
                }
            }, otp_timeout); // Update the countdown every 1 second (1000 milliseconds)

            // Clean up the timer when the component unmounts
            return () => clearInterval(timer);
        }
    }, [seconds, props.isResetPasswordProps]);

    //*onClick Reset Password
    const onClickResetPassword = async (values) => {
        let dataObj = {
            "password": values?.confirmPassword,
            "otp": values?.otp,
        };
        dispatch(resetPassword(dataObj));
    };

    const togglePasswordVisibility = () => { setShowPassword(!showPassword);};
    const togglePassworConformdVisibility = () => { setShowConformPassword(!showConformPassword);};

    return (
        <div>
            <form >
                <div>
                    <section className="login-wrapper  py-wrapper" style={{ "backgroundImage": "url(images/login-bg.webp)" }}>
                        <div className="container z1">
                            <div className="bg-transLight register-block">
                                <div className="bg-lightTheme1">
                                    <h1>Reset Password</h1>
                                    <div>
                                        {seconds > 0 ?
                                            <span className="total" style={{ color: '#FF0000' }} >Your verification code expired in : {formattedTime}</span> :
                                            <span className="total" style={{ color: '#FF0000' }} >Your verification code already expired.</span>
                                        }
                                    </div>
                                    <div className="text-start mb-md-5 mb-4">
                                        <div className="row g-md-30 g-md-4 g-3">
                                            <div className="mb-30">
                                                <label htmlFor="user_name">Password<label style={{ color: '#FF0000' }}> * </label></label>
                                                <div className="password-input position-relative d-flex">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        name="password"
                                                        value={values.password}
                                                        onChange={(e) => {
                                                            setFieldValue("password", e.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={errors.password && touched.password ? 'form-control form-control-error' : 'form-control'}
                                                        placeholder="Password"
                                                    />
                                                    <div className="toogleIcon">
                                                        <span
                                                            className="toggle-password"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            <i className={showPassword ? 'fas fa-eye fs-5' : 'fas fa-eye-slash fs-5'}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                {errors.password && touched.password ? <p className="error-text">{errors.password}</p> : null}
                                            </div>
                                            <div className="mb-30">
                                                <label htmlFor="user_name">Confirm Password<label style={{ color: '#FF0000' }}> * </label></label>
                                                <div className="password-input position-relative d-flex">
                                                    <input
                                                        type={showConformPassword ? "text" : "password"}
                                                        name="confirmPassword"
                                                        value={values.confirmPassword}
                                                        onChange={(e) => {
                                                            setFieldValue("confirmPassword", e.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={errors.confirmPassword && touched.confirmPassword ? 'form-control form-control-error' : 'form-control'}
                                                        placeholder="Confirm password"
                                                    />
                                                    <div className="toogleIcon">
                                                        <span
                                                            className="toggle-password"
                                                            onClick={togglePassworConformdVisibility}
                                                        >
                                                            <i className={showConformPassword ? 'fas fa-eye fs-5' : 'fas fa-eye-slash fs-5'}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                {errors.confirmPassword && touched.confirmPassword ? <p className="error-text">{errors.confirmPassword}</p> : null}
                                            </div>
                                            <div>
                                                <label htmlFor="otp">verification code<label style={{ color: '#FF0000' }}> * </label></label>
                                                <input
                                                    maxLength={4}
                                                    type="number"
                                                    name="otp"
                                                    value={values.otp}
                                                    onChange={(e) => {
                                                        let newValue = e.target.value;
                                                        // Remove non-numeric characters and limit to 4 digits
                                                        newValue = newValue.replace(/[^0-9]/g, '').slice(0, 4);
                                                        setFieldValue("otp", newValue);
                                                    }}
                                                    onBlur={handleBlur}
                                                    className={errors.otp && touched.otp ? 'form-control form-control-error' : 'form-control'}
                                                    placeholder="Verification code"
                                                    inputMode="numeric"
                                                // pattern="[0-9]*"
                                                />
                                                {errors.otp && touched.otp ? <p className="error-text">{errors.otp}</p> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" onClick={() => {
                                        handleSubmit();
                                    }} className="btn btn-green"><span>Reset Password</span></button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </form>
        </div>
    );
};

export default ResetPasswordPassword;