import { State, City } from 'country-state-city';

const getStateByName = async (name) => {

  let stateArrayResponse = State.getStatesOfCountry("US");
  let filterState = stateArrayResponse.filter((item) => item?.name === name);
  if (filterState?.length) {
    return { label: filterState[0]?.name, value: filterState[0]?.isoCode };
  } else {
    return false
  }
};

const getStateByCode = async (code) => {

  let stateArrayResponse = State.getStatesOfCountry("US");
  let filterState = stateArrayResponse.filter((item) => item?.isoCode === code);
  if (filterState?.length) {
    return { label: filterState[0]?.name, value: filterState[0]?.isoCode };
  } else {
    return false
  }
};

const getCityByName = async (stateCode, cityName) => {

  let cityArrayResponse = City.getCitiesOfState("US", String(stateCode))
  let filterCity = cityArrayResponse.filter((item) => (item?.name === cityName && item?.stateCode === String(stateCode)));
  if (filterCity.length) {
    return { label: filterCity[0]?.name, value: stateCode };
  } else {
    return false
  }
};

const getCityByCountry = async () => {

  let cityArrayResponse = City.getCitiesOfCountry("US")
  var myArray = [];
  var objectArray = Object.entries(cityArrayResponse);
  objectArray.forEach(([key, value]) => {
    myArray.push({ label: value?.name, value: value?.countryCode });
  })
  if (myArray.length) { return myArray } else { return false }
};


export { getStateByName, getStateByCode, getCityByName, getCityByCountry }
