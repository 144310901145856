import {
  CONTACT_US,
  CONTACT_US_SUCCESSFUL,
  CONTACT_US_FAILED,
  CONTACT_US_RESET,
} from "./actionTypes";

export const contactUSDetail = (user) => {
  return {
    type: CONTACT_US,
    payload: { user },
  };
};

export const contactUSSuccessful = (user) => {
  return {
    type: CONTACT_US_SUCCESSFUL,
    payload: user,
  };
};

export const contactUSField = (user) => {
  return {
    type: CONTACT_US_FAILED,
    payload: user,
  };
};

export const contactUSReset = (user) => {
  return {
    type: CONTACT_US_RESET,
    payload: user,
  };
};
