import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { FINAL_PAYMENT_CHECKOUT } from "./actionTypes";
import { finalPaymentCheckoutSuccessful, finalPaymentCheckoutFailed, } from "./action";
import { postFinalPaymentCheckout, } from "../../helpers/fakebackend_helper"
import { API_CODE } from '../../helpers/enum'


function* finalPaymentCheckOut({ payload: { user } }) {

    // console.log("user----", JSON.stringify(user))
    try {
        const response = yield call(postFinalPaymentCheckout, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(finalPaymentCheckoutSuccessful(response.data));
        } else {
            yield put(finalPaymentCheckoutFailed(response.message));
        }
    } catch (error) {
        yield put(finalPaymentCheckoutFailed(error));
    }
}

export function* watchOnFinalPaymentCheckout() {
    yield takeEvery(FINAL_PAYMENT_CHECKOUT, finalPaymentCheckOut);
}

function* FinalPaymentCheckoutSaga() {

    yield all([
        fork(watchOnFinalPaymentCheckout),
    ]);
}

export default FinalPaymentCheckoutSaga;
