import {
    FINAL_PAYMENT_CHECKOUT,
    FINAL_PAYMENT_CHECKOUT_SUCCESSFUL,
    FINAL_PAYMENT_CHECKOUT_FAILED,
} from "./actionTypes";

const initialState = {
    errorMessageFinalPayment: null,
    loadingFinalPayment: false,
    successFinalPayment: false,
    errorFinalPayment: false,
    finalPaymentCheckoutData: null,
};


const FinalPaymentCheckoutReducer = (state = initialState, action) => {

    switch (action.type) {
        case FINAL_PAYMENT_CHECKOUT:
            state = {
                ...state,
                loadingFinalPayment: true,
                successFinalPayment: false,
                errorFinalPayment: false,
                errorMessageFinalPayment: null,
                finalPaymentCheckoutData: null,
            };
            break;
        case FINAL_PAYMENT_CHECKOUT_SUCCESSFUL:
            state = {
                ...state,
                loadingFinalPayment: false,
                successFinalPayment: true,
                errorMessageFinalPayment: null,
                errorFinalPayment: false,
                finalPaymentCheckoutData: action.payload,
            };
            break;
        case FINAL_PAYMENT_CHECKOUT_FAILED:
            state = {
                ...state,
                loadingFinalPayment: false,
                errorMessageFinalPayment: action.payload,
                errorFinalPayment: true,
                successFinalPayment: false,
                finalPaymentCheckoutData: null,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default FinalPaymentCheckoutReducer;
