import {
    RENTER_ADD_REVIEW,
    RENTER_ADD_REVIEW_SUCCESSFUL,
    RENTER_ADD_REVIEW_FAILED,
    RENTER_ADD_REVIEW_RESET
} from "./actionTypes";

export const renterAddReviewDetails = user => {
    return {
        type: RENTER_ADD_REVIEW,
        payload: { user },
    }
}

export const renterAddReviewDetailsSuccessful = user => {
    return {
        type: RENTER_ADD_REVIEW_SUCCESSFUL,
        payload: user,
    }
}

export const renterAddReviewDetailsFailed = user => {
    return {
        type: RENTER_ADD_REVIEW_FAILED,
        payload: user,
    }
}

export const renterAddReviewDetailsReset = user => {
    return {
        type: RENTER_ADD_REVIEW_RESET,
        payload: user,
    }
}





