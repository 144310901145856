import {
    FINAL_PAYMENT_CHECKOUT,
    FINAL_PAYMENT_CHECKOUT_SUCCESSFUL,
    FINAL_PAYMENT_CHECKOUT_FAILED,
} from "./actionTypes";


export const finalPaymentCheckout = user => {
    return {
        type: FINAL_PAYMENT_CHECKOUT,
        payload: { user },
    }
}

export const finalPaymentCheckoutSuccessful = user => {
    return {
        type: FINAL_PAYMENT_CHECKOUT_SUCCESSFUL,
        payload: user,
    }
}

export const finalPaymentCheckoutFailed = user => {
    return {
        type: FINAL_PAYMENT_CHECKOUT_FAILED,
        payload: user,
    }
}
