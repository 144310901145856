import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../Redux/auth/login/action";
// import './HostDirectLogin.css';

const HostDirectLogin = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoginSucess, setIsLoginSuccess] = useState(false);

  const success = useSelector(state => state?.LoginUser?.success);
  const error = useSelector(state => state?.LoginUser?.error);
  const loginError = useSelector(state => state?.LoginUser?.loginError);
  const user = useSelector(state => state?.LoginUser?.user);

  useEffect(() => {
    if (success && isLoginSucess === false) {
      setIsLoginSuccess(true);
      // dispatch(getUserDetails());
      toast.success("Login successfully.");
      navigate("/info");
    } else {
      // setIsLoginSuccess(false);
      toast.error(loginError);
    }
  }, [success, error]);

  useEffect(() => {
    toast.error(loginError);
  }, [error]);

  useEffect(() => {
    let dataObj = {
      "username": 'no-data@admin.com',
      "password": '123456',
      "id": id,
    };
    // setIsLoginSuccess(true);
    dispatch(loginUser(dataObj));
  }, []);



  return (
    <div>
      <>
        {
          success &&
          <section className="page_404">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 ">
                  <div className="col-sm-10 col-sm-offset-1  text-center">
                    <div className="four_zero_four_bg">
                      <h1 className="text-center ">500</h1>
                    </div>
                    <div className="contant_box_404">
                      <h3 className="h2">
                        Something Went Wrong !
                      </h3>
                      <p>the page you are looking for not avaible!</p>
                      <Link to={'/'} className="Link_404">Go to Home</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </>
    </div>
  )
}

export default HostDirectLogin
