import {
    GET_RENTER_ORDER_DETAILS,
    GET_RENTER_ORDER_DETAILS_SUCCESSFUL,
    GET_RENTER_ORDER_DETAILS_FAILED,
} from "./actionTypes";


export const getRenterOrderDetails = user => {
    return {
        type: GET_RENTER_ORDER_DETAILS,
        payload: { user },
    }
}

export const getRenterOrderDetailsSuccessful = user => {
    return {
        type: GET_RENTER_ORDER_DETAILS_SUCCESSFUL,
        payload: user,
    }
}

export const getRenterOrderDetailsFailed = user => {
    return {
        type: GET_RENTER_ORDER_DETAILS_FAILED,
        payload: user,
    }
}
