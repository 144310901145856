import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hostListProduct } from "../../../../Redux/actions";
import Slider from "react-slick";
import { api } from '../../../../config';

const ViewSimilarProducts = () => {

  const user = useSelector(state => state.HostListProduct.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hostListProduct({ page: 1 }));
  }, []);


  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/productdetails/${id}`);
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: user?.docs.length === 1 ? 1 : user?.docs.length === 2 ? 2 : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1029,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 410,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="products-wrapper py-wrapper overflow-hidden">
      <div className="container">
        <div className="heading">
          <h2>View Similar Products</h2>
        </div>
        <div className="productsNew-slider slider">
          <Slider {...settings}>
            {user?.docs &&
              user?.docs.map((item, index) => {

                const totalRatings = item?.reviews_data.reduce(
                  (sum, rating) => sum + rating.rate, 0
                );

                const averageRating = item?.reviews_data.length > 0 ? totalRatings / item?.reviews_data.length : 0;

                const filledStars = Math.floor(averageRating);
                const emptyStars = 5 - filledStars;

                const stars = [];
                for (let i = 0; i < filledStars; i++) {
                  stars.push(
                    <i
                      key={i}
                      className="fa-solid fa-star"
                      style={{ color: "#FFA500", fontSize: "18px" }}
                    ></i>
                  );
                }
                for (let i = 0; i < emptyStars; i++) {
                  stars.push(
                    <i
                      key={i + filledStars}
                      className="fa-regular fa-star"
                      style={{ fontSize: "18px" }}
                    ></i>
                  );
                }
                return (
                  <div className="products-block bg-white" key={index}>
                    <div className="ratio cover">
                      <img
                        src={`${item?.images_detail[0]?.path}`}
                        alt={item?._id + '_' + index}
                        onError={event => {
                          event.target.src = '/images/default_image.webp'
                          event.onerror = null
                        }}
                      />
                    </div>
                    <div className="text-center">
                      {item?.product_name.length > 15 ? (
                        <h5 className="product-break-word">{item?.product_name}</h5>
                      ) : (
                        <h5>{item?.product_name}</h5>
                      )}
                      <div className="rating">
                        {stars}
                        <span>({item?.reviews_data.length})</span>
                      </div>
                      <h4>
                        ${item?.price_per_day}
                        <span>/ Day</span>
                      </h4>
                      <button onClick={() => handleClick(item?._id)}
                        className="btn btn-green">
                        <span>RENT IT</span>
                      </button>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ViewSimilarProducts;
