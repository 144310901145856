import { createStore, applyMiddleware, compose, } from "redux";
import { persistStore, } from 'redux-persist'
import createSagaMiddleware from "redux-saga";
import persistedReducer from "./reducers";
import rootSaga from "./sagas";


const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export function configureStore(initialState) {

    const store = createStore(
        persistedReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(...middlewares)
        ),
    );

    const persistor = persistStore(store)

    sagaMiddleware.run(rootSaga);
    return { store, persistor }
}