import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import "./orderProgressSteps.css";

const OrderProgressSteps = ({ dataFromParent }) => {


  const [allStatus, setAllStatus] = useState([
    'placed order',
    'accepted / rejected',
    'renter agreement',
    'check out',
    'check in',
    'completed',
  ]);

  useEffect(() => {

  }, []);


  return (
    <section className="row pb-4">
      <div className="task-progress-header">Your Order Progress</div>
      <div className="container">
        <ul className="progressbar">
          {allStatus.map((status, index) => (
            <li key={index} className={index < dataFromParent ? "active" : ""}>
              <a href="javascript:void(0)" >{status}</a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default OrderProgressSteps;
