import {
    RESEND_VERIFY_OTP,
    RESEND_VERIFY_OTP_SUCCESSFUL,
    RESEND_VERIFY_OTP_FAILED,
    RESEND_VERIFY_OTP_RESET,
} from "./actionTypes";


export const resendOTP = user => {
    return {
        type: RESEND_VERIFY_OTP,
        payload: { user },
    }
}

export const resendOTPSuccessful = user => {
    return {
        type: RESEND_VERIFY_OTP_SUCCESSFUL,
        payload: user,
    }
}

export const resendOTPFailed = user => {
    return {
        type: RESEND_VERIFY_OTP_FAILED,
        payload: user,
    }
}

export const resendOTPReset = user => {
    return {
        type: RESEND_VERIFY_OTP_RESET,
        payload: user,
    }
}