import React, { useRef, useState } from "react";
import RenterBannerslider from "../../../Common/Bannerslider";
import Review from "./Review";
import ViewSimilarProducts from "./ViewSimilarProducts";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productDetail } from "../../../../Redux/actions";
import { useParams, useNavigate } from "react-router-dom";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { productAddCard } from "../../../../Redux/addToCard/action";
import { toast } from "react-toastify";
import uuid from "react-uuid";
import { api } from "../../../../config";
import Header from "../../../../Layouts/Header";
import Footer from "../../../../Layouts/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import InputMask from 'react-input-mask';
import './productDetails.css'

export default function RenterProductDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  const datePickerRef = useRef(null);
  const dateReturnRef = useRef(null);
  const [minReturnDate, setMinReturnDate] = useState(null);

  const [avgRating, setAvgRating] = useState({});
  const [starsData, setStarsData] = useState([]);
  const [specificationList, setSpecificationList] = useState([]);

  const [productPickupDate, setProductPickupDate] = useState(null);
  const [productPickupTime, setProductPickupTime] = useState("08:00");
  const [productReturnDate, setProductReturnDate] = useState(null);
  const [productReturnTime, setProductReturnTime] = useState("17:00");
  const [productCaptain, setProductCaptain] = useState("");
  const [isDateDisable, setIsDateDisable] = useState(true);

  const dispatch = useDispatch();

  const user = useSelector(state => state.ProductDetail.user);
  const allReviews = user?.product?.reviews_data;

  const reviewsData = () => {
    const totalRatings = allReviews?.reduce(
      (sum, rating) => sum + rating.rate,
      0
    );
    const averageRating = totalRatings / allReviews?.length;
    setAvgRating({
      averageRating: averageRating,
      totalReview: allReviews?.length,
    });
  };

  const rattingData = () => {
    const { averageRating } = avgRating;

    const filledStars = Math.floor(averageRating);

    const emptyStars = 5 - filledStars;

    let stars = [];
    // Add filled stars
    for (let i = 0; i < filledStars; i++) {
      stars.push(
        <i
          key={i}
          className="fa-solid fa-star"
          style={{ color: "#FFA500", fontSize: "18px" }}
        ></i>
      );
    }

    // Add empty stars
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <i
          key={i + filledStars}
          className="fa-regular fa-star"
          style={{ fontSize: "18px" }}
        ></i>
      );
    }

    setStarsData(stars);
  };

  useEffect(() => {
    reviewsData();
  }, [user]);

  useEffect(() => {
    rattingData();
  }, [avgRating]);

  // const id = "64e58b0a2280ef4b32298f23";

  const fetchData = async () => {
    try {
      const dataObj = { id };
      await dispatch(productDetail(dataObj));
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const [activeSlide, setActiveSlide] = useState(0);
  const mainSliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);

  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    asNavFor: thumbnailSliderRef.current,
    afterChange: (currentSlide) => setActiveSlide(currentSlide),
  };

  const sliderSettings1 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    asNavFor: mainSliderRef.current,
    dots: false,
  };

  const handleThumbnailClick = (index) => {
    mainSliderRef.current.slickGoTo(index);
  };

  //*onclick Add to card
  const onClickAddCard = (e) => {
    if (
      productPickupDate === null ||
      productPickupDate === ""
    ) {
      toast.error("Please select product pickup date.");
      return false;
    } else if (
      productReturnDate === null ||
      productReturnDate === ""
    ) {
      toast.error("Please select product return date.");
      return false;
    }
    else if (user.product.product_type === "Boats" || user.product.product_type === "Utv’s") {
      if (productCaptain === "" || productCaptain === null) {
        user.product.product_type === "Boats" ? toast.error("Please select boat captain.") : toast.error("Please select utv’s captain.");
        return false;
      }
    }

    //*calculate Approximate Final Amount
    const pickupDate = new Date(productPickupDate);
    const returnDate = new Date(productReturnDate);
    pickupDate.setHours(0, 0, 0, 0);
    returnDate.setHours(0, 0, 0, 0);
    const timeDifference = returnDate.getTime() - pickupDate.getTime() + (24 * 60 * 60 * 1000);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const approximateFinalAmount = daysDifference * user.product.price_per_day;

    //*calculate insurance Final Amount
    const FinalInsuranceAmount = daysDifference * user.product.insurance_amount;

    let dataObj = {
      uniqueId: uuid(),
      productPickupDate: productPickupDate,
      productPickupTime: productPickupTime,
      productReturnDate: productReturnDate,
      productReturnTime: productReturnTime,
      product_type: user.product.product_type,
      product_name: user.product.product_name,
      product_location: user.product.product_location,
      _id: user.product._id,
      description: user.product.description,
      delivery_charge: user.product.delivery_charge,
      price_per_day: user.product.price_per_day,
      images_detail: user.product.images_detail,
      insurance_amount: FinalInsuranceAmount,
      insurance_amount_per_day: user.product.insurance_amount,
      security_deposit: user.product.security_deposit,
      who_captain: productCaptain,
      approximateFinalAmount:
        daysDifference === 0
          ? user.product.price_per_day
          : approximateFinalAmount,
      totalDays: daysDifference === 0 ? 1 : daysDifference,
    };
    dispatch(productAddCard(dataObj));
    
    let token = localStorage.getItem("accessToken");
    if (token === null) {
      toast.error("Please login.")
      navigate("/login", { state: { redirectTo: "/cart" } });
    } else {
      navigate("/cart");
    }
};


  const handleCalendarIconReturnClick = () => {
    if (dateReturnRef.current) {
      dateReturnRef.current.setOpen(true);
    }
  };

  const handleCalendarIconPickClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  useEffect(() => {
    if (productPickupDate) {
      const minDate = moment(productPickupDate, "MM/DD/YYYY").toDate();
      setMinReturnDate(minDate);
    }
  }, [productPickupDate]);

  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  useEffect(() => {
    if (user?.product?.specification) {
      let specificationArray = user?.product?.specification.split(',');
      let finalArray = [];
      specificationArray.map((item, index) => {
        let str = item.trim();
        let firstChar = str.charAt(0);
        let obj = { "name": str, "icon": firstChar }
        finalArray.push(obj)
        if (index === specificationArray.length - 1) {
          setSpecificationList(finalArray);
        }
      })
    }
  }, [user]);

  return (
    <div>
      <Header />
      <RenterBannerslider headerName={"Product Details"} />
      <section className="pyCustom-wrapper overflow-hidden">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-9 col-md-8 productDetails-main">
              <div className="row g-4  mb-3 pb-3">
                <div className="col-md-6">
                  <div className="slider productDetails-for">
                    <Slider {...sliderSettings} ref={mainSliderRef}>
                      {user?.product &&
                        user?.product?.images_detail.map((image, index) => {
                          return (
                            <div className="img-obj cover" key={index}>
                              <img
                                src={`${image.path}`}
                                alt={image?._id + "_" + index}
                                onError={(event) => {
                                  event.target.src =
                                    "/images/default_image.webp";
                                  event.onerror = null;
                                }}
                              />
                              {user?.product?.is_booked ? (
                                <span className="book-status">Booked</span>
                              ) : null}
                            </div>
                          );
                        })}
                    </Slider>
                  </div>

                  <div className="slider productDetails-nav">
                    <Slider {...sliderSettings1} ref={thumbnailSliderRef}>
                      {user?.product &&
                        user?.product?.images_detail.map((image, index) => {
                          return (
                            <div
                              className={`img-obj cover ${index === activeSlide ? "active" : ""
                                }`}
                              key={index}
                              onClick={() => handleThumbnailClick(index)}
                            >
                              <img
                                src={`${image.path}`}
                                alt={image?._id + "_" + index}
                                onError={(event) => {
                                  event.target.src =
                                    "/images/default_image.webp";
                                  event.onerror = null;
                                }}
                              />
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                </div>
                <div className="col-md-6 productDetails-block">
                  <h3 className="break-word">
                    {user?.product?.product_name}
                    {user?.product?.is_booked}
                  </h3>

                  {avgRating.totalReview !== 0 && (
                    <div className="d-flex align-items-center mb-3">
                      <div className="rating">
                        {starsData}
                        <span>({avgRating.totalReview})</span>
                      </div>
                    </div>
                  )}

                  {/* <div className="text-danger ms-2">Booked</div> */}

                  <p>
                    <b>Owned by:</b>{" "}
                    {/* <b style={{ fontSize: "22px" }}> */}
                    <span style={{ fontSize: "19px" }}>
                      {user?.product?.host_data?.first_name}{" "}
                      {user?.product?.host_data?.last_name}
                    </span>
                    {/* </b> */}
                  </p>
                  <p>
                    <b>Location: </b>{" "}
                    {user?.product?.product_location.map((location, index) => (
                      <span key={index}>
                        {location}
                        {index < user.product.product_location.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </p>
                  <h4>
                    ${user?.product?.price_per_day}
                    <span>/ Day</span>
                  </h4>
                  <div className="productDetails-value">
                    <p>
                      <span>SKU:</span>{" "}
                      {user?.product?.sku}
                    </p>
                    <p>
                      <span>CATEGORY:</span> {user?.product?.product_type}
                    </p>
                  </div>
                  <h5>Product Features</h5>
                  <div className="specificationList__items">
                    {
                      specificationList.map((item, index) => {
                        return (
                          <div key={index} className="specificationList_Inside">
                            <div className="specificationList__icon">
                              {/* {item.icon} */}
                            </div>
                            <div className="specificationList__text">{renderHTML(item.name)}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="productDetails-des">
                <h5>Description</h5>
                <p>{user?.product?.description}</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="productDetails-form">
                <div className="productDetails-head">
                  <p>FROM ${user?.product?.price_per_day} /PER DAY</p>
                </div>
                <div className="productDetails-body">
                  <div className="">
                    <label htmlFor="pickup_date">
                      Pickup Date
                      <label style={{ color: "#FF0000" }}>* </label>
                    </label>
                    <div className="input-group">
                      <DatePicker
                        selected={productPickupDate ? moment(productPickupDate, 'MM/DD/YYYY').toDate() : null}
                        onChange={(date) => {
                          if (date) {
                            const formattedDate = moment(date).format("MM/DD/YYYY");
                            setProductPickupDate(formattedDate);
                            setIsDateDisable(false);
                          } else {
                            setProductPickupDate("");
                            setIsDateDisable(true);
                          }
                        }}
                        id="product_date"
                        className="form-control py-2"
                        placeholderText="MM/DD/YYYY"
                        dateFormat="MM/dd/yyyy" // Change the format to MM/DD/YYYY
                        ref={datePickerRef}
                        minDate={moment(new Date(), "MM/DD/YYYY").toDate()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={90}
                        customInput={
                          <InputMask
                            mask="99/99/9999"
                            value={productPickupDate}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setProductPickupDate(inputValue);
                            }}
                            placeholder="MM/DD/YYYY"
                          />
                        }
                      />
                      <div className="input-group-append position-absolute product-date-picker">
                        <span
                          // className="input-group-text"
                          onClick={handleCalendarIconPickClick}
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            fill="#000000"
                            width="25px"
                            height="27px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z" />
                            <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="return_date">
                      Return Date
                      <label style={{ color: "#FF0000" }}>* </label>
                    </label>
                    <div className="input-group">
                      <DatePicker
                        selected={productReturnDate ? moment(productReturnDate, 'MM/DD/YYYY').toDate() : null}
                        onChange={(date) => {
                          if (date) {
                            const formattedDateRetn = moment(date).format("MM/DD/YYYY");
                            setProductReturnDate(formattedDateRetn);
                          } else {
                            setProductReturnDate("");
                          }
                        }}
                        disabled={isDateDisable}
                        minDate={minReturnDate}
                        id="return_date"
                        className="form-control py-2"
                        placeholderText="MM/DD/YYYY"
                        dateFormat="MM/dd/yyyy" // Change the format to MM/DD/YYYY
                        ref={dateReturnRef}
                        showYearDropdown
                        scrollableMonthYearDropdown

                        scrollableYearDropdown
                        yearDropdownItemNumber={90}
                        customInput={
                          <InputMask
                            mask="99/99/9999"
                            value={productReturnDate}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setProductReturnDate(inputValue);
                            }}
                            placeholder="MM/DD/YYYY"
                          />
                        }
                      />
                      <div className="input-group-append position-absolute product-date-picker">
                        <span
                          // className="input-group-text"
                          onClick={handleCalendarIconReturnClick}
                        >
                          <svg
                            fill="#000000"
                            width="25px"
                            height="27px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z" />
                            <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  {user?.product?.is_captain || user?.product?.product_type === "Utv’s" ? (
                    <div className="captainSection">
                      <h6>Select Captain</h6>
                      <select
                        className="form-select"
                        value={productCaptain}
                        onChange={(e) => {
                          setProductCaptain(e.target.value);
                        }}
                        id="who_captain"
                      >
                        <option value="">Select Captain Here..</option>
                        <option
                          value="owner"
                          disabled={user?.product?.who_captain === "self"}
                        >
                          Owner/Host
                        </option>
                        <option value="self">Self/Renter</option>
                      </select>
                    </div>
                  ) : null
                  }
                  <div className="">
                    <label htmlFor="return_date">Protection Plan Per Day</label>
                    <p>
                      ${user?.product?.insurance_amount}
                    </p>
                    <label htmlFor="return_date">Deposit</label>
                    <p>
                      {user?.product?.product_type} Security Deposit <br></br>{" "}
                      <small>
                        {" "}
                        {user?.product?.security_deposit} - One Time
                      </small>
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      onClickAddCard();
                    }}
                    className="btn btn-green w-100 "
                  >
                    <span>BOOK NOW</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Review rating={avgRating} allReviews={allReviews} />
      <ViewSimilarProducts />
      <Footer />
    </div>
  );
}
