import {
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED
} from "./actionTypes";

const initialState = {
    registrationError: null,
    message: null,
    loading: false,
    user: null,
    registrationMessage: "",
    success: false,
    error: false
};


const RegisterUser = (state = initialState, action) => {

    switch (action.type) {
        case REGISTER_USER:
            state = {
                ...state,
                loading: true,
                registrationError: null,
            };
            break;
        case REGISTER_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                // user: action.payload,
                success: true,
                registrationError: null,
                error: false,
            };
            break;
        case REGISTER_USER_FAILED:
            state = {
                ...state,
                // user: null,
                loading: false,
                registrationError: action.payload,
                error: true,
                success: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default RegisterUser;
