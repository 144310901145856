import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment/moment";
import Slider from "react-slick";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import "./Bannerslider.css";
import { getCityDetails } from "../../Redux/common/getCities/action"

const Bannerslider = () => {

  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [location, setLocation] = useState("");

  const [locationList, setLocationList] = useState([]);
  const [filteredLocationList, setFilteredLocationList] = useState([]);

  const getCityErrorMessage = useSelector(state => state?.GetCityReducer?.getCityErrorMessage);
  const getCityLoading = useSelector(state => state?.GetCityReducer?.getCityLoading);
  const getCityData = useSelector(state => state?.GetCityReducer?.getCityData);
  const getCitySuccess = useSelector(state => state?.GetCityReducer?.getCitySuccess);

  useEffect(() => {
    dispatch(getCityDetails());
  }, []);

  //* get city response
  useEffect(() => {
    if (getCitySuccess && !getCityLoading && getCityData) {
      setLocationList(getCityData);
      setFilteredLocationList(getCityData.slice(0, 50));
    }
  }, [getCitySuccess]);

  useEffect(() => {
    if (getCityErrorMessage && !getCityLoading) {
      setLocationList([]);
    }
  }, [getCityErrorMessage]);

  const handleLocationSearchChange = (event) => {
    let search = event.trim().toLowerCase();
    if (search && search.length > 2) {
      let newArray = locationList?.filter(function (el) {
        return el.value.toLowerCase().includes(search);
      });
      setFilteredLocationList(newArray);
    } else {
      setFilteredLocationList(locationList.slice(0, 50));
    }
  };

  // useEffect(() => {
  //   getLocations();
  // }, []);

  //*list
  const vehiclesList = [
    {
      label: "Select Vehicle Type",
      value: "",
    },
    {
      label: "UTV’s",
      value: "Utv’s",
    },
    {
      label: "ATV’s",
      value: "Atv’s",
    },
    {
      label: "Campers/RVs",
      value: "Campers/Rv’s",
    },
    {
      label: "WaveRunners",
      value: "Wave runners",
    },
    {
      label: "Boats",
      value: "Boats",
    },
    {
      label: "Trailers",
      value: "Trailers",
    },
    {
      label: "Trucks",
      value: "Trucks",
    },
    {
      label: "SUVs",
      value: "SUV",
    },
    {
      label: "E-Bikes",
      value: "EBikes",
    },
    {
      label: "Snowmobiles",
      value: "Snowmobiles",
    },
    {
      label: "Overland",
      value: "Overland",
    },
    {
      label: "Dirt Bike’s",
      value: "DirtBikes",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  let todayDate = new Date();
  let initialStartDate = `${todayDate.getUTCMonth()}/${todayDate.getUTCDate()}/${todayDate.getUTCFullYear()}`;
  let initialEndDate = `${todayDate.getUTCMonth() + 1
    }/${todayDate.getUTCDate()}/${todayDate.getUTCFullYear()}`;

  const [dates, setDatesState] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });

  const setDates = (e, { startDate, endDate }) => {
    setDatesState({
      startDate: startDate.format("MM/DD/YYYY"),
      endDate: endDate.format("MM/DD/YYYY"),
    });
  };

  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  const slickSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: true,
  };
  const navigate = useNavigate();

  const handleSearchSubmit = () => {
    const filterData = {
      page: 1,
      search_string: search?.value,
      product_location: location,
      start_date: dates?.startDate,
      end_date: dates?.endDate,
    };
    navigate("/productlist", { state: { filterData } });
  };

  const handleSearchInputChange = (event) => { setSearch(event); };
  const handleLocationChange = (event) => { setLocation(event); };
  const handleDateChange = (event) => { return true; };

  const images = [
    "images/banner.webp",
    "images/Banner1.png",
    "images/Banner2.png",
    "images/Banner3.png",
    "images/Banner4.png",
    "images/Banner5.png",
    "images/Banner6.png",
  ];

  return (
    <section className="banner-wrapper  position-relative">
      <div className="position-absolute w-100">
        <div className="container banner-content z1">
          <div className="text-white">
            <h4>GET FLEXIBLE RENTAL DEALS</h4>
            <h1>
              Choose Your <span> Adventurous Ride</span> and Begin Exploring!
            </h1>
          </div>
          <div className="bg-transLight">
            <div className="bg-lightTheme1">
              <form action="#">
                <div className="row g-2">
                  <div className="col-lg-3 col-sm-6">
                    <Select
                      value={search}
                      options={vehiclesList}
                      onChange={(e) => {
                        handleSearchInputChange(e);
                      }}
                      className="form-select"
                      classNamePrefix="my-react-select"
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <Select
                      value={location}
                      options={filteredLocationList}
                      onChange={(e) => {
                        handleLocationChange(e);
                      }}
                      onInputChange={(e) => {
                        handleLocationSearchChange(e);
                      }}
                      placeholder={"Enter city"}
                      className="form-select"
                      classNamePrefix="my-react-select"
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="date-input">
                      <DateRangePicker
                        onApply={setDates}
                        initialSettings={{
                          startDate: initialStartDate,
                          endDate: initialEndDate,
                          ranges: ranges,
                        }}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            value={dates.startDate + " - " + dates.endDate}
                            className="form-control"
                            onChange={(e) => handleDateChange(e.target.value)}
                          />
                          <span className="input-group-addon  position-absolute register-date-picker">
                            <svg
                              fill="#000000"
                              width="28px"
                              height="28px"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z" />
                              <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z" />
                            </svg>
                          </span>
                        </div>
                      </DateRangePicker>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <button
                      type="button"
                      onClick={handleSearchSubmit}
                      className="btn btn-green w-100"
                    >
                      <span>FIND NOW</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-slider slider">
        <Slider {...slickSettings}>
          {images.map((imageSrc, index) => (
            <div key={index}>
              <div className="banner-img img-obj cover">
                <img src={imageSrc} alt={`category_image ${index}`} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Bannerslider;
