import {
    UPDATE_ORDER_STATUS,
    UPDATE_ORDER_STATUS_SUCCESSFUL,
    UPDATE_ORDER_STATUS_FAILED,
    UPDATE_ORDER_STATUS_RESET,
} from "./actionTypes";

const initialState = {
    statusErrorMessage: null,
    statusLoading: false,
    statusSuccess: false,
    statusError: false,
    updateOrderStatusData: null,
};


const UpdateOrderStatusReducer = (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_ORDER_STATUS:
            state = {
                ...state,
                statusLoading: true,
                statusSuccess: false,
                statusError: false,
                statusErrorMessage: null,
                updateOrderStatusData: null,
            };
            break;
        case UPDATE_ORDER_STATUS_SUCCESSFUL:
            state = {
                ...state,
                statusLoading: false,
                statusSuccess: true,
                statusErrorMessage: null,
                statusError: false,
                updateOrderStatusData: action.payload,
            };
            break;
        case UPDATE_ORDER_STATUS_FAILED:
            state = {
                ...state,
                statusLoading: false,
                statusErrorMessage: action.payload,
                statusError: true,
                statusSuccess: false,
                updateOrderStatusData: null,
            };
            break;
        case UPDATE_ORDER_STATUS_RESET:
            state = {
                ...state,
                statusErrorMessage: null,
                statusLoading: false,
                statusSuccess: false,
                statusError: false,
                updateOrderStatusData: null,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default UpdateOrderStatusReducer;
