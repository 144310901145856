import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESSFUL,
  LOGIN_USER_NOT_FOUND,
  LOGIN_RESET_STATE,
} from "./actionTypes";

const initialState = {
  loginError: null,
  message: null,
  loading: false,
  user: null,
  success: false,
  error: false,
  isUserNotFound: false,
};

const LoginUser = (state = initialState, action) => {

  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        loginError: null,

      };
      break;

    case LOGIN_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        success: true,
        loginError: null,
        isUserNotFound: false,
      };
      break;

    case LOGIN_USER_FAILED:
      state = {
        ...state,
        loading: false,
        user: null,
        loginError: action.payload,
        error: true,
        isUserNotFound: false,
      };
      break;

    case LOGIN_USER_NOT_FOUND:
      state = {
        ...state,
        loading: false,
        user: null,
        loginError: null,
        error: false,
        isUserNotFound: true,
      };
      break;

    case LOGIN_RESET_STATE:
      state = {
        ...state,
        loginError: null,
        message: null,
        loading: false,
        user: null,
        success: false,
        error: false,
        isUserNotFound: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LoginUser;
