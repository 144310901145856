import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { UPDATE_ORDER_STATUS } from "./actionTypes";
import { updateOrderStatusSuccessful, updateOrderStatusFailed, } from "./action";
import { postUpdateOrderStatus, } from "../../helpers/fakebackend_helper"
import { API_CODE } from '../../helpers/enum'


function* updateOrderStatusDetails({ payload: { user } }) {

    try {
        const response = yield call(postUpdateOrderStatus, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(updateOrderStatusSuccessful(response.data));
        } else {
            yield put(updateOrderStatusFailed(response.message));
        }
    } catch (error) {
        yield put(updateOrderStatusFailed(error));
    }
}

export function* watchOnUpdateOrderStatus() {
    yield takeEvery(UPDATE_ORDER_STATUS, updateOrderStatusDetails);
}

function* UpdateOrderStatusSaga() {

    yield all([
        fork(watchOnUpdateOrderStatus),
    ]);
}

export default UpdateOrderStatusSaga;
