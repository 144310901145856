import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESSFUL,
  LOGIN_USER_NOT_FOUND,
  LOGIN_RESET_STATE,
} from "./actionTypes";

export const loginUser = user => {
  return {
    type: LOGIN_USER,
    payload: { user },
  };
};

export const loginUserSuccessful = user => {
  return {
    type: LOGIN_USER_SUCCESSFUL,
    payload: user,
  };
};

export const loginUserFaield = user => {
  return {
    type: LOGIN_USER_FAILED,
    payload: user,
  };
};

export const loginUserNotFound = user => {
  return {
    type: LOGIN_USER_NOT_FOUND,
    payload: user,
  };
};

export const loginResetState = user => {
  return {
    type: LOGIN_RESET_STATE,
    payload: user,
  };
};


