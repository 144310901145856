import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { FETCH_MESSAGE_DETAILS } from "./actionTypes";
import { fetchMessageDetailsSuccessful, fetchMessageDetailsFailed } from "./action";
import { getFetchMessageDetails } from "../../../helpers/fakebackend_helper";

function* fetchMsgDetailsOnLoad({ payload }) {
    try {
        const response = yield call(getFetchMessageDetails, payload);
        yield put(fetchMessageDetailsSuccessful(response));
    } catch (error) {
        yield put(fetchMessageDetailsFailed(error));
    }
}

export function* watchOnFetchMsg() {
    yield takeEvery(FETCH_MESSAGE_DETAILS, fetchMsgDetailsOnLoad);
}

function* FetchMsgSaga() {
    yield all([
        fork(watchOnFetchMsg),
    ]);
}

export default FetchMsgSaga;
