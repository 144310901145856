import React, { useEffect, useState, useRef } from "react";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ROLE_TYPES, } from '../../helpers/enum';
import { City } from 'country-state-city';
import { ConvertResponseForCountry, ConvertToBinary } from '../../helpers/commonfunction'
import moment from 'moment';
import { api } from '../../config';
import * as url from "../../helpers/url_helper";
import { API_CODE } from "../../helpers/enum";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import './register.css'
import Alert from 'react-bootstrap/Alert';
import { useFormik } from "formik"
import * as Yup from "yup";
import InputMask from 'react-input-mask';
import stateJSON from '../../localJSONFiles/states.json'
import VerifyOtp from "./verifyOtp";
import { getPromoCodeDetails, addPromoCodeAndHost } from "../../Redux/actions";

const Register = () => {

  const ref = useRef()
  const dispatch = useDispatch();
  const datePickerRef = useRef(null);

  //*State
  //*Register
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");
  const [showAlertRegister, setShowAlertRegister] = useState(false);
  const [videoText, setVideoText] = useState("");
  const [isShowLoader, setIsShowLoader] = useState(false);

  //*Verify Otp
  const [userId, setUserId] = useState("");
  const [sendUserEmail, setSendUserEmail] = useState("");

  //*List
  const [countryArray] = useState([{ "label": "United States", "value": "US" }]);
  const [stateArray, setStateArray] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const roleList = [
    { label: "Renter", value: ROLE_TYPES.RENTER },
    { label: "Host", value: ROLE_TYPES.HOST }
  ]
  const [docList, setDocList] = useState([]);
  const [fileList, setFileList] = useState([]);

  const initialValues = {
    role: null,
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    password: "",
    confirmPassword: "",
    countryValue: null,
    stateValue: null,
    city: "",
    address: "",
    zip_code: "",
    dateOfBirth: null,
    attachment: 0,
    isVerifyOtp: false,
    promoCode: "",
  }
  // const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const registerSchema = Yup.object({
    role: Yup.object().required("Please select role"),
    firstName: Yup.string().min(2, "First name must be at least 2 characters").max(30).required('Please enter first name'),
    lastName: Yup.string().min(2, "Last name must be at least 2 characters").max(30).required('Please enter last name'),
    email: Yup.string()
      .test(
        'oneOfRequired',
        'Please enter email or phone number',
        function (item) {
          let email = this.parent.email; let phoneNo = this.parent.phoneNo;
          if (!email && (!phoneNo || phoneNo === "1")) {
            return this.createError({ message: "Please enter a valid email or a valid phone number." });
          }
          // Use a regular expression to validate the email address
          if (email) {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!email.match(emailRegex)) {
              return this.createError({ message: "Please enter a valid email address." });
            }
          }
          return true;
        }
      ),
    phoneNo: Yup.string()
      .test(
        'oneOfRequired',
        'Please enter email or phone number',
        function (item) {
          let email = this.parent.email; let phoneNo = this.parent.phoneNo;
          if (!email && (!phoneNo || phoneNo === "1")) {
            return this.createError({ message: "Please enter a valid email or a valid phone number." });
          }
          return true;
        }
      ),
    password: Yup.string().min(6, "Password minimum length should be 6").required("Please enter password."),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], "Password not matched").min(6, "confirm password minimum length should be 6").required("Please enter confirm password"),
    // countryValue: Yup.object().required('Please select country'),
    stateValue: Yup.object().required('Please select state'),
    city: Yup.string().required('Please Enter city'),
    zip_code: Yup.string().min(5).max(5).required('Please Enter ZIP Code'),
    address: Yup.string().min(2, "Address must be at least 2 characters").max(100).required('Please enter address'),
    dateOfBirth: Yup.string().default(() => new Date()).required('Please select date of birth'),
    isVerifyOtp: Yup.boolean(),
    // promoCode: Yup.string()
    //   .when('role.value', {
    //     is: "2",
    //     then: () =>
    //       Yup.string().min(2, "Promo Code must be at least 2 characters").max(100).required('Please enter Promo Code'),
    //   }),
  })

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, resetForm, setFieldValue, } = useFormik({
    initialValues: initialValues,
    validationSchema: registerSchema,
    onSubmit: (values) => {
      if (values.role.value === "2" && values.promoCode !== "") {
        onClickCheckPromocode();
      } else {
        onClickRegisterUser();
      }
    },
  })

  const success = useSelector(state => state?.RegisterUser?.success);
  const error = useSelector(state => state?.RegisterUser?.error);
  const registrationError = useSelector(state => state?.RegisterUser?.registrationError);

  const promoCodeDetailsErrorMessage = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsErrorMessage);
  const promoCodeDetailsLoading = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsLoading);
  const promoCodeDetailsSuccess = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsSuccess);
  const promoCodeDetailsError = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsError);
  const promoCodeDetailsOrderData = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsOrderData);  

  useEffect(() => {
    if (promoCodeDetailsOrderData?.promocode.status === true && !isPromoCodeExpired(promoCodeDetailsOrderData?.promocode.startDate, promoCodeDetailsOrderData?.promocode.endDate)) {
      toast.success("Promo code applied successfully.");
      //*register user
      onClickRegisterUser(promoCodeDetailsOrderData?.promocode._id);
    } else if (promoCodeDetailsOrderData?.promocode.status === false) {
      toast.error("Promo code is not active.");
    } else if (isPromoCodeExpired(promoCodeDetailsOrderData?.promocode.startDate, promoCodeDetailsOrderData?.promocode.endDate)) {
      toast.error("Promo code is expire.");
    }
  }, [promoCodeDetailsOrderData]);

  useEffect(() => {
    toast.error(promoCodeDetailsErrorMessage);
  }, [promoCodeDetailsError, promoCodeDetailsErrorMessage]);

  useEffect(() => {
    if (success && isRegisterSuccess === true) {
      setIsRegisterSuccess(false);
      toast.success('Register successfully.');
    }
  }, [success]);

  useEffect(() => {
    setIsRegisterSuccess(false);
    toast.error(registrationError);
    resetForm();
  }, [error]);


  useEffect(() => {
    //_________________old Code_______________________//
    // let stateArrayResponse = State.getStatesOfCountry("US");
    // let finalStateArray = ConvertResponseForCountry(stateArrayResponse);
    //_________________old Code_______________________//
    let finalStateArray = stateJSON;
    setStateArray(finalStateArray);
  }, []);


  const isPromoCodeExpired = (startDate, endDate) => {
    const currentDate = new Date();
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    return currentDate < startDateObj || currentDate > endDateObj;
  };

  //*get city
  const onClickState = (e) => {
    let stateValue = e.value
    let cityArrayResponse = City.getCitiesOfState("US", stateValue);
    let finalCityArray = ConvertResponseForCountry(cityArrayResponse);
    setCityArray(finalCityArray);
  }

  //*check promocode 
  const onClickCheckPromocode = async () => {
    dispatch(getPromoCodeDetails({ promo_code: values?.promoCode, type: "host" }));
  }

  //*Register User
  const onClickRegisterUser = async (promoCodeID) => {

    setIsShowLoader(true);
    let formattedDateOfBirth = moment(values?.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD');

    //*get Doc List
    let finalDocList = docList.map((item) => item?.file);
    let dataObj = {
      "email": values?.email === "" ? "" : values?.email,
      "password": values?.confirmPassword,
      "first_name": values?.firstName,
      "phone_number": values?.phoneNo === "" || values?.phoneNo === "1" ? "" : "+" + values?.phoneNo,
      "last_name": values?.lastName,
      "is_signed_agreements": "1",
      "identity_doc": finalDocList,
      "role": values?.role?.value,
      "address": values?.address,
      "city": values.city,
      "zip_code": values.zip_code,
      "state": values?.stateValue?.value,
      "country": 'US',
      // "country": values.countryValue.label,
      "dob": formattedDateOfBirth
    }

    let dataAppend = new FormData();

    //append images
    for (let [key, itm] of Object.entries(fileList)) {
      dataAppend.append('identity_doc', itm, itm?.name);
    }

    //append remaining data
    for (let [key, itm] of Object.entries(dataObj)) {
      if (key !== 'identity_doc') {
        dataAppend.append(key, itm);
      }
    }

    var requestOptions = {
      method: 'POST',
      body: dataAppend,
      redirect: 'follow'
    };

    fetch(`${api.API_URL}${url?.REGISTER_USER}`, requestOptions)
      .then(response => response.text())
      .then((result) => {
        //need to redirect to login page
        setIsShowLoader(false);
        var getRegisterData = JSON.parse(result)
        if (getRegisterData?.statusCode === API_CODE.SUCCESS) {
          toast.success('Register successfully.');
          if (values?.phoneNo !== "" && values?.email !== "") { setSendUserEmail(values?.email); }
          else if (values?.phoneNo === "") { setSendUserEmail(values?.email); }
          else { setSendUserEmail(values?.phoneNo); }
          setFieldValue("isVerifyOtp", true);
          let getUserId = getRegisterData?.data?.id;
          setUserId(getUserId);
          if (values?.role?.value === "2") {
            let dataObj = {
              "promoCode_id": promoCodeID,
              "host_id": getUserId
            }
            dispatch(addPromoCodeAndHost(dataObj));
          }
        } else if (getRegisterData?.statusCode === 422) {
          if (getRegisterData?.message.includes("Phone Number Already Taken")) {
            setShowAlertRegister(true)
            setRegisterErrorMessage('Phone number is already taken.');
          } else if (getRegisterData?.message.includes("Email Already Taken")) {
            setShowAlertRegister(true)
            setRegisterErrorMessage('Email is already taken.');
          } else {
            setShowAlertRegister(true)
            setRegisterErrorMessage(getRegisterData?.message??'Registration failed. Please check your input.');
          }
        }
      })
      .catch(error => console.log('error', error));
    // setIsRegisterSuccess(true);
    // dispatch(registerUser(dataAppend));
  };

  //*Select Doc
  async function selectDocuments(e) {
    let file = e.target.files;
    let allowedFileTypes = [".jpg", ".jpeg", ".png", ".webp", "image/jpeg", "image/jpg", "image/png", "image/webp"];
    // console.log(file)
    let docFinalList = [];
    if (docList.length > 0) {
      for (let i = 0; i < file.length; i++) {
        let fileType = file[i].type;
        let binary = await ConvertToBinary(file[i]);
        if (allowedFileTypes.includes(fileType)) {
          let data = {
            "file": binary,
            "filename": file[i].name
          }
          docFinalList.push(data);
          if (i === file.length - 1) {
            let finalData = [...docFinalList]
            setDocList([...docList, finalData])
            setFileList(file);
            setFieldValue("attachment", file.length);
          }
        }
      }
    }
    else {
      for (let i = 0; i < file.length; i++) {
        let fileType = file[i].type;
        let binary = await ConvertToBinary(file[i]);
        if (allowedFileTypes.includes(fileType)) {

          let data = {
            "file": binary,
            "filename": file[i].name
          }
          docFinalList.push(data);
          if (i === file.length - 1) {
            let finalData = [...docFinalList]
            setDocList(finalData)
            setFileList(file);
            setFieldValue("attachment", file.length);
          }

        }
      }
    }
  }

  const handleClick = (e) => {
    ref.current.click()
  }

  //*onClick Remove Doc
  const onClickRemoveDocuments = (index) => {
    let data = [...docList];
    data.splice(index, 1)
    setDocList(data)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePassworConformdVisibility = () => {
    setShowConformPassword(!showConformPassword);
  };

  const handleCalendarIconPickClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      setFieldValue("dateOfBirth", date);
    } else {
      setFieldValue("dateOfBirth", null);
    }
  };

  useEffect(() => {
    const videoModal = document.getElementById('exampleModal');
    const videoIframe = document.getElementById('videoIframe');

    const handleModalHide = () => {
      // Pause the video when the modal is hidden
      if (videoIframe) {
        videoIframe.src = ''; // Set the source to an empty string to stop the video
      }
    };

    if (videoModal) {
      // Add event listener when modal becomes hidden
      videoModal.addEventListener('hidden.bs.modal', handleModalHide);
    }

    return () => {
      // Remove event listener on component unmount
      if (videoModal) {
        videoModal.removeEventListener('hidden.bs.modal', handleModalHide);
      }
    };
  }, []);

  return (
    <div>
      <form>
        <section className="login-wrapper register-wrapper py-wrapper" style={{ "backgroundImage": "url(images/login-bg.webp)" }}>
          {isShowLoader === true &&
            <div id="loader" data-wordload="Please Wait"></div>
          }
          <div className="container z1">
            <div className="bg-transLight register-block">
              {
                values.isVerifyOtp === true ?
                  // _________________Verify otp _________________//
                  <VerifyOtp
                    isVerifyOtp={values.isVerifyOtp}
                    sendUserEmail={sendUserEmail}
                    userId={userId}
                  />
                  // _________________Verify otp _________________//
                  :
                  // _________________Register _________________//
                  <div className="bg-lightTheme1">
                    <h1>Register</h1>
                    <Alert variant="danger" show={showAlertRegister} onClose={() => setShowAlertRegister(false)} dismissible>
                      <Alert.Heading><i className="icon fas fa-exclamation-triangle"></i>{" "}{registerErrorMessage}</Alert.Heading>
                    </Alert>
                    <div className="text-start mb-md-5 mb-4">
                      <div className="row g-md-30 g-md-4 g-3">

                        <div className="col-sm-6">
                          <label htmlFor="are_you">Are you a Renter or Host ? <label style={{ color: '#FF0000' }}>* </label></label>
                          <Select
                            options={roleList}
                            name="role"
                            value={values.role}
                            onChange={(e) => {
                              setFieldValue("role", e);
                              if (e.value === "1") { setVideoText("Rent your next Adventure!"); }
                              else { setVideoText("Become a Hosting Owner!"); }
                            }}
                            onBlur={handleBlur}
                            className={errors.role && touched.role ? 'form-select form-control-error' : 'form-select'}
                            placeholder={"Select renter or host"}
                            classNamePrefix="my-react-select"
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                          />
                          {errors.role && touched.role ? <p className="error-text">{errors.role}</p> : null}
                        </div>

                        <div className="col-sm-6">
                          <div className="register-video">
                            <div className="ratio ratio-1x1 cover register-imgBlock me-md-3  me-2">
                              <img src="images/about-2.webp" alt="video_image" />
                              <span className="play-btn flex-all" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-play flex-all"></i></span>
                            </div>
                            <p style={{ fontSize: '18px' }}>{videoText}</p>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <label htmlFor="first_name">FIRST NAME <label style={{ color: '#FF0000' }}>* </label></label>
                          <input maxLength={30} name="firstName" value={values.firstName}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const sanitizedInputValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
                              setFieldValue("firstName", sanitizedInputValue);
                            }}
                            onBlur={handleBlur}
                            type="text"
                            id="first_name"
                            className={errors.firstName && touched.firstName ? 'form-control form-control-error' : 'form-control'}
                            placeholder="First name"
                          />
                          {errors.firstName && touched.firstName ? <p className="error-text">{errors.firstName}</p> : null}
                        </div>

                        <div className="col-sm-6">
                          <label htmlFor="last_name">LAST NAME <label style={{ color: '#FF0000' }}>* </label></label>
                          <input maxLength={30} name="lastName" value={values.lastName}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const sanitizedInputValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
                              setFieldValue("lastName", sanitizedInputValue);
                            }}
                            onBlur={handleBlur}
                            type="text"
                            id="last_name"
                            className={errors.lastName && touched.lastName ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Last name"
                          />
                          {errors.lastName && touched.lastName ? <p className="error-text">{errors.lastName}</p> : null}
                        </div>

                        <div className="col-sm-6">
                          <label htmlFor="email">EMAIL <label style={{ color: '#FF0000' }}>* </label></label>
                          <input name="email" value={values.email} maxLength={30}
                            onChange={(e) => {
                              setFieldValue("email", e.target.value);
                              if (e.target.value.length === 0) { setFieldValue("emailDuplicate", true); } else { setFieldValue("emailDuplicate", false); }
                            }}
                            onBlur={handleBlur}
                            type="email"
                            id="email"
                            className={errors.email && touched.email ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Email" />
                          {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="phone">Phone <label style={{ color: '#FF0000' }}>* </label></label>
                          <PhoneInput
                            id="phoneNo"
                            enableAreaCodes={false}
                            country={'us'}
                            onlyCountries={['us']}
                            name="phoneNo"
                            value={values.phoneNo}
                            onChange={(phone) => {
                              setFieldValue("phoneNo", phone);
                            }}
                            onBlur={handleBlur}
                            // inputStyle={{
                            //   borderRadius: '40px', width: '100%', padding: '22px 43px',
                            //   borderColor: emailError && phoneNoError ? '#ff0000' : ""
                            // }}
                            disableDropdown={true}
                            //  disableCountryCode={true}
                            countryCodeEditable={false}
                            className={errors.phoneNo && touched.phoneNo ? 'register-phone form-control-error' : 'register-phone'}
                          // className="register-phone"
                          >
                          </PhoneInput>
                          {errors.phoneNo && touched.phoneNo ? <p className="error-text">{errors.phoneNo}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="password">PASSWORD <label style={{ color: '#FF0000' }}>* </label></label>
                          <div className="password-input position-relative d-flex">
                            <input
                              name="password"
                              value={values.password}
                              onChange={(e) => {
                                setFieldValue("password", e.target.value);
                              }}
                              onBlur={handleBlur}
                              type={showPassword ? "text" : "password"}
                              id="password"
                              className={errors.password && touched.password ? 'form-control form-control-error' : 'form-control'}
                              placeholder="Password" />
                            <div className="toogleIcon">
                              <span
                                className="toggle-password"
                                onClick={togglePasswordVisibility}
                              >
                                <i className={showPassword ? 'fas fa-eye fs-5' : 'fas fa-eye-slash fs-5'}></i>
                              </span>
                            </div>
                          </div>
                          {errors.password && touched.password ? <p className="error-text">{errors.password}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="c_password">CONFIRM PASSWORD <label style={{ color: '#FF0000' }}>* </label></label>
                          <div className="password-input position-relative d-flex">
                            <input
                              name="confirmPassword"
                              value={values.confirmPassword}
                              onChange={(e) => {
                                setFieldValue("confirmPassword", e.target.value);
                              }}
                              onBlur={handleBlur}
                              type={showConformPassword ? "text" : "password"}
                              id="c_password"
                              className={errors.confirmPassword && touched.confirmPassword ? 'form-control form-control-error' : 'form-control'}
                              placeholder="Confirm password" />
                            <div className="toogleIcon">
                              <span
                                className="toggle-password"
                                onClick={togglePassworConformdVisibility}
                              >
                                <i className={showConformPassword ? 'fas fa-eye fs-5' : 'fas fa-eye-slash fs-5'}></i>
                              </span>
                            </div>
                          </div>
                          {errors.confirmPassword && touched.confirmPassword ? <p className="error-text">{errors.confirmPassword}</p> : null}
                        </div>

                        <div className="col-sm-6">
                          <label htmlFor="state">State <label style={{ color: '#FF0000' }}>* </label></label>
                          
                          <Select
                            options={stateArray}
                            name="stateValue"
                            value={values.stateValue}
                            onChange={(e) => {
                              setFieldValue("stateValue", e);
                              onClickState(e);
                            }}
                            onBlur={handleBlur}
                            className={errors.stateValue && touched.stateValue ? 'form-select form-control-error' : 'form-select'}
                            placeholder={"Select state"}
                            classNamePrefix="my-react-select"
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                          />
                          {errors.stateValue && touched.stateValue ? <p className="error-text">{errors.stateValue}</p> : null}
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="city">CITY <label style={{ color: '#FF0000' }}>* </label></label>
                          <input maxLength={30} name="city" value={values.city}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const sanitizedInputValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
                              setFieldValue("city", sanitizedInputValue);
                            }}
                            onBlur={handleBlur}
                            type="text"
                            id="city"
                            className={errors.city && touched.city ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Washington"
                          />
                          {errors.city && touched.city ? <p className="error-text">{errors.city}</p> : null}
                        </div>

                        <div className="col-sm-6">
                          <label htmlFor="address">ADDRESS <label style={{ color: '#FF0000' }}>* </label> </label>
                          <input name="address" value={values.address} maxLength={100}
                            onChange={(e) => {
                              setFieldValue("address", e.target.value);
                            }}
                            onBlur={handleBlur}
                            type="text"
                            id="address"
                            className={errors.address && touched.address ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Address" />
                          {errors.address && touched.address ? <p className="error-text">{errors.address}</p> : null}
                        </div>

                        <div className="col-sm-6">
                          <label htmlFor="zip_code">ZIP CODE <label style={{ color: '#FF0000' }}>* </label> </label>
                          <input name="zip_code" value={values.zip_code} maxLength={100}
                            onChange={(e) => {
                              setFieldValue("zip_code", e.target.value);
                            }}
                            onBlur={handleBlur}
                            type="text"
                            id="zip_code"
                            className={errors.zip_code && touched.zip_code ? 'form-control form-control-error' : 'form-control'}
                            placeholder="23213" />
                          {errors.zip_code && touched.zip_code ? <p className="error-text">{errors.zip_code}</p> : null}
                        </div>

                        <div className="col-sm-6 mt-4">
                          <label htmlFor="date_of_birth">Date Of Birth<label style={{ color: '#FF0000' }}>* </label> </label>
                          <div className="input-group">
                            <DatePicker
                              selected={values.dateOfBirth ? moment(values.dateOfBirth, 'MM/DD/YYYY').toDate() : null}
                              onChange={handleDateChange}
                              dateFormat="MM/dd/yyyy"
                              id="dob_date"
                              className={errors.dateOfBirth && touched.dateOfBirth ? 'form-control form-control-error' : 'form-control'}
                              placeholderText="MM/DD/YYYY"
                              maxDate={moment().subtract(25, 'years').toDate()}
                              showYearDropdown
                              scrollableMonthYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={90}
                              ref={datePickerRef}
                              customInput={
                                <InputMask
                                  mask="99/99/9999"
                                  value={values.dateOfBirth}
                                  onChange={e => {
                                    const inputValue = e.target.value;
                                    values.dateOfBirth = inputValue;
                                  }}
                                  placeholder="MM/DD/YYYY"
                                />
                              }
                            />
                            <div className="input-group-append position-absolute register-date-picker">
                              <span
                                // className="input-group-text"
                                onClick={handleCalendarIconPickClick}
                                style={{ cursor: "pointer" }}
                              >
                                <svg
                                  fill="#000000"
                                  width="28px"
                                  height="28px"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z" />
                                  <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z" />
                                </svg>
                              </span>
                            </div>
                          </div>
                          {errors.dateOfBirth && touched.dateOfBirth ? <p className="error-text">{errors.dateOfBirth}</p> : null}
                        </div>
                        {
                          values?.role?.value === ROLE_TYPES.HOST ?
                            <>
                              <div className="col-sm-6">
                                <label htmlFor="promoCode">Promo Code </label>
                                <input name="promoCode" value={values.promoCode} maxLength={100}
                                  onChange={(e) => {
                                    setFieldValue("promoCode", e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  type="text"
                                  id="promoCode"
                                  className={errors.promoCode && touched.promoCode ? 'form-control form-control-error' : 'form-control'}
                                  placeholder="Promo Code" />
                                {errors.promoCode && touched.promoCode ? <p className="error-text">{errors.promoCode}</p> : null}
                              </div>
                            </>
                            : null
                        }

                      </div>

                    </div>
                    <button type='button' onClick={() => {
                      handleSubmit();
                    }} className="btn btn-green"><span>REGISTER</span></button>
                    <p>Already have an account? <Link to={"/login"} className="a-link">Login</Link></p>
                  </div>
                // _________________Register _________________//
              }

            </div>
          </div>
        </section>

        <div className="modal modal-video fade" id="exampleModal" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content ">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <div className="modal-body p-0">
                <div className="ratio ratio-16x9">
                  <iframe
                    id="videoIframe"
                    src={
                      values?.role && values?.role?.label === 'Host'
                        ? 'https://www.youtube.com/embed/mswQ0gB7s-w?si=DYdv6bkQNkJEWQ7b'
                        : 'https://www.youtube.com/embed/yyzsqbjdQr8?si=epn4EkW8O8bH9MAu'
                    }
                    title={values.role && values.role.label === 'Host' ? 'Host Video' : 'Renter Video'}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>

                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
export default Register
