import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

function UpdateDocConfirmationModal({ isOpen, onClose, onUpdate, productStatus }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Enable Confirmation"
      style={{
        content: {  
          width: "500px",   
          height: "250px",
          top:"50%",
          left:"50%",
          transform:"translate(-50%,-50%)",
        },
      }}
    >
      <h2 className='my-4'>{"Are you want to process ?"}</h2>
      <p className='my-4'>{"Your documents need verification again, Until verify you can't log-in."}</p>
      <button className='btn-lg btn-danger mx-2' onClick={onUpdate}>{"Update"}</button>
      <button className='btn-lg btn-secondary' onClick={onClose}>Cancel</button>
    </Modal>
  );
}

export default UpdateDocConfirmationModal;
