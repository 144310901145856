import {
    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESSFUL,
    SEND_MESSAGE_FAILED,
    SEND_MESSAGE_RESET,
} from "./actionTypes";

export const sendMessageDetails = user => {
    return {
        type: SEND_MESSAGE,
        payload: { user },
    }
}

export const sendMessageDetailsSuccessful = user => {
    return {
        type: SEND_MESSAGE_SUCCESSFUL,
        payload: user,
    }
}

export const sendMessageDetailsFailed = user => {
    return {
        type: SEND_MESSAGE_FAILED,
        payload: user,
    }
}

export const sendMessageDetailsReset = user => {
    return {
        type: SEND_MESSAGE_RESET,
        payload: user,
    }
}






