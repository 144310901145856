export const LIST_HOST_PRODUCT = "LIST_HOST_PRODUCT";
export const LIST_HOST_PRODUCT_SUCCESSFUL = "LIST_HOST_PRODUCT_SUCCESSFUL";
export const LIST_HOST_PRODUCT_FAILED = "LIST_HOST_PRODUCT_FAILED";

export const ADD_LIST_HOST_PRODUCT = "ADD_LIST_HOST_PRODUCT";
export const ADD_LIST_HOST_PRODUCT_SUCCESSFUL = "ADD_LIST_HOST_PRODUCT_SUCCESSFUL";
export const ADD_LIST_HOST_PRODUCT_FAILED = "ADD_LIST_HOST_PRODUCT_FAILED";

export const GET_LIST_HOST_PRODUCT = "GET_LIST_HOST_PRODUCT";
export const GET_LIST_HOST_PRODUCT_SUCCESSFUL = "GET_LIST_HOST_PRODUCT_SUCCESSFUL";
export const GET_LIST_HOST_PRODUCT_FAILED = "GET_LIST_HOST_PRODUCT_FAILED";

export const EDIT_LIST_HOST_PRODUCT = "EDIT_LIST_HOST_PRODUCT";
export const EDIT_LIST_HOST_PRODUCT_SUCCESSFUL = "EDIT_LIST_HOST_PRODUCT_SUCCESSFUL";
export const EDIT_LIST_HOST_PRODUCT_FAILED = "EDIT_LIST_HOST_PRODUCT_FAILED";

export const EDIT_IMAGE_HOST_PRODUCT = "EDIT_IMAGE_HOST_PRODUCT";
export const EDIT_IMAGE_HOST_PRODUCT_SUCCESSFUL = "EDIT_IMAGE_HOST_PRODUCT_SUCCESSFUL";
export const EDIT_IMAGE_HOST_PRODUCT_FAILED = "EDIT_IMAGE_HOST_PRODUCT_FAILED";

export const DELETE_LIST_HOST_PRODUCT = "DELETE_LIST_HOST_PRODUCT";
export const DELETE_LIST_HOST_PRODUCT_SUCCESSFUL = "DELETE_LIST_HOST_PRODUCT_SUCCESSFUL";
export const DELETE_LIST_HOST_PRODUCT_FAILED = "DELETE_LIST_HOST_PRODUCT_FAILED";

export const ENABLE_LIST_HOST_PRODUCT = "ENABLE_LIST_HOST_PRODUCT";
export const ENABLE_LIST_HOST_PRODUCT_SUCCESSFUL = "ENABLE_LIST_HOST_PRODUCT_SUCCESSFUL";
export const ENABLE_LIST_HOST_PRODUCT_FAILED = "ENABLE_LIST_HOST_PRODUCT_FAILED";

//LIST_SEARCH_HOST_PRODUCT
export const LIST_SEARCH_HOST_PRODUCT = "LIST_SEARCH_HOST_PRODUCT";
export const LIST_LOCATION_HOST_PRODUCT = "LIST_LOCATION_HOST_PRODUCT";
export const LIST_SORTBY_HOST_PRODUCT = "LIST_SORTBY_HOST_PRODUCT";