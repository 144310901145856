import React from "react";
import PropTypes from "prop-types";
import withRouter from "../Components/Common/withRouter";
import Header from "./Header";
import Footer from "./Footer";


const Layout = (props) => {
    return (
        <React.Fragment>
            <div>
                <Header />
                <div>
                    {props.children}
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);