import React from "react";
const Rent = () => {

  return (
    <section
      className="rentEverything-wrapper text-white"
      style={{ "backgroundImage": "url(images/rentEverything-bg.webp)" }}
    >
      <div className="container z1">
        <div className="row g-md-4 g-3 align-items-center justify-content-between">
          <div className="col">
            <div className="heading mb-0">
              <h2>
                Rent Everything <span>You Need</span>
              </h2>
              <h5>EARN MONEY WITH WHAT YOU ALREADY HAVE</h5>
            </div>
          </div>
          <div className="col">
            <a
              href="https://www.outdoorshare.info" target="_blank" rel="noopener noreferrer" className="btn btn-green">
              <span>Get Help</span>
            </a>
            <p>Visit our information page to review FAQ’s, Direct message customer support, and find more info to better use this site.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rent;
