import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

function DeleteConfirmationModal({ isOpen, onClose, onDelete }) {
  return (
    <div className='deleteButton'>
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Delete Confirmation"
      style={{ 
        content: {
          width: "500px",   
          height: "250px",
          top:"50%",
          left:"50%",
          transform:"translate(-50%,-50%)",
        },
    }}
    >
      <h2 className='my-sm-4 my-3'>Delete Product</h2>
      <p className='my-sm-4 my-3'>Are you sure you want to delete this Product?</p>
      <button className='btns btn-lg btn-danger mx-2' onClick={onDelete}><span>Delete</span></button>
      <button className='btns btn-lg btn-secondary' onClick={onClose}><span>Cancel</span></button>
    </Modal>
    </div>
  );
}

export default DeleteConfirmationModal;
