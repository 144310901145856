import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { RENTER_UPDATE_REVIEW } from "./actionTypes";
import { renterUpdateReviewDetailsSuccessful, renterUpdateReviewDetailsFailed, } from "./action";
import { postUpdateProductReview } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* renterUpdateProductReview({ payload: { user } }) {

    try {
        const response = yield call(postUpdateProductReview, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(renterUpdateReviewDetailsSuccessful(response.data));

        } else {
            yield put(renterUpdateReviewDetailsFailed(response.message));
        }
    } catch (error) {
        yield put(renterUpdateReviewDetailsFailed(error));
    }
}

export function* watchOnRenterUpdateReview() {
    yield takeEvery(RENTER_UPDATE_REVIEW, renterUpdateProductReview);
}

function* RenterUpdateReviewSaga() {

    yield all([
        fork(watchOnRenterUpdateReview),
    ]);
}

export default RenterUpdateReviewSaga;
