import { call, put, takeEvery, } from "redux-saga/effects";
import { CANCEL_ORDER } from "./actionTypes";
import { cancelOrderSuccessful, cancelOrderFailed } from "./action";
import { cancelOrder as cancelOrderApi } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'

function* cancelOrder({ payload: { user } }) {

    try {
        const response = yield call(cancelOrderApi, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(cancelOrderSuccessful(response.data));
        } else {
            yield put(cancelOrderFailed(response.message));
        }
    } catch (error) {
        yield put(cancelOrderFailed(error));
    }
}

function* CancelOrderSaga() {
    yield takeEvery(CANCEL_ORDER, cancelOrder);
}

export default CancelOrderSaga;
