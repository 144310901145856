import { loadStripe } from '@stripe/stripe-js';
import { api } from "../config";

import * as url from "./url_helper";
import { getUserProfileDetails } from "../helpers/fakebackend_helper"
import { IDENTITY_DOCUMENT_STATUS } from "../helpers/enum"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_IDENTITY_API_KEY);

// Function to check user verification status
export async function stripeVerification() {

    try {
         
        const userRes = await getUserProfileDetails()

        const userDocStatus = userRes.data.login_user.document_verification_status

        if(userDocStatus === IDENTITY_DOCUMENT_STATUS.VERIFIED) {
            return {
                verfied: true,
                type:'success',
                msg: "Your document has been verified. You can now use your account."
            }
        } else if(userDocStatus === IDENTITY_DOCUMENT_STATUS.PENDING || userDocStatus === IDENTITY_DOCUMENT_STATUS.UNVERIFIED || userDocStatus === '' || userDocStatus === null) {

            let token = localStorage.getItem("accessToken");
            let headers = {};
            if (token) {
              headers["Authorization"] = `Bearer ${token}`;
            }
          
            const stripe = await stripePromise;
            const verificationResponse = await fetch(`${api.API_URL}${url?.CREATE_IDENTITY_SESSION}`, {
            method: 'POST',
            headers,
            });
        
            const session = await verificationResponse.json();
            const { error } = await stripe.verifyIdentity(session.client_secret);
            console.log("🚀 ~ stripeVerification ~ error:", error)
            
            if(error && error.code === "session_cancelled") {
                await fetch(`${api.API_URL}${url?.STRIPE_SESSION_CANCELED}`, {
                    method: 'POST',
                    headers,
                });
            }

            return error ? {
                verfied: false,
                type: 'error',
                msg: "Failed to verify your identity. Please try again later."
            } : {
                verfied: false,
                type: 'success',
                msg: "Thanks for submitting your identity document. We are processing your verification. Please try again in a moment"
            }
        
        } else if (userDocStatus === IDENTITY_DOCUMENT_STATUS.INPROGRESS) {
            return {
                verfied: false,
                type: 'warning',
                msg: "We are processing your verification. Please try again in a moment"
            }
        } else {
            return {
                verfied: false,
                type: 'error',
                msg: "We are not able to verify your identity at this time, please contact The OutdoorShare Team for assistance.\ncontact.us@myoutdoorshare.com \n(801) 449 0536"
            }
        }

    } catch (error) {
        console.log("🚀 ~ checkUserVerification ~ error:", error)
        
    }
}
