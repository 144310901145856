import {
    GET_RENTER_ORDER_DETAILS,
    GET_RENTER_ORDER_DETAILS_SUCCESSFUL,
    GET_RENTER_ORDER_DETAILS_FAILED,
} from "./actionTypes";

const initialState = {
    errorMessage: null,
    orderDetailLoading: false,
    success: false,
    error: false,
    orderDetailData: null,
    orderDetailNavigation: false,
};


const RenterOrderDetailReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_RENTER_ORDER_DETAILS:
            state = {
                ...state,
                orderDetailLoading: true,
                success: false,
                error: false,
                errorMessage: null,
                orderDetailData: null,
                orderDetailNavigation: false,
            };
            break;
        case GET_RENTER_ORDER_DETAILS_SUCCESSFUL:
            state = {
                ...state,
                orderDetailLoading: false,
                success: true,
                errorMessage: null,
                error: false,
                orderDetailData: action.payload,
                orderDetailNavigation: true,
            };
            break;
        case GET_RENTER_ORDER_DETAILS_FAILED:
            state = {
                ...state,
                orderDetailLoading: false,
                errorMessage: action.payload,
                error: true,
                success: false,
                orderDetailData: null,
                orderDetailNavigation: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default RenterOrderDetailReducer;
