import {
    ADD_PROMO_CODE_HOST_RELATION,
    ADD_PROMO_CODE_HOST_RELATION_SUCCESSFUL,
    ADD_PROMO_CODE_HOST_RELATION_FAILED,
    ADD_PROMO_CODE_HOST_RELATION_RESET
} from "./actionTypes";

const initialState = {
    addPromoCodeAndHostErrorMessage: null,
    addPromoCodeAndHostLoading: false,
    addPromoCodeAndHostSuccess: false,
    addPromoCodeAndHostError: false,
    addPromoCodeAndHostData: null,
};


const AddPromoCodeReducer = (state = initialState, action) => {

    switch (action.type) {

        case ADD_PROMO_CODE_HOST_RELATION:
            state = {
                ...state,
                addPromoCodeAndHostLoading: true,
                addPromoCodeAndHostSuccess: false,
                addPromoCodeAndHostError: false,
                addPromoCodeAndHostErrorMessage: null,
                addPromoCodeAndHostData: null,
            };
            break;
        case ADD_PROMO_CODE_HOST_RELATION_SUCCESSFUL:
            state = {
                ...state,
                addPromoCodeAndHostLoading: false,
                addPromoCodeAndHostSuccess: true,
                addPromoCodeAndHostErrorMessage: null,
                addPromoCodeAndHostError: false,
                addPromoCodeAndHostData: action.payload,
            };
            break;
        case ADD_PROMO_CODE_HOST_RELATION_FAILED:
            state = {
                ...state,
                addPromoCodeAndHostLoading: false,
                addPromoCodeAndHostErrorMessage: action.payload,
                addPromoCodeAndHostError: true,
                addPromoCodeAndHostSuccess: false,
                addPromoCodeAndHostData: null,
            };
            break;

        case ADD_PROMO_CODE_HOST_RELATION_RESET:
            state = {
                ...state,
                addPromoCodeAndHostErrorMessage: null,
                addPromoCodeAndHostLoading: false,
                addPromoCodeAndHostSuccess: false,
                addPromoCodeAndHostError: false,
                addPromoCodeAndHostData: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default AddPromoCodeReducer;
