import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Rent from "../Components/Products/Rent";

const Footer = () => {

    const [siteSetting, setSiteSeetingData] = useState([]);
    const location = useLocation();
    const user = useSelector(state => state?.SiteSettings?.user);

    useEffect(() => {
        setSiteSeetingData(user);
    }, [user]);

    return (
        <React.Fragment>
            <Rent />
            <footer className="text-white">
                <div className="container">
                    <div className="footer-top">
                        <div className="row g-md-4 g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <img src="images/logo-white.svg" alt="Logo" />
                            </div>
                            <div className="col-auto">
                                <ul>
                                    <li className={location?.pathname === "/home" ? "active" : ""}><Link to={"/home"}>Home</Link></li>
                                    <li className={location?.pathname === "/productlist" ? "active" : ""}><Link to={"/productlist"}>Products</Link></li>
                                    <li className={location?.pathname === "/contact" ? "active" : ""}><Link to={"/contact"}>Contact Us</Link></li>
                                    <li className={location?.pathname === "/about" ? "active" : ""}><Link to={"/about"}>About Us</Link></li>
                                    <li className={location?.pathname === "/privacypolicy" ? "active" : ""}><Link to={"/privacypolicy"}>Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-middle">
                        <div className="row g-md-4 g-3 align-items-center justify-content-md-around">
                            <div className="col-auto">
                                <div className="contact-block">
                                    <div className="ratio ratio-1x1 rounded-circle bg-green">
                                        <div className="flex-all">
                                            <a href={siteSetting?.settings?.site_faccebook_link} target="_blank" rel="noreferrer" data-toggle="tooltip" style={{ color: "white" }}>
                                                <i className="fa-brands fa-facebook-f"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ratio ratio-1x1 rounded-circle bg-green">
                                        <div className="flex-all">
                                            <a href={siteSetting?.settings?.site_threads_link} target="_blank" rel="noreferrer" data-toggle="tooltip" style={{ color: "white" }}>
                                                <i className="fa-brands fa-threads"></i>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div className="ratio ratio-1x1 rounded-circle bg-green">
                                        <div className="flex-all">
                                            <a href={siteSetting?.settings?.site_linkedin_link} target="_blank" rel="noreferrer" data-toggle="tooltip" style={{ color: "white" }}>
                                                <i className="fa-brands fa-linkedin-in"></i>
                                            </a>
                                        </div>
                                    </div> */}
                                    <div className="ratio ratio-1x1 rounded-circle bg-green">
                                        <div className="flex-all">
                                            <a href={siteSetting?.settings?.site_instagram_link} target="_blank" rel="noreferrer" data-toggle="tooltip" style={{ color: "white" }}>
                                                <i className="fa-brands fa-instagram"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-auto">
                                <div className="contact-block">
                                    <div className="ratio ratio-1x1 rounded-circle bg-green">
                                        <div className="flex-all">
                                            <a href={`mailto:${siteSetting?.settings?.site_email}`} style={{ color: "white" }}><i className="fa-regular fa-envelope"></i></a>
                                        </div>
                                    </div>
                                    <p><a href={`mailto:${siteSetting?.settings?.site_email}`}>{siteSetting?.settings?.site_email}</a></p>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <div className="contact-block">
                                    <div className="ratio ratio-1x1 rounded-circle bg-green">
                                        <div className="flex-all">
                                            <a href={`tel:${siteSetting?.settings?.site_phone}`} style={{ color: "white" }}><i className="fa-regular fa-phone"></i></a>
                                        </div>
                                    </div>
                                    <p><a href={`tel:${siteSetting?.settings?.site_phone}`}>{siteSetting?.settings?.site_phone}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyRight text-center">
                        <p>© 2023 OutdoorShare, All Rights Reserved</p>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Footer;