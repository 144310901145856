import {
  PRODUCT_DETAILS,
  PRODUCT_DETAILS_SUCCESSFUL,
  PRODUCT_DETAILS_FAILED,
  PRODUCT_DETAILS_RESET
} from "./actionTypes";

const initialState = {
  productDetailError: null,
  message: null,
  loading: false,
  user: null,
  success: false,
  error: false,
};

const ProductDetail = (state = initialState, action) => {

  switch (action.type) {
    case PRODUCT_DETAILS:
      state = {
        ...state,
        loading: true,
        productDetailError: null,
      };
      break;

    case PRODUCT_DETAILS_SUCCESSFUL:

      state = {
        ...state,
        loading: false,
        user: action.payload,
        success: true,
        productDetailError: true,
      };
      break;

    case PRODUCT_DETAILS_FAILED:
      state = {
        ...state,
        loading: false,
        user: null,
        productDetailError: action.payload,
        error: true,
      };
      break;

    case PRODUCT_DETAILS_RESET:
      state = {
        ...state,
        productDetailError: null,
        message: null,
        loading: false,
        user: null,
        success: false,
        error: false,
      };
      break;

    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default ProductDetail;
