import {
    PRODUCT_ADD_TO_CARD,
    PRODUCT_ADD_TO_CARD_DELETE,
    PRODUCT_ADD_TO_CARD_RESET
} from "./actionTypes";


export const productAddCard = user => {
    return {
        type: PRODUCT_ADD_TO_CARD,
        payload: user,
    }
}

export const productAddCardDelete = user => {
    return {
        type: PRODUCT_ADD_TO_CARD_DELETE,
        payload: user,
    }
}

export const productAddCardReset = user => {
    return {
        type: PRODUCT_ADD_TO_CARD_RESET,
        payload: user,
    }
}








