import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { CALCULATE_ORDER_PAYMENT } from "./actionTypes";
import { CalculateOrderPaymentSuccessful, CalculateOrderPaymentFailed, } from "./action";
import { postCalculateOrderPayment } from "../../helpers/fakebackend_helper";

function* calculateOrderPayment({ payload }) {
    try {
        const response = yield call(postCalculateOrderPayment, payload);
        yield put(CalculateOrderPaymentSuccessful(response.data));
    } catch (error) {
        yield put(CalculateOrderPaymentFailed(error));
    }
}

export function* watchOnCalculateOrderPayment() {
    yield takeEvery(CALCULATE_ORDER_PAYMENT, calculateOrderPayment);
}

function* CalculateOrderPaymentSaga() {

    yield all([
        fork(watchOnCalculateOrderPayment),
    ]);
}

export default CalculateOrderPaymentSaga;
