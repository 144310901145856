export const GET_PROFILE_DETAILS = "GET_PROFILE_DETAILS"
export const GET_PROFILE_DETAILS_SUCCESSFUL = "GET_PROFILE_DETAILS_SUCCESSFUL"
export const GET_PROFILE_DETAILS_FAILED = "GET_PROFILE_DETAILS_FAILED"

export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_SUCCESSFUL = "UPDATE_PROFILE_SUCCESSFUL"
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED"
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET"

export const UPDATE_KYC = "UPDATE_KYC"
export const UPDATE_KYC_SUCCESSFUL = "UPDATE_KYC_SUCCESSFUL"
export const UPDATE_KYC_FAILED = "UPDATE_KYC_FAILED"