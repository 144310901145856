import {
    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESSFUL,
    SEND_MESSAGE_FAILED,
    SEND_MESSAGE_RESET,
} from "./actionTypes";

const initialState = {
    sendMsgErrorMessage: null,
    sendMsgLoading: false,
    sendMsgData: null,
    sendMsgSuccess: false,
    sendMsgError: false,
};


const SendMessageReducer = (state = initialState, action) => {

    switch (action.type) {
        case SEND_MESSAGE:
            state = {
                ...state,
                sendMsgLoading: true,
                sendMsgErrorMessage: null,
            };
            break;
        case SEND_MESSAGE_SUCCESSFUL:
            state = {
                ...state,
                sendMsgLoading: false,
                sendMsgData: action.payload,
                sendMsgSuccess: true,
                sendMsgErrorMessage: null,
                sendMsgError: false,
            };
            break;
        case SEND_MESSAGE_FAILED:
            state = {
                ...state,
                sendMsgData: null,
                sendMsgLoading: false,
                sendMsgErrorMessage: action.payload,
                sendMsgError: true,
                sendMsgSuccess: false,
            };
            break;
        case SEND_MESSAGE_RESET:
            state = {
                ...state,
                sendMsgErrorMessage: null,
                sendMsgLoading: false,
                sendMsgData: null,
                sendMsgSuccess: false,
                sendMsgError: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default SendMessageReducer;
