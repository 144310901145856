import {
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESSFUL,
    RESET_PASSWORD_FAILED
} from "./actionTypes";


export const resetPassword = user => {
    return {
        type: RESET_PASSWORD,
        payload: { user },
    }
}

export const resetPasswordSuccessful = user => {
    return {
        type: RESET_PASSWORD_SUCCESSFUL,
        payload: user,
    }
}

export const resetPasswordFailed = user => {
    return {
        type: RESET_PASSWORD_FAILED,
        payload: user,
    }
}