import { call, put, takeEvery } from "redux-saga/effects";
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./action";
import { postRegisterUser } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'

function* registerUser({ payload: { user } }) {

    try {
        const response = yield call(postRegisterUser, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(registerUserSuccessful(response.message));
        } else {
            yield put(registerUserFailed(response.message));
        }
    } catch (error) {
        yield put(registerUserFailed(error));
    }
}

function* RegisterSaga() {
    yield takeEvery(REGISTER_USER, registerUser);
}

export default RegisterSaga;
