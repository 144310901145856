import {
    SIGN_RENTER_AGREEMENT,
    SIGN_RENTER_AGREEMENT_SUCCESSFUL,
    SIGN_RENTER_AGREEMENT_FAILED,
    SIGN_RENTER_AGREEMENT_RESET
} from "./actionTypes";

const initialState = {
    renterAgreementErrorMessage: null,
    renterAgreementLoading: false,
    renterAgreementSuccess: false,
    renterAgreementError: false,
    renterAgreementData: null,
};

const RenterAgreementReducer = (state = initialState, action) => {

    switch (action.type) {
        case SIGN_RENTER_AGREEMENT:
            state = {
                ...state,
                renterAgreementLoading: true,
                renterAgreementSuccess: false,
                renterAgreementError: false,
                renterAgreementErrorMessage: null,
                renterAgreementData: null,
            };
            break;
        case SIGN_RENTER_AGREEMENT_SUCCESSFUL:
            state = {
                ...state,
                renterAgreementLoading: false,
                renterAgreementSuccess: true,
                renterAgreementErrorMessage: null,
                renterAgreementError: false,
                renterAgreementData: action.payload,
            };
            break;
        case SIGN_RENTER_AGREEMENT_FAILED:
            state = {
                ...state,
                renterAgreementLoading: false,
                renterAgreementErrorMessage: action.payload,
                renterAgreementError: true,
                renterAgreementSuccess: false,
                renterAgreementData: null,
            };
            break;

        case SIGN_RENTER_AGREEMENT_RESET:
            state = {
                ...state,
                renterAgreementLoading: false,
                renterAgreementErrorMessage: null,
                renterAgreementError: false,
                renterAgreementSuccess: false,
                renterAgreementData: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default RenterAgreementReducer;
