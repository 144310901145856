import {
    RENTER_UPDATE_REVIEW,
    RENTER_UPDATE_REVIEW_SUCCESSFUL,
    RENTER_UPDATE_REVIEW_FAILED,
    RENTER_UPDATE_REVIEW_RESET,
} from "./actionTypes";

export const renterUpdateReviewDetails = user => {
    
    return {
        type: RENTER_UPDATE_REVIEW,
        payload: { user },
    }
}

export const renterUpdateReviewDetailsSuccessful = user => {
    return {
        type: RENTER_UPDATE_REVIEW_SUCCESSFUL,
        payload: user,
    }
}

export const renterUpdateReviewDetailsFailed = user => {
    return {
        type: RENTER_UPDATE_REVIEW_FAILED,
        payload: user,
    }
}

export const renterUpdateReviewDetailsReset = user => {
    return {
        type: RENTER_UPDATE_REVIEW_RESET,
        payload: user,
    }
}






