import {
  HOST_LIST_PRODUCT,
  HOST_LIST_PRODUCT_FAILED,
  HOST_LIST_PRODUCT_SUCCESSFUL,

  HOST_LIST_PRODUCT_SORT,
  HOST_LIST_PRODUCT_SORT_SUCCESSFUL,
  HOST_LIST_PRODUCT_SORT_FAILED,

} from "./actionTypes";

const initialState = {
  hostListProductError: null,
  loading: false,
  user: null,
  success: false,
  error: false,
  isSort: false,
};

const HostListProduct = (state = initialState, action) => {

  switch (action.type) {
    case HOST_LIST_PRODUCT:
      state = {
        ...state,
        hostListProductError: null,
        loading: true,
        user: null,
        success: false,
        error: false,
        isSort: false,
      };
      break;

    case HOST_LIST_PRODUCT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        success: true,
        error: false,
        hostListProductError: null,
        isSort: false,
      };
      break;

    case HOST_LIST_PRODUCT_FAILED:
      state = {
        ...state,
        loading: false,
        user: null,
        hostListProductError: action.payload,
        error: true,
        success: false,
        isSort: false,
      };
      break;

    case HOST_LIST_PRODUCT_SORT:
      state = {
        ...state,
        hostListProductError: null,
        loading: false,
        user: null,
        success: false,
        error: false,
        isSort: false,
      };
      break;

    case HOST_LIST_PRODUCT_SORT_SUCCESSFUL:
      state = {
        hostListProductError: null,
        loading: false,
        user: action.payload.user,
        success: true,
        error: false,
        isSort: true,
      };
      break;

    case HOST_LIST_PRODUCT_SORT_FAILED:
      state = {
        ...state,
        hostListProductError: null,
        loading: false,
        user: null,
        success: false,
        error: true,
        isSort: true,
      };
      break;


    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default HostListProduct;
