import {
    GET_PROMO_CODE_DETAILS,
    GET_PROMO_CODE_DETAILS_SUCCESSFUL,
    GET_PROMO_CODE_DETAILS_FAILED,
    GET_PROMO_CODE_DETAILS_RESET
} from "./actionTypes";

const initialState = {
    promoCodeDetailsErrorMessage: null,
    promoCodeDetailsLoading: false,
    promoCodeDetailsSuccess: false,
    promoCodeDetailsError: false,
    promoCodeDetailsOrderData: null,
};


const PromoCodeReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_PROMO_CODE_DETAILS:
            state = {
                ...state,
                promoCodeDetailsLoading: true,
                promoCodeDetailsSuccess: false,
                promoCodeDetailsError: false,
                promoCodeDetailsErrorMessage: null,
                promoCodeDetailsOrderData: null,
            };
            break;
        case GET_PROMO_CODE_DETAILS_SUCCESSFUL:
            state = {
                ...state,
                promoCodeDetailsLoading: false,
                promoCodeDetailsSuccess: true,
                promoCodeDetailsErrorMessage: null,
                promoCodeDetailsError: false,
                promoCodeDetailsOrderData: action.payload,
            };
            break;
        case GET_PROMO_CODE_DETAILS_FAILED:
            state = {
                ...state,
                promoCodeDetailsLoading: false,
                promoCodeDetailsErrorMessage: action.payload,
                promoCodeDetailsError: true,
                promoCodeDetailsSuccess: false,
                promoCodeDetailsOrderData: null,
            };
            break;

        case GET_PROMO_CODE_DETAILS_RESET:
            state = {
                ...state,
                promoCodeDetailsErrorMessage: null,
                promoCodeDetailsLoading: false,
                promoCodeDetailsSuccess: false,
                promoCodeDetailsError: false,
                promoCodeDetailsOrderData: null,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default PromoCodeReducer;
