import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../../Layouts/Header";
import Footer from "../../../Layouts/Footer";

//*images
import samHoskinsImage from '../About/images/samHoskins.png'
import nickJexImage from '../About/images/nickJex.png'
import thomasPatstonImage from '../About/images/thomasPatson.png'
import tylerThompsonImage from '../About/images/tylerThompson.png'

const Aboutepage = () => {

  const [siteSetting, setSiteSeetingData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => { setIsModalOpen(true); };
  const closeModal = () => { setIsModalOpen(false); };
  const user = useSelector(state => state?.SiteSettings?.user);

  useEffect(() => {
    setSiteSeetingData(user);
  }, [user]);

  useEffect(() => {
    const videoIframe = document.getElementById('videoIframe');
    const videoModal = document.getElementById('exampleModal');
    const handleModalHide = () => {
      // Pause the video when the modal is hidden
      if (videoIframe && videoIframe.contentWindow) {
        videoIframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };
    if (isModalOpen) {
      // Add event listener when modal becomes visible
      if (videoModal) {
        // Add event listener when modal becomes hidden
        videoModal.addEventListener('hidden.bs.modal', handleModalHide);
      }
    }

    return () => {
      // Remove event listener on component unmount
      if (videoModal) {
        // Add event listener when modal becomes hidden
        videoModal.addEventListener('hidden.bs.modal', handleModalHide);
      }
    };
  }, [isModalOpen]);

  return (
    <div>
      <Header />
      <section className="innerBanner-wrapper">
        <div className="innerBanner-img img-obj cover">
          <img src="images/header.png" alt="header_image" />
        </div>
        <div className="container h-100 z1 flex-all">
          <h1>About Us</h1>
        </div>
      </section>
      {/* About part 1 */}
      <section className="about-wrapper py-wrapper">
        <div className="container">
          <div className="row g-xl-5 g-4">
            <div className="col-lg-5 pe-xl-0">
              <div className="heading">
                <h3>Welcome to OutdoorShare LLC,</h3>
              </div>
              <div className="content mb-md-5 mb-4">
                <p>
                  At OutdoorShare LLC where we are passionate about making the outdoors more accessible and affordable for everyone!
                  Our mission is to break down barriers and empower individuals to connect with nature in a safe and cost-efficient way.
                  At OutdoorShare LLC, we believe that nature is a powerful healer and a limitless source of inspiration and inner peace.
                  That is why we are dedicated to providing innovative solutions and resources that help enable people to fully embrace the beauty of the great outdoors.{" "}
                </p>
                <p>
                  Our goal is for people to think of OutdoorShare LLC when they think of enjoying the outdoors.
                  We envision a world where adventure knows no bounds, where individuals of all ages and abilities can find new boundaries to explore and conquer.
                  Whether you are a seasoned outdoor enthusiast or just starting your journey, OutdoorShare LLC is your gateway to unforgettable outdoor experiences.
                  So, join us as we embark on this incredible adventure together, pushing the limits, and discovering the true extent of our potential. Together,
                  let us redefine what it means to live life to the fullest and find new boundaries in the great outdoors!
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-img ps-xl-4 ms-xl-2 ">
                <img src="images/about-1.webp" alt="about-1_image" className="z1" />
                <div className="about-bg img-obj cover">
                  <img src="images/about-2.webp" alt="about-2_image" />
                  <Link
                    href="#"
                    className="play-btn flex-all"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={openModal}
                  >
                    <i className="fa-solid fa-play flex-all"></i>
                    <span>CLICK TO PLAY</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About part 2 */}
      <section className="about-wrapper bestAgency-wrapper bg-lightTheme  py-wrapper">
        <div className="container">
          <div className="heading">
            <h2>Find New Boundaries with OutdoorShare LLC 2023</h2>
          </div>
          <div className="content mb-md-5 mb-4">
            <p>
              When you search to expand your boundaries and you embrace the excitement of stepping outside of your comfort zone by trying new things,
              OutdoorShare LLC is there to help you make it reality. Through exploring unfamiliar territory and pushing your limits, you discover fresh
              perspectives and uncover hidden aspects of yourself and the world around you. We all hope to learn that each stumble and setback becomes a
              valuable lesson, contributing to our overall understanding and personal growth. Why not make this ongoing journey of self-discovery and improvement
              guide you towards a richer, more fulfilling life using OutdoorShare LLC as your partner when planning those life adventures.
            </p>
          </div>
          <div className="row g-xl-5 g-4 align-items-center">
            <div className="col-auto">
              <div className="callUs-block">
                <div className="ratio ratio-1x1 rounded-circle bg-green ">
                  <div className="flex-all">
                    <img src="images/phone.svg" alt="phone_icon" />
                  </div>
                </div>
                <div className="">
                  <h5>
                    <a href="tel:8002360011">
                      {siteSetting?.settings?.site_phone}
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <Link to={"/contact"} className="btn btn-black">
                <span>CONTACT US</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Add modal */}
      <div className={`modal modal-video fade ${isModalOpen ? 'show' : ''}`}
        id="exampleModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
            <div className="modal-body p-0">
              <div className="ratio ratio-16x9">
                {isModalOpen && (
                  <iframe
                    id="videoIframe"
                    // src="https://www.youtube.com/embed/YOdNvSHMhrg"
                    src="https://www.youtube.com/embed/YOdNvSHMhrg?si=9sRkPZ9mRuDhHExi"
                    title="YouTube video player"
                    allowFullScreen
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="loginModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered  modal-login">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body text-center">
              <img src="images/login.svg" alt="login_Icon" />
              <h4>You need to Login before adding this product to cart</h4>
              <Link className="btn btn-green me-0">
                <span>LOGIN</span>
              </Link>
              <p>
                Don't have an account?{" "}
                <Link className="a-link">
                  Register
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* About part 3 */}
      <section className="about-wrapper missionVission-wrapper   pyCustom-wrapper">
        <div className="container">
          <div className="row g-xl-5 g-4">
            <div className="col-lg-12  ">
              <h3 className="text-center mb-4">Our Mission</h3>
              <div className="content">
                <p>
                  We are seeking to bring joy to others by making the outdoors accessible and affordable for more people.
                  We are always striving to help others find new boundaries by making outdoor adventure resources available
                  to more people enabling them to discover new adventure and solitude while being close to nature and enjoying
                  the beauty of the outdoors. When someone thinks of nature and enjoying the outdoors, we want them to think of
                  OutdoorShare LLC when planning their outdoor adventure.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <h3>Our Vission</h3>
              <div className="content">
                <p>
                  Making this the first true generator on the Internet. It uses
                  a dictionary of over 200 Latin words, combined with a handful
                  of model sentence structures, to generate Lorem Ipsum which
                  looks reasonable. The generated Lorem Ipsum is therefore
                  always free from repetition, injected humour, or
                  non-characteristic words etc
                </p>
              </div>
            </div> */}
          </div>
        </div>
        <div className="container mt-5">
          <h3 className="text-center mb-4">Our Team</h3>
          <div className="row g-xl-5 g-4 mt-0.5">
            <div className="col-lg-6 d-flex align-items-center justify-content-center" >
              <div className="content d-flex align-items-center">
                <img src={samHoskinsImage} alt="Team Image" style={{ width: '700px', height: '700px', objectFit: 'contain' }} />
              </div>
            </div>
            <div className="col-lg-6">
              <h3>{"Sam Hoskins"}</h3>
              <h5>{"Co-Founder and Chief Executive Officer"}</h5>
              <div className="content">
                <p className="ml-3">
                  Mr. Hoskins has been a successful entrepreneur since a noticeably youthful age. He has started multiple successful business entities during his short,
                  but remarkable career, and has contributed much of his success to becoming a consummate and knowledgeable salesperson, as well as  through his great gift of empathy,
                  and keeping the commitments that he makes.
                </p>
                <p className="ml-3">
                  Through his experience as an entrepreneur, Mr. Hoskins has created a focused passion for leadership.
                  During his business career, Sam has led multiple teams of individuals to admirable levels of success
                  in their chosen endeavors. Not only has Mr. Hoskins been phenomenally successful in motivating his teams
                  to strive for constant self-improvement, but has repeatedly helped guide his teams to significantly higher
                  levels of success, through his dynamic and motivational personality, and by always leading from the front.
                  Additionally, Sam has an extraordinarily strong passion for self-improvement, and clearly strives to understand
                  the psychology and motivations of his co-workers and prospective business clients.
                </p>

                <p className="ml-3">
                  Additionally, Sam has been married since 2018 to his lovely wife, and has found additional success and great personal
                  satisfaction (so far) while raising their 4 beautiful children, all having been born within 4 years from the oldest to the youngest.
                </p>

                <p className="ml-3">
                  Having been a passionate outdoor adventure enthusiast since childhood, Sam brings that same passion and enthusiasm to OutdoorShare as
                  both our Co-Founder and our Chief Executive Officer. Sam spends his valuable free time enjoying multiple sports activities with his
                  family and friends, along with devoting considerable time to his lifelong faith.
                  Having been a “hands on” type of entrepreneur throughout his entire life, Sam is the ideal leader to move OutdoorShare forward on their
                  mission to make the “great outdoors” available to all who wish to experience it on a level that is appropriate for their needs, goals,
                  and quest for adventure.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row g-xl-5 g-4">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div className="content d-flex align-items-center">
                <img src={nickJexImage} alt="Team Image" style={{ width: '664px', height: '664px', objectFit: 'contain' }} />
              </div>
            </div>
            <div className="col-lg-6">
              <h3>{"Nick Jex"}</h3>
              <h5>{"Co-Founder and Chief Operating Officer"}</h5>
              <div className="content">
                <p className="ml-3">
                  Mr. Jex started his entrepreneurial journey at an early age man by buying and selling four-wheelers.
                  Presently, Nick has found true passion related to his automotive and outdoor adventure interests and has
                  become phenomenally successful and is considered to be a top talent in the sales and management of most things
                  automotive, especially if the vehicles are designed for outdoor adventure and recreation.
                </p>

                <p className="ml-3">
                  Nick’s relentless and affable spirit has led him to remarkable success in running a successful automotive and sales
                  related business, as well as becoming a very influential leader and mentor to those seeking success in his field.
                </p>

                <p className="ml-3">
                  As a prolific and emerging leader in his field, Nick loves discovering and fulfilling his client’s needs,
                  finding win-win solutions, and sharing what he is most passionate about.
                </p>

                <p className="ml-3">
                  Nick brings a great passion for outdoor adventure and the extensive product knowledge in all aspects of outdoor recreational
                  vehicles to the firm.After growing up in Highland, Utah, Nick is a valuable resource to OutdoorShare regarding most aspects of
                  outdoor adventure and activities, including boating, fishing, off road adventuring, camping, or most any other outdoor adventure activity.
                </p>

                <p className="ml-3">
                  During whatever valuable free time that Nick is able to find, he spends as much of that time as possible to devote to his wife and daughter.
                  Additionally, Nick is fully devoted to his faith and believes in living life with strong personal and family values such as honesty, faith,
                  and integrity, all of which are the foundation of his business dealings.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row g-xl-5 g-4">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div className="content d-flex align-items-center ">
                <img src={thomasPatstonImage} alt="Team Image" style={{ width: '664px', height: '664px', objectFit: 'contain' }} />
              </div>
            </div>
            <div className="col-lg-6">
              <h3>{"Thomas Patston JD, LL.M."}</h3>
              <h5>{"Chief Legal & Compliance Officer - General Counsel"}</h5>
              <div className="content">
                <p className="ml-3">
                  Mr. Patston has over 35 years of professional experience in the Legal Services, Estate Planning and Financial
                  Services fields. He holds a Juris Doctor ( Law Degree), a Master’s Degree in Business and Taxation Law, as well
                  as a Bachelor’s Degree in both Legal Studies and Financial Planning.
                </p>
                <p className="ml-3">
                  Thomas spent 15 years as a Senior Vice President of the Estate Planning Division for one of the nation’s largest financial
                  services firms and has served as a Managing Partner in several private practices. Mr. Patston was also a cable television
                  talk show Anchor and a Co-Producer for Business USA Network, producing and moderating a nationally televised weekly business
                  and financial report. Additionally, Mr. Patston has appeared many times on both television and radio shows discussing Estate
                  Planning, Business Formation, Asset Preservation Planning, and general Financial Planning matters.
                </p>

                <p className="ml-3">
                  He has been a life-long participation in professional motorsports, both as a Driver, and Team Owner, as well as having served as
                  a Chief Racing Instructor for Sports Car Club of America. Additionally, Thomas has been regularly active in mountaineering, and
                  rock climbing, and has served with several Mountain Rescue Groups in Colorado and California.
                </p>

                <p className="ml-3">
                  Mr. Patston has also been a regular participant in whitewater sports, skiing, and professional ski racing, including serving on the
                  Far West Division Board of Directors for The United States Ski & Snowboard Association (US Ski Team). He has also been a USSA Certified
                  Ski Racing Coach and a PSIA Certified Ski Instructor. Thomas is married with one son, and currently lives with his wife Carole, and their
                  beloved “Pack of German Shepherds”in the California Sierras.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row g-xl-5 g-4">
            <div className="col-lg-6 d-flex align-items-center justify-content-center" >
              <div className="content d-flex align-items-center" >
                <img src={tylerThompsonImage} alt="Team Image" style={{ width: '664px', height: '664px', objectFit: 'contain' }} />
              </div>
            </div>
            <div className="col-lg-6">
              <h3>{"Tyler Thompson"}</h3>
              <h5>{"Chief Marketing Officer"}</h5>
              <div className="content">
                <p className="ml-3">
                  Tyler has entrepreneur and has started a multiple of successful business entities both individually and with others. Presently,
                  Tyler is Chief Executive Officer of one of Northern California’s leading Arborist firms. His company engages in many aspects of
                  “tree servicing” and the removal of dead, damaged, downed, or dangerous trees in the California Sierras. Typically, Tyler and his
                  team are the first group called in a multi-county area after a major storm has left extensive tree damage needing to be cleared.
                </p>
                <p className="ml-3">
                  Additionally, his firm engages in multiple other aspects of outdoor landscaping, as well as wildfire mitigation and prevention services
                  in an area where such services are absolutely critical. Tyler’s firm is also most proficient in other areas of outdoor construction as well.
                  In an area where most homes rely upon either their fireplaces or wood burning stoves in the winter, Tyler’s firewood company keeps homeowners
                  in the Sierra’s well stocked throughout the winter with the firewood that they depend upon in a harsh mountain environment.
                </p>

                <p className="ml-3">
                  In addition to his above ventures, all of which he has personally built from the ground up, Tyler and his wife Jessica, breed and raise various farm
                  animals on their mountain acreage, all while producing and promoting their own YouTube channel, sharing their knowledge and expertise with a rapidly
                  growing following. He also has an AS in business administration with a concentration in marketing.
                </p>

                <p className="ml-3">
                  Tyler’s skills and abilities go far beyond those learned while working for his Degree in Business Administration, or his exceptional marketing abilities.
                  And Tyler passionately believes in creating strong business and personal relationships, while making his clients a friend, and by making sure that anyone
                  in his circle laughs and has fun while working together. Tyler brings an important, fun, outgoing, and driven culture to OutdoorShare LLC. With Tyler’s strong
                  skill sets in marketing and through being extremely well networked throughout the Sierra Mountain community, Tyler is an extremely valuable asset to the
                  OutdoorShare Management Team.
                </p>

                <p className="ml-3">
                  When you do not find Tyler working 80-hour weeks, he is typically found enjoying his dirt bikes, wave runners, motorhome, and other outdoor adventures with his
                  wife Jessica, and their four children. Additionally, you may also find him on stage, or acting for an upcoming film, being the accomplished actor that he also is.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
        </div>
      </section>
      {/* <Rent /> */}
      <Footer />
    </div>
  );
};

export default Aboutepage;
