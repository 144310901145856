import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginResetState } from "../Redux/auth/login/action";
import { loginWithFacebookReset } from "../Redux/auth/loginWithFacebook/action"
import { addPromoCodeAndHostReset } from "../Redux/addPromoCode/action"
import { getPromoCodeDetailsReset } from "../Redux/promoCode/action"
import { resendOTPReset } from "../Redux/auth/resendVerificationOTP/action";
import { verifyOtpReset } from "../Redux/auth/verifyOTP/action";
import { fbLogout } from "../helpers/facebookSDK"
import { updateProfileReset } from '../Redux/profile/action'


const Header = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ACTIVE PAGES
    const location = useLocation();

    //*State
    const [name, setName] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [orderCount, setOrderCount] = useState(0);
    const [siteSetting, setSiteSeetingData] = useState([]);
    
    const user = useSelector(state => state?.SiteSettings?.user);
    const productArray = useSelector(state => state?.AddToCard?.productArray);
    const addToCardSuccess = useSelector(state => state?.AddToCard?.addToCardSuccess);

    useEffect(() => {
        setSiteSeetingData(user);
        if (productArray) { setOrderCount(productArray?.length); } else { setOrderCount(0); }
    }, [user, addToCardSuccess]);

    useEffect(() => {
        let firstName = localStorage.getItem("firstName");
        let lastName = localStorage.getItem("lastName");
        if (firstName && lastName) { setName(firstName + " " + lastName); }
        let accessToken = localStorage.getItem("accessToken"); setAccessToken(accessToken);
    }, []);

    const handleClickLogin = () => {
        dispatch(loginResetState());
        dispatch(loginWithFacebookReset());
    }

    const handleClearSession = () => {
        window.location.reload();
        sessionStorage.removeItem("loggedAuthUser");
        localStorage.removeItem("loggedAuthUser");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("role");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("email");
        localStorage.removeItem("loggedUserId");
        localStorage.removeItem("qRImage");
        localStorage.removeItem("qrUrl");
        localStorage.removeItem("isLoginWithFacebook");
        sessionStorage.clear();
        localStorage.clear();
        navigate("/home");
    }

    return (
        <React.Fragment>
            <header>
                <div className="top-header text-white">
                    <div className="container">
                        <div className="row g-1 align-items-center justify-content-between">
                            <div className="col-auto">
                                {
                                    accessToken === null &&
                                    <p>Now Inviting Hosting Owners! <Link to={"/register"}>Get Started</Link></p>
                                }
                            </div>
                            {
                                accessToken !== null ?
                                    <div className="col-auto">
                                        <div className="dropdown">
                                            <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <span className="ratio ratio-1x1 rounded-circle"><img src="images/userprofile.jpg" alt="userprofile_image" /></span>
                                                {name.length > 15 ? (
                                                    <p className="header-break-word">{name}</p>
                                                ) : (
                                                    <p>{name}</p>
                                                )}
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                <li><Link className="dropdown-item"
                                                    to={"/info"}
                                                    onClick={() => { dispatch(updateProfileReset()); }}
                                                >Profile</Link></li>
                                                <li><button className="dropdown-item"
                                                    onClick={() => {

                                                        let isFacebookCheck = localStorage.getItem("isLoginWithFacebook");
                                                        if (isFacebookCheck) {
                                                            fbLogout();
                                                            handleClearSession();
                                                        } else {
                                                            handleClearSession();
                                                        }
                                                    }}
                                                >Logout</button></li>
                                            </ul>
                                        </div>
                                    </div> :
                                    <div className="col-auto">
                                        <nav>
                                            <ul>
                                                <li><Link to={"/login"} onClick={() => { handleClickLogin(); }}>Login</Link></li>
                                                <li><Link to={"/register"} onClick={() => {
                                                    dispatch(resendOTPReset());
                                                    dispatch(verifyOtpReset());
                                                    dispatch(addPromoCodeAndHostReset());
                                                    dispatch(getPromoCodeDetailsReset());
                                                }} >Register</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <div className="navbar-logo"><Link to={"/home"} ><img src="images/logo.svg" alt="logo" className="img-responsive" /></Link>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto">
                                <li className="nav-item">
                                    <Link
                                        to={"/home"}
                                        className={`nav-link ${location?.pathname === '/home' ? 'active' : ''}`}
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to={"/productlist"}
                                        className={`nav-link ${location?.pathname === '/productlist' ? 'active' : ''}`}
                                    >
                                        Products
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to={"/contact"}
                                        className={`nav-link ${location?.pathname === '/contact' ? 'active' : ''}`}
                                    >
                                        Contact Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to={"/about"}
                                        className={`nav-link ${location?.pathname === '/about' ? 'active' : ''}`}
                                    >
                                        About Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="call-header ms-auto">
                            <div className=""><a href="tel:8002360011"><img src="images/phone.svg" alt="phone_icon" /></a></div>
                            <div className="">
                                <h5><a href="tel:8002360011">{siteSetting?.settings?.site_phone}</a></h5>
                            </div>
                        </div>
                        <div className="cart-header flex-all">
                            <Link to={"/cart"} href="cart.html"><img src="images/cart.svg" alt="cart_icon" /></Link>
                            {orderCount > 0 && (
                                <div className="badge bg-black">{orderCount}</div>
                            )}
                        </div>
                        <button className="navbar-toggler p-0 collapsed ms-3" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-icon">
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </button>
                    </div>
                </nav>
            </header>
        </React.Fragment>
    );
}

export default Header;