import {
    HOST_ADD_REVIEW,
    HOST_ADD_REVIEW_SUCCESSFUL,
    HOST_ADD_REVIEW_FAILED,
    HOST_ADD_REVIEW_RESET
} from "./actionTypes";

export const hostAddReviewDetails = user => {
    return {
        type: HOST_ADD_REVIEW,
        payload: { user },
    }
}

export const hostAddReviewDetailsSuccessful = user => {
    return {
        type: HOST_ADD_REVIEW_SUCCESSFUL,
        payload: user,
    }
}

export const hostAddReviewDetailsFailed = user => {
    return {
        type: HOST_ADD_REVIEW_FAILED,
        payload: user,
    }
}

export const hostAddReviewDetailsReset = user => {
    return {
        type: HOST_ADD_REVIEW_RESET,
        payload: user,
    }
}





