import { call, put, takeEvery } from "redux-saga/effects";
import { RESEND_VERIFY_OTP } from "./actionTypes";
import { resendOTPSuccessful, resendOTPFailed } from "./action";
import { postResendVerificationOtp } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* resendVerificationOTP({ payload: { user } }) {

    try {
        const response = yield call(postResendVerificationOtp, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(resendOTPSuccessful(response.data));

        } else {
            yield put(resendOTPFailed(response.message));
        }
    } catch (error) {
        yield put(resendOTPFailed(error));
    }
}


function* ResendOTPSaga() {
    yield takeEvery(RESEND_VERIFY_OTP, resendVerificationOTP);
}

export default ResendOTPSaga;
