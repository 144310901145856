import {
    HOST_GET_PROTECTION_PROOF,
    HOST_GET_PROTECTION_PROOF_SUCCESSFUL,
    HOST_GET_PROTECTION_PROOF_FAILED,
    HOST_GET_PROTECTION_PROOF_RESET
} from "./actionTypes";

export const hostGetProtectionProof = user => {
    return {
        type: HOST_GET_PROTECTION_PROOF,
        payload: { user },
    }
}

export const hostGetProtectionProofSuccessful = user => {
    return {
        type: HOST_GET_PROTECTION_PROOF_SUCCESSFUL,
        payload: user,
    }
}

export const hostGetProtectionProofFailed = user => {
    return {
        type: HOST_GET_PROTECTION_PROOF_FAILED,
        payload: user,
    }
}

export const hostGetProtectionProofReset = user => {
    return {
        type: HOST_GET_PROTECTION_PROOF_RESET,
        payload: user,
    }
}





