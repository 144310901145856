import React, { useEffect,  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../Redux/auth/forgotPassword/action";
import { toast } from "react-toastify";
import { useFormik } from "formik"
import * as Yup from "yup";
import ResetPasswordPassword from "./resetPassword";

const ForgotPassword = () => {

  const dispatch = useDispatch();
  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const initialValuesForgotPassword = {
    isEmail: "1",
    email: "",
    isResetPassword: false,
  }

  const forgotPasswordSchema = Yup.object({
    email: Yup.string().
      when("isEmail", {
        is: '1',
        then: () =>
          Yup.string()
            .email("Please enter valid email")
            .required("Please enter email or phone number."),
        otherwise: () =>
          Yup.string()
            .required("Please enter email or phone number.")
            .min(10, 'Phone number must be at least 10 char')
      }),
    isResetPassword: Yup.boolean(),
  })


  const { values, errors, touched, handleBlur, handleSubmit, resetForm, setFieldValue, } = useFormik({
    initialValues: initialValuesForgotPassword,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      onClickForgotPassword(values)
    },
  })

  const forgotPasswordError = useSelector(state => state?.ForgotPassword?.forgotPasswordError);
  const isForgotPasswordSuccess = useSelector(state => state?.ForgotPassword?.isForgotPasswordSuccess);
  const isForgotPasswordError = useSelector(state => state?.ForgotPassword?.isForgotPasswordError);
  const loading = useSelector(state => state?.ForgotPassword?.loading);

  useEffect(() => {
    if (isForgotPasswordSuccess && !loading) {
      toast.success("Verification code send to email.");
      setFieldValue("isResetPassword", true);
    }
  }, [isForgotPasswordSuccess]);

  useEffect(() => {
    if (isForgotPasswordError && !loading) { toast.error(forgotPasswordError); }
  }, [isForgotPasswordError]);


  //*onClick Forgot Password
  const onClickForgotPassword = async (values) => {
    var dataObj;
    if (values?.email.indexOf('@') > 0) {
      dataObj = { "email": values?.email };
    } else if (!isNaN(values?.email)) {
      dataObj = { "phone_number": values?.email };
    }
    if (!dataObj) {
      toast.error('Please input correct data.');
      return;
    }
    dispatch(forgotPassword(dataObj));
  };

  return (
    <div>
      <form >
        {
          values.isResetPassword === false ?
            <div>
              <section className="login-wrapper  py-wrapper" style={{ "backgroundImage": "url(images/login-bg.webp)" }}>
                <div className="container z1">
                  <div className="bg-transLight register-block">
                    <div className="bg-lightTheme1">
                      <h1>Forgot Password</h1>
                      <div className="text-start mb-md-5 mb-4">
                        <div className="row g-md-30 g-md-4 g-3">
                          <div className="col-sm-6">
                            <label htmlFor="email">EMAIL OR PHONE NUMBER <label style={{ color: '#FF0000' }}>* </label></label>
                            <input
                              name="email"
                              value={values.email}
                              onChange={(e) => {
                                let checkPhoneNumber = phoneRegex.test(e.target.value);
                                if (checkPhoneNumber) {
                                  setFieldValue("isEmail", "0"); setFieldValue("email", e.target.value);
                                } else {
                                  setFieldValue("isEmail", "1"); setFieldValue("email", e.target.value);
                                }
                              }}
                              onBlur={handleBlur}
                              className={errors.email && touched.email ? 'form-control form-control-error' : 'form-control'}
                              type="text"
                              id="email"
                              placeholder="Email or phone number with country code" />
                            {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                          </div>
                        </div>
                      </div>
                      <button type="button" onClick={() => { handleSubmit(); }} className="btn btn-green"><span>Forgot Password</span></button>
                    </div>
                  </div>
                </div>
              </section>

              <div className="modal modal-video fade" id="exampleModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content ">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="modal-body p-0">
                      <div className="ratio ratio-16x9">
                        <iframe src="https://www.youtube.com/embed/9xwazD5SyVg" title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div>
              <ResetPasswordPassword
                isResetPasswordProps={values.isResetPassword}
              />
              <div className="modal modal-video fade" id="exampleModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content ">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="modal-body p-0">
                      <div className="ratio ratio-16x9">
                        <iframe src="https://www.youtube.com/embed/9xwazD5SyVg" title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
      </form>
    </div>
  );
};

export default ForgotPassword;
