import {
    HOST_VIEW_PAYMENT,
    HOST_VIEW_PAYMENT_SUCCESSFUL,
    HOST_VIEW_PAYMENT_FAILED
} from "./actionTypes";


export const hostViewPaymentAll = user => {
    return {
        type: HOST_VIEW_PAYMENT,
        payload: { user },
    }
}

export const hostViewPaymentSuccessful = user => {
    return {
        type: HOST_VIEW_PAYMENT_SUCCESSFUL,
        payload: user,
    }
}

export const hostViewPaymentFailed = user => {
    return {
        type: HOST_VIEW_PAYMENT_FAILED,
        payload: user,
    }
}