import {
    PLACE_ORDER_CHECKOUT,
    PLACE_ORDER_CHECKOUT_SUCCESSFUL,
    PLACE_ORDER_CHECKOUT_FAILED,
} from "./actionTypes";


export const placeOrderCheckout = user => {
    return {
        type: PLACE_ORDER_CHECKOUT,
        payload: { user },
    }
}

export const placeOrderCheckoutSuccessful = user => {
    return {
        type: PLACE_ORDER_CHECKOUT_SUCCESSFUL,
        payload: user,
    }
}

export const placeOrderCheckoutFailed = user => {
    return {
        type: PLACE_ORDER_CHECKOUT_FAILED,
        payload: user,
    }
}
