import {
    GET_HOST_ORDER_DETAILS,
    GET_HOST_ORDER_DETAILS_SUCCESSFUL,
    GET_HOST_ORDER_DETAILS_FAILED,
} from "./actionTypes";


export const hostGetOrderDetails = user => {
    return {
        type: GET_HOST_ORDER_DETAILS,
        payload: { user },
    }
}

export const hostGetOrderDetailsSuccessful = user => {
    return {
        type: GET_HOST_ORDER_DETAILS_SUCCESSFUL,
        payload: user,
    }
}

export const hostGetOrderDetailsFailed = user => {
    return {
        type: GET_HOST_ORDER_DETAILS_FAILED,
        payload: user,
    }
}
