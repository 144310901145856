import React, { useEffect, useState } from "react";
import ProfileCard from "./Renter/ProfileCard";
import { useDispatch, useSelector } from "react-redux";
import {
  listHostProduct,
  listLocationHostProduct,
  listSearchHostProduct,
  listSortByHostProduct,
  deleteListHostProduct,
  productEnable
} from "../../Redux/host_product_crud/action";
import { hostDeleteProtectionProof } from "../../Redux/host/deleteProtectionProof/action"
import { Link, useNavigate } from "react-router-dom";
import DeleteConfirmationModal from "../Common/DeleteConfirmModal";
import { productDetail } from "../../Redux/actions";
import EnableConfirmationModal from "../Common/EnableConfirmModal";
import { toast } from "react-toastify";
import Select from 'react-select';
import { getCityDetails } from "../../Redux/common/getCities/action"
import { productDetailReset } from "../../Redux/product_details/action"
import { hostGetProtectionProofReset } from "../../Redux/host/getProtectionProof/action"
import { getUserDetails } from "../../Redux/profile/action"
import { PRODUCT_STATUS, IDENTITY_DOCUMENT_STATUS } from "../../helpers/enum"
import { stripeVerification } from "../../helpers/stripeVerification"

const HostProductList = () => {

  //*state
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [enableItemId, setEnableItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEnableModal, setEnableShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState("");
  const [sortingField, setSortingField] = useState("");
  const [selectedProductStatus, setSelectedProductStatus] = useState("");
  const [isShowLoader, setIsShowLoader] = useState(true);
  const [docList, setDocList] = useState([]);

  const sortingOptions = [
    { value: "product_name", label: "Name" },
    { value: "created_at", label: "Date" },
  ];

  //*list
  const [searchCriteria, setSearchCriteria] = useState({
    search_string: "",
    product_location: [] || "",
  });
  const [locationList, setLocationList] = useState([]);
  const [filteredLocationList, setFilteredLocationList] = useState([]);

  const navigate = useNavigate();

  const user = useSelector(state => state?.HostProductCrud?.user);
  const loggedInUser = useSelector(state => state?.UserProfile?.user);
  
  const isDeleteSuccess = useSelector(state => state?.HostProductCrud?.isDeleteSuccess);
  const isDeleteSuccessMsg = useSelector(state => state?.HostProductCrud?.isDeleteSuccessMsg);
  const isProductEnableSuccess = useSelector(state => state?.HostProductCrud?.isProductEnableSuccess);
  const isProductEnableSuccessMsg = useSelector(state => state?.HostProductCrud?.isProductEnableSuccessMsg);
  const success = useSelector(state => state?.HostProductCrud?.success);

  const getCityErrorMessage = useSelector(state => state?.GetCityReducer?.getCityErrorMessage);
  const getCityLoading = useSelector(state => state?.GetCityReducer?.getCityLoading);
  const getCityData = useSelector(state => state?.GetCityReducer?.getCityData);
  const getCitySuccess = useSelector(state => state?.GetCityReducer?.getCitySuccess);

  const singleProductData = useSelector(state => state?.ProductDetail?.user);

  const singleprodutDetail = singleProductData?.product;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success("Product deleted successfully");
      dispatch(listHostProduct({ page: currentPage }));
    }
  }, [isDeleteSuccess, isDeleteSuccessMsg]);

  useEffect(() => {
    if (isProductEnableSuccess) {
      toast.success(selectedProductStatus === "enabled" ? "Product disable successfully" : "Product enabled successfully");
      dispatch(listHostProduct({ page: currentPage }));
    }
  }, [isProductEnableSuccess, isProductEnableSuccessMsg]);

  const handleLocationSearchChange = (event) => {
    let search = event.trim().toLowerCase();
    if (search && search.length > 2) {
      let newArray = locationList.filter(function (el) {
        return el.value.toLowerCase().includes(search);
      });
      setFilteredLocationList(newArray);
    } else {
      setFilteredLocationList(locationList.slice(0, 50));
    }
  };

  useEffect(() => {
    let loggedUserId = localStorage.getItem("loggedUserId");
    let dataObj = { "host_id": loggedUserId }
    dispatch(getCityDetails(dataObj));
    if(!loggedInUser) dispatch(getUserDetails())
  }, []);

  useEffect(() => {
    if (success && user) {
      let docList = user.docs
      setDocList(docList);
      setIsShowLoader(true);
    } else {
      setDocList([]);
      setIsShowLoader(false);
    }
  }, [success]);

  //* get city response
  useEffect(() => {
    if (getCitySuccess && !getCityLoading && getCityData) {
      setLocationList(getCityData);
      setFilteredLocationList(getCityData.slice(0, 50));
    }
  }, [getCitySuccess]);

  useEffect(() => {
    if (getCityErrorMessage && !getCityLoading) {
      setLocationList([]);
    }
  }, [getCityErrorMessage]);

  useEffect(() => {
    // Update the useEffect to fetch host products based on currentPage and searchCriteria
    setIsShowLoader(false)
    dispatch(listHostProduct({ page: currentPage, ...searchCriteria }));
  }, [currentPage, searchCriteria]);

  const handleEdit = (id) => {
    navigate(`/host/editproduct/${id}`);
  };

  // Delete Product
  const handleDeleteByid = async (id) => {
    setDeleteItemId(id);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteListHostProduct({ id: id }));
      await dispatch(hostDeleteProtectionProof({ "product_id": id }));
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  //Enable Product
  const handleEnableByid = async (id) => {
    setEnableItemId(id);
    try {
      await dispatch(productDetail({ id: id }));
      setEnableShowModal(true);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const handleEnable = async (id) => {
    try {
      await dispatch(
        productEnable({
          id: id,
          user: { ...singleprodutDetail, product_status: selectedProductStatus === "enabled" ? "disable" : "enabled" },
        })
      )
    } catch (error) {
      console.error("Error enable product:", error);
    }
  };


  // Serch and location filter to product 
  const handleSearchSubmit = () => {
    if (searchCriteria.search_string.trim() !== "") {
      const apiParams = {
        page: 1,
        ...searchCriteria,
      };
      setCurrentPage(1);
      dispatch(listSearchHostProduct(apiParams));
      dispatch(listLocationHostProduct(apiParams));
    } else {
      console.log("No search criteria provided.");
    }
  };

  const handleSearchInputChange = (event) => {
    const newSearchString = event.target.value;
    setSearch(newSearchString);
    setSearchCriteria((prevSearchCriteria) => ({
      ...prevSearchCriteria,
      search_string: newSearchString,
    }));
  };

  const handleLocationChange = (event) => {
    setLocation(event);

    let product_location = event.map((event) => {
      return event.value;
    })

    setSearchCriteria((prevSearchCriteria) => ({
      ...prevSearchCriteria,
      product_location: product_location,
    }));
  };

  // Sort by product
  const handleSortingChange = (selectedOption) => {
    setSortingField(selectedOption);

    if (selectedOption) {
      const apiParams = {
        page: 1,
        sort_string: selectedOption.value,
        ...searchCriteria,
      };
      dispatch(listSortByHostProduct(apiParams));
    }
  };

  const handleProductStatus = async (product) => {
    setIsShowLoader(false)
    const {verfied,type,msg} = await stripeVerification()
    setIsShowLoader(true)

    if(product?.product_status === "enabled" || verfied) {
      handleEnableByid(product?._id); setSelectedProductStatus(product?.product_status)
      return

    } else {
      toast[type](msg, {
        autoClose: false,
        closeOnClick: true,
        dangerouslyAllowHTML: true
      })
      
    }

  }

  return (
    <div>
      <section className="innerBanner-wrapper">
        <div className="innerBanner-img img-obj cover">
          <img src="images/header.png" alt="header_image" />
        </div>
        <div className="container h-100 z1 flex-all">
          <h1>Products</h1>
        </div>
      </section>
      <section className="profile-wrapper py32-wrapper px-18 host-no-found">
        <div className="container-fluid">
          <div className="row g-4">
            {/* <div className="col-md-auto"> */}
            <ProfileCard />
            {/* </div> */}
            <div className="col-md-9 col-sm-8 profile-main">
              <div className="card ms-xl-1 border-0">
                <div className="card-header justify-content-between product-list-btn border-0">
                  <h3>PRODUCTS</h3>
                  <Link to={"/host/addproduct"} className="btn btn-green">
                    <span>ADD NEW PRODUCT</span>
                  </Link>
                </div>
                <div className="card-body px-0 pb-0">
                  <div className="row g-3 mb-3 productsPage-form">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4 ">
                      <div className="position-relative search-input input-height">
                        <input
                          type="text"
                          className="form-control input-height"
                          placeholder="Search"
                          value={search}
                          onChange={handleSearchInputChange}
                        />
                        <img
                          src="images/search.svg"
                          alt="search_icon"
                          className="input-group-img"
                        />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-4">
                      <Select
                        value={location}
                        options={filteredLocationList}
                        onChange={e => {
                          handleLocationChange(e);
                        }}
                        onInputChange={e => {
                          handleLocationSearchChange(e);
                        }}
                        placeholder={"Enter city name ... "}
                        className="form-select"
                        classNamePrefix="my-react-select"
                        isMulti
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                      />
                    </div>
                    <div className="col-auto">
                      <button className="btn btn-green" onClick={handleSearchSubmit}>
                        <span>SUBMIT</span>
                      </button>
                    </div>
                    <div className="col-xl-2 col-md-3 col-sm-3 col-auto ms-auto">
                      <Select
                        options={sortingOptions}
                        value={sortingField}
                        onChange={handleSortingChange}
                        placeholder="Sort By"
                        className="form-select"
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                      />
                    </div>
                  </div>
                  {isShowLoader === false &&
                    <div id="loader" data-wordload="Please Wait"></div>
                  }
                  {
                    docList.length > 0 ?
                      <>
                        <div className="mb-4">
                          {docList ?
                            docList?.map((product, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <div className="productsPage-block" >
                                    <div className="img-obj cover">
                                      <img
                                        src={`${product?.images_detail[0]?.path}`}
                                        alt={product?._id + '_' + index}
                                        onError={event => {
                                          event.target.src = '/images/default_image.webp'
                                          event.onerror = null
                                        }}
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="productsPage-head">
                                        {product?.product_name?.length > 15 ? (
                                          <h3 className="product-break-word">{product?.product_name}</h3>
                                        ) : (
                                          <h3>{product?.product_name}</h3>
                                        )}
                                        <h4>
                                          ${product?.price_per_day} <span>/ Day</span>
                                        </h4>
                                      </div>
                                      <p>{product?.description}</p>
                                      <div className="row g-3 productsPage-label">
                                        <div className="col-xl-4 col-auto">
                                          {product?.product_location.length > 0 && (
                                            <p>
                                              <span>Location</span>:{" "}
                                              {product?.product_location.join(", ")}
                                            </p>
                                          )}
                                          <p>
                                            <span>Year</span>: 2012
                                          </p>
                                        </div>
                                        <div className="col-xl-4 col-auto">
                                          <p>
                                            <span>Make</span>: {product?.make}
                                          </p>
                                          <p>
                                            <span>Miles/Hours</span>:{" "}
                                            {product?.price_per_hour}
                                          </p>
                                        </div>
                                        <div className="col-xl-4 col-auto">
                                          <p>
                                            <span>Safety Gear</span>:{" "}
                                            {product?.safety_gear}
                                          </p>
                                          <p>
                                            <span>Model</span>: {product?.model}
                                          </p>
                                        </div>
                                        <div className="col-xl-4 col-auto">
                                          <p>
                                            <span>{"Status : "}</span>
                                            <span
                                              className={product?.product_status === PRODUCT_STATUS.ENABLE ? "green-text" :
                                                product?.product_status === PRODUCT_STATUS.DISABLE ? "yello-text" :
                                                  product?.product_status === PRODUCT_STATUS.TEMPORARY_OFF ? "red-text" : null
                                              }
                                              style={{ fontWeight: 'bold' }}
                                            >
                                              {
                                                product?.product_status === PRODUCT_STATUS.ENABLE ? "Verify" :
                                                  product?.product_status === PRODUCT_STATUS.DISABLE ? "Pending" :
                                                    product?.product_status === PRODUCT_STATUS.TEMPORARY_OFF ? "Denied" : ""
                                              }</span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="text-end">
                                        <button
                                          onClick={() => {
                                            dispatch(productDetailReset());
                                            dispatch(hostGetProtectionProofReset());
                                            handleEdit(product?._id);
                                          }
                                          }
                                          className="btn btn-blue btn-icon"
                                          title="Edit Product"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 22.061 21.828"
                                            width="800px"
                                            height="800px"
                                            fill="none"
                                          >
                                            <path
                                              d="M17,3a2.828,2.828,0,0,1,4,4L7.5,20.5,2,22l1.5-5.5Z"
                                              transform="translate(-1 -1.172)"
                                              fill="none"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            />
                                          </svg>
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleDeleteByid(product?._id)
                                          }
                                          className="btn btn-warning btn-icon"
                                          title="Delete Product"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#deleteModal"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 22"
                                          >
                                            <g transform="translate(-2 -1)">
                                              <path
                                                d="M3,6H21"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                              />
                                              <path
                                                d="M19,6V20a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V6M8,6V4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                              />
                                              <line
                                                y2="6"
                                                transform="translate(10 11)"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                              />
                                              <line
                                                y2="6"
                                                transform="translate(14 11)"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                              />
                                            </g>
                                          </svg>
                                        </button>
                                        <button
                                          onClick={() => handleProductStatus(product)}
                                          className={product?.product_status === "enabled" ? "btn btn-danger btn-icon" : "btn btn-primary btn-icon"}
                                          title={product?.product_status === "enabled" ? "Disabled Product" : "Enabled Product"}
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                        >
                                          <svg
                                            width="800px"
                                            height="800px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.5"
                                              d="M20.5 7V13C20.5 16.7712 20.5 18.6569 19.3284 19.8284C18.1569 21 16.2712 21 12.5 21H11.5C7.72876 21 5.84315 21 4.67157 19.8284C3.5 18.6569 3.5 16.7712 3.5 13V7"
                                              stroke="#1C274C"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                            />
                                            <path
                                              d="M2 5C2 4.05719 2 3.58579 2.29289 3.29289C2.58579 3 3.05719 3 4 3H20C20.9428 3 21.4142 3 21.7071 3.29289C22 3.58579 22 4.05719 22 5C22 5.94281 22 6.41421 21.7071 6.70711C21.4142 7 20.9428 7 20 7H4C3.05719 7 2.58579 7 2.29289 6.70711C2 6.41421 2 5.94281 2 5Z"
                                              stroke="#1C274C"
                                              strokeWidth="1.5"
                                            />
                                            <path
                                              d="M9.5 13.4L10.9286 15L14.5 11"
                                              stroke="#1C274C"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }) :
                            <div className="heading flex center">
                              <h2>
                                {"No data available"}
                              </h2>
                            </div>
                          }
                        </div>
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-end">
                            <li
                              className={`page-item ${!user?.hasNextPage ? "disabled" : ""
                                }`}
                            >
                              <button
                                className="page-link prev-link"
                                aria-label="Previous"
                                onClick={() => setCurrentPage(currentPage - 1)}
                              >
                                <span aria-hidden="true">
                                  <i className="fa-solid fa-chevron-left"></i>
                                </span>
                              </button>
                            </li>
                            {Array.from({ length: user?.totalPages }, (_, index) => (
                              <li
                                key={index}
                                className={`page-item ${currentPage === index + 1 ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(index + 1)}
                                >
                                  {index + 1}
                                </button>
                              </li>
                            ))}
                            <li
                              className={`page-item ${!user?.hasNextPage ? "disabled" : ""
                                }`}
                            >
                              <button
                                className="page-link next-link"
                                aria-label="Next"
                                onClick={() => setCurrentPage(currentPage + 1)}
                              >
                                <span aria-hidden="true">
                                  <i className="fa-solid fa-chevron-right"></i>
                                </span>
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </> :
                      <div className="heading flex center">
                        <h2>
                          {"No data available"}
                        </h2>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DeleteConfirmationModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onDelete={() => {
          handleDelete(deleteItemId);
          setShowModal(false);
        }}
        itemId={deleteItemId}
      />
      <EnableConfirmationModal
        isOpen={showEnableModal}
        productStatus={selectedProductStatus}
        onClose={() => setEnableShowModal(false)}
        onDelete={() => {
          handleEnable(enableItemId);
          setEnableShowModal(false);
        }}
        itemId={enableItemId}
      />
    </div>
  );
};

export default HostProductList;
