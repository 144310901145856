import {
    CALCULATE_ORDER_PAYMENT,
    CALCULATE_ORDER_PAYMENT_SUCCESSFUL,
    CALCULATE_ORDER_PAYMENT_FAILED,
} from "./actionTypes";

export const CalculateOrderPayment = orderPaymentDetails => {
    return {
        type: CALCULATE_ORDER_PAYMENT,
        payload: orderPaymentDetails,
    }
}

export const CalculateOrderPaymentSuccessful = orderPaymentDetails => {
    return {
        type: CALCULATE_ORDER_PAYMENT_SUCCESSFUL,
        payload: orderPaymentDetails,
    }
}

export const CalculateOrderPaymentFailed = orderPaymentDetails => {
    return {
        type: CALCULATE_ORDER_PAYMENT_FAILED,
        payload: orderPaymentDetails,
    }
}
