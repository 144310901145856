import { call, put, takeEvery, } from "redux-saga/effects";
import { LOGIN_USER } from "./actionTypes";
import { postLoginUser, getUserProfileDetails } from "../../../helpers/fakebackend_helper";
import { loginUserFaield, loginUserSuccessful, loginUserNotFound } from "./action";
import { setAuthorization } from "../../../helpers/api_helper";
import { API_CODE } from "../../../helpers/enum";

function* loginUser({ payload: { user } }) {
  try {
    const response = yield call(postLoginUser, user);
    if (response.statusCode === API_CODE.SUCCESS) {
      //* Set User data to Local storage
      sessionStorage.setItem("loggedAuthUser", JSON.stringify(response.data));
      localStorage.setItem("loggedAuthUser", JSON.stringify(response.data));

      //*set Token
      localStorage.setItem("accessToken", response.data.token);
      let token = response.data.token;
      // console.log("token------", token);
      setAuthorization(token);
      let Role = response.data.user.role
      if (Role === 1) { localStorage.setItem("role", "renter"); } else { localStorage.setItem("role", "host"); }
      localStorage.setItem("firstName", response.data.user.first_name);
      localStorage.setItem("lastName", response.data.user.last_name);
      localStorage.setItem("email", response.data.user.email);
      localStorage.setItem("loggedUserId", response.data.user._id);
      localStorage.setItem("qRImage", response.data.user.qr_data);
      localStorage.setItem("qrUrl", response.data.user.qr_url);
      localStorage.setItem("isLoginWithFacebook", false);


      //check user auth or not
      try {
        const UserResponse = yield call(getUserProfileDetails);
        // console.log("UserResponse response--------", JSON.stringify(UserResponse));
        if (UserResponse.statusCode === API_CODE.SUCCESS) {
          yield put(loginUserSuccessful(response.data));

        } else {
          yield put(loginUserNotFound("User is Not Verified Yet."));
          sessionStorage.removeItem("loggedAuthUser");
          localStorage.removeItem("loggedAuthUser");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("role");
          localStorage.removeItem("firstName");
          localStorage.removeItem("lastName");
          localStorage.removeItem("email");
          localStorage.removeItem("loggedUserId");
          localStorage.removeItem("qRImage");
          localStorage.removeItem("qrUrl");
          localStorage.removeItem("isLoginWithFacebook");
          sessionStorage.clear();
          localStorage.clear();

        }
      } catch (error) {
        yield put(loginUserNotFound(error));
        sessionStorage.removeItem("loggedAuthUser");
        localStorage.removeItem("loggedAuthUser");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("role");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("email");
        localStorage.removeItem("loggedUserId");
        localStorage.removeItem("qRImage");
        localStorage.removeItem("qrUrl");
        localStorage.removeItem("isLoginWithFacebook");
        sessionStorage.clear();
        localStorage.clear();
      }

    } else {
      yield put(loginUserFaield(response.message));
    }
  } catch (error) {
    yield put(loginUserFaield(error));
  }
}

function* LoginSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export default LoginSaga;
