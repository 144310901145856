import React, { useEffect, useState, useRef } from "react";
//redux
import { useSelector, useDispatch } from "react-redux";
import { userVerifyOtp } from "../../Redux/auth/verifyOTP/action"
import { resendOTP } from "../../Redux/auth/resendVerificationOTP/action"
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './register.css'
import Alert from 'react-bootstrap/Alert';
import { useFormik } from "formik"
import * as Yup from "yup";
import ValidateEmail from "../Common/ValidateEmail";
import { otp_timeout, otp_timeout_minute } from '../../config';


const VerifyOtp = (props) => {

    const ref = useRef()
    const dispatch = useDispatch();
    const navigation = useNavigate();

    //*State
    //*Verify Otp
    const [userId, setUserId] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [sendUserMsg, setSendUserMsg] = useState("");
    const [seconds, setSeconds] = useState(60 * (otp_timeout_minute / 1000));
    const [formattedTime, setFormattedTime] = useState(convertSecondsToHMS(seconds));


    const verifyOtpLoading = useSelector(state => state.VerifyOTPReducer.verifyOtpLoading);
    const verifyOtpSuccess = useSelector(state => state.VerifyOTPReducer.verifyOtpSuccess);
    const verifyOtpError = useSelector(state => state.VerifyOTPReducer.verifyOtpError);
    const verifyOtpErrorMsg = useSelector(state => state.VerifyOTPReducer.verifyOtpErrorMsg);

    const resendOTPLoading = useSelector(state => state.ResendVerificationOTPReducer.resendOTPLoading);
    const resendOTPPasswordSuccess = useSelector(state => state.ResendVerificationOTPReducer.resendOTPPasswordSuccess);
    const resendOTPPasswordError = useSelector(state => state.ResendVerificationOTPReducer.resendOTPPasswordError);
    const resendOTPPasswordErrorMsg = useSelector(state => state.ResendVerificationOTPReducer.resendOTPPasswordErrorMsg);

    useEffect(() => {
        if (props.isVerifyOtp && props.sendUserEmail) {
            let validateEmail = ValidateEmail(props.sendUserEmail);
            if (validateEmail) { setSendUserMsg("Verification code send to your registered email " + props.sendUserEmail) }
            else { setSendUserMsg("Verification code send to your phone number " + props.sendUserEmail) }
            setShowAlert(true); setUserId(props.userId);
        }
    }, [props.sendUserEmail, props.isVerifyOtp]);

    useEffect(() => {
        if (verifyOtpSuccess && !verifyOtpLoading) {
            toast.success('OTP verified successfully.');
            navigation("/login")
            resetForm();
        }
    }, [verifyOtpSuccess]);

    useEffect(() => {
        if (verifyOtpError && !verifyOtpLoading) {
            toast.error("Invalid otp/ otp not verified.");
        }
    }, [verifyOtpError, verifyOtpErrorMsg]);

    useEffect(() => {
        if (resendOTPPasswordSuccess && !resendOTPLoading) {
            // toast.success('Verification Otp sent.');
            let validateEmail = ValidateEmail(props.sendUserEmail);
            if (validateEmail) { setSendUserMsg("Verification code resend to your registered email " + props.sendUserEmail) }
            else { setSendUserMsg("Verification code resend to your phone number " + props.sendUserEmail) }
        }
    }, [resendOTPPasswordSuccess]);

    useEffect(() => {
        if (resendOTPPasswordError && !resendOTPLoading) {
            toast.error(resendOTPPasswordErrorMsg);
        }
    }, [resendOTPPasswordError, resendOTPPasswordErrorMsg]);

    function convertSecondsToHMS(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
                setFormattedTime((prev) => {
                    return convertSecondsToHMS(seconds - 1);
                });
            } else {
                setFormattedTime('');
            }
        }, otp_timeout);
        // Clean up the timer when the component unmounts
        return () => clearInterval(timer);
    }, [seconds]);

    //*onclick Verify otp
    const onClickVerifyOtp = async (otpValues) => {
        let dataObj = {
            "id": userId,
            "otp": otpValues.verifyOtp,
        }
        dispatch(userVerifyOtp(dataObj));
    }

    //*onclick Resend otp
    const onClickResetOtp = async () => {
        let dataObj = { "id": userId }
        dispatch(resendOTP(dataObj));
    }

    //_______________________________________Verify Otp__________________________________//
    const otpInitialValues = {
        verifyOtp: '',
    }
    const otpSchema = Yup.object({
        verifyOtp: Yup.number().min(4).required('Please enter otp.'),
    })

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, resetForm, setFieldValue, } = useFormik({
        initialValues: otpInitialValues,
        validationSchema: otpSchema,
        onSubmit: (values) => {
            onClickVerifyOtp(values);
        },
    })

    return (
        <div>
            <form>
                <div className="bg-lightTheme1">
                    <h1>Verify OTP</h1>
                    <div>
                        {seconds > 0 ?
                            <span className="total" style={{ color: '#FF0000' }} >Your verification code expired in : {formattedTime}</span> :
                            <span className="total" style={{ color: '#FF0000' }} >Your verification code already expired.</span>
                        }
                    </div>
                    <Alert variant="success" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                        <Alert.Heading>{sendUserMsg}</Alert.Heading>
                    </Alert>
                    <div className="text-start mb-md-5 mb-4">
                        <div className="row g-md-30 g-md-4 g-3">
                            <div className="col-sm-6">
                                <label htmlFor="verifyOtp">verification code<label style={{ color: '#FF0000' }}>* </label></label>
                                <input
                                    maxLength={4}
                                    type="number"
                                    name="verifyOtp"
                                    value={values.verifyOtp}
                                    onChange={(e) => {
                                        let newValue = e.target.value;
                                        // Remove non-numeric characters and limit to 4 digits
                                        newValue = newValue.replace(/[^0-9]/g, '').slice(0, 4);
                                        setFieldValue("verifyOtp", newValue);
                                    }}
                                    onBlur={handleBlur}
                                    id="verifyOtp"
                                    className={errors.verifyOtp && touched.verifyOtp ? 'form-control form-control-error' : 'form-control'}
                                    placeholder="Enter OTP" />
                                {errors.verifyOtp && touched.verifyOtp ? <p className="error-text">{errors.verifyOtp}</p> : null}
                            </div>
                            <div className="col-sm-6 mt-5">
                                <button type="button" onClick={() => {
                                    setSeconds(60 * (otp_timeout_minute / 1000))
                                    resetForm();
                                    onClickResetOtp();
                                    setShowAlert(true)
                                }} className="btn btn-green"><span>Resend OTP</span></button>
                            </div>
                        </div>
                    </div>
                    <button type="button" onClick={() => {
                        handleSubmit();
                    }} className="btn btn-green"><span>Verify OTP</span></button>
                </div>
            </form>
        </div>
    )
}
export default VerifyOtp
