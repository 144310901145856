import {
    HOST_DELETE_PROTECTION_PROOF,
    HOST_DELETE_PROTECTION_PROOF_SUCCESSFUL,
    HOST_DELETE_PROTECTION_PROOF_FAILED,
    HOST_DELETE_PROTECTION_PROOF_RESET,

    HOST_DELETE_PROTECTION_PROOF_SINGLE,
    HOST_DELETE_PROTECTION_PROOF_SINGLE_SUCCESSFUL,
    HOST_DELETE_PROTECTION_PROOF_SINGLE_FAILED,
    HOST_DELETE_PROTECTION_PROOF_SINGLE_RESET

} from "./actionTypes";

export const hostDeleteProtectionProof = user => {
    return {
        type: HOST_DELETE_PROTECTION_PROOF,
        payload: { user },
    }
}

export const hostDeleteProtectionProofSuccessful = user => {
    return {
        type: HOST_DELETE_PROTECTION_PROOF_SUCCESSFUL,
        payload: user,
    }
}

export const hostDeleteProtectionProofFailed = user => {
    return {
        type: HOST_DELETE_PROTECTION_PROOF_FAILED,
        payload: user,
    }
}

export const hostDeleteProtectionProofReset = user => {
    return {
        type: HOST_DELETE_PROTECTION_PROOF_RESET,
        payload: user,
    }
}

export const hostDeleteProtectionSingleProof = user => {
    return {
        type: HOST_DELETE_PROTECTION_PROOF_SINGLE,
        payload: { user },
    }
}

export const hostDeleteProtectionProofSingleSuccessful = user => {
    return {
        type: HOST_DELETE_PROTECTION_PROOF_SINGLE_SUCCESSFUL,
        payload: user,
    }
}

export const hostDeleteProtectionProofSingleFailed = user => {
    return {
        type: HOST_DELETE_PROTECTION_PROOF_SINGLE_FAILED,
        payload: user,
    }
}

export const hostDeleteProtectionProofSingleReset = user => {
    return {
        type: HOST_DELETE_PROTECTION_PROOF_SINGLE_RESET,
        payload: user,
    }
}





