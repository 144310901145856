import {
    RENTER_ADD_REVIEW,
    RENTER_ADD_REVIEW_SUCCESSFUL,
    RENTER_ADD_REVIEW_FAILED,
    RENTER_ADD_REVIEW_RESET
} from "./actionTypes";

const initialState = {
    addReviewErrorMessage: null,
    loading: false,
    addReviewData: null,
    success: false,
    error: false,
};


const RenterAddReviewReducer = (state = initialState, action) => {

    switch (action.type) {
        case RENTER_ADD_REVIEW:
            state = {
                ...state,
                loading: true,
                addReviewErrorMessage: null,
            };
            break;
        case RENTER_ADD_REVIEW_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                addReviewData: action.payload,
                success: true,
                errorMessage: null,
                error: false,
            };
            break;
        case RENTER_ADD_REVIEW_FAILED:
            state = {
                ...state,
                addReviewData: null,
                loading: false,
                errorMessage: action.payload,
                error: true,
                success: false,
            };
            break;

        case RENTER_ADD_REVIEW_RESET:
            state = {
                ...state,
                addReviewErrorMessage: null,
                loading: true,
                addReviewData: null,
                success: false,
                error: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default RenterAddReviewReducer;
