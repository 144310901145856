import {
    HOST_DELETE_PROTECTION_PROOF,
    HOST_DELETE_PROTECTION_PROOF_SUCCESSFUL,
    HOST_DELETE_PROTECTION_PROOF_FAILED,
    HOST_DELETE_PROTECTION_PROOF_RESET,

    HOST_DELETE_PROTECTION_PROOF_SINGLE,
    HOST_DELETE_PROTECTION_PROOF_SINGLE_SUCCESSFUL,
    HOST_DELETE_PROTECTION_PROOF_SINGLE_FAILED,
    HOST_DELETE_PROTECTION_PROOF_SINGLE_RESET
} from "./actionTypes";

const initialState = {
    hostDeleteProtectionProofErrorMessage: null,
    hostDeleteProtectionProofLoading: false,
    hostDeleteProtectionProofData: null,
    hostDeleteProtectionProofSuccess: false,
    hostDeleteProtectionProofError: false,

    hostDeleteProtectionProofSingleErrorMessage: null,
    hostDeleteProtectionProofSingleLoading: false,
    hostDeleteProtectionProofSingleData: null,
    hostDeleteProtectionProofSingleSuccess: false,
    hostDeleteProtectionProofSingleError: false,
};


const HostDeleteProtectionPlanReducer = (state = initialState, action) => {

    switch (action.type) {
        case HOST_DELETE_PROTECTION_PROOF:
            state = {
                ...state,
                hostDeleteProtectionProofLoading: true,
                hostDeleteProtectionProofErrorMessage: null,
            };
            break;
        case HOST_DELETE_PROTECTION_PROOF_SUCCESSFUL:
            state = {
                ...state,
                hostDeleteProtectionProofLoading: false,
                hostDeleteProtectionProofData: action.payload,
                hostDeleteProtectionProofSuccess: true,
                hostDeleteProtectionProofErrorMessage: null,
                hostDeleteProtectionProofError: false,
            };
            break;
        case HOST_DELETE_PROTECTION_PROOF_FAILED:
            state = {
                ...state,
                hostDeleteProtectionProofData: null,
                hostDeleteProtectionProofLoading: false,
                hostDeleteProtectionProofErrorMessage: action.payload,
                hostDeleteProtectionProofError: true,
                hostDeleteProtectionProofSuccess: false,
            };
            break;

        case HOST_DELETE_PROTECTION_PROOF_RESET:
            state = {
                ...state,
                hostDeleteProtectionProofErrorMessage: null,
                hostDeleteProtectionProofLoading: false,
                hostDeleteProtectionProofData: null,
                hostDeleteProtectionProofSuccess: false,
                hostDeleteProtectionProofError: false,
            };
            break;


        case HOST_DELETE_PROTECTION_PROOF_SINGLE:
            state = {
                ...state,
                hostDeleteProtectionProofSingleLoading: true,
                hostDeleteProtectionProofSingleErrorMessage: null,
            };
            break;
        case HOST_DELETE_PROTECTION_PROOF_SINGLE_SUCCESSFUL:
            state = {
                ...state,
                hostDeleteProtectionProofSingleLoading: false,
                hostDeleteProtectionProofSingleData: action.payload,
                hostDeleteProtectionProofSingleSuccess: true,
                hostDeleteProtectionProofSingleErrorMessage: null,
                hostDeleteProtectionProofSingleError: false,
            };
            break;
        case HOST_DELETE_PROTECTION_PROOF_SINGLE_FAILED:
            state = {
                ...state,
                hostDeleteProtectionProofSingleData: null,
                hostDeleteProtectionProofSingleLoading: false,
                hostDeleteProtectionProofSingleErrorMessage: action.payload,
                hostDeleteProtectionProofSingleError: true,
                hostDeleteProtectionProofSingleSuccess: false,
            };
            break;

        case HOST_DELETE_PROTECTION_PROOF_SINGLE_RESET:
            state = {
                ...state,
                hostDeleteProtectionProofSingleErrorMessage: null,
                hostDeleteProtectionProofSingleLoading: false,
                hostDeleteProtectionProofSingleData: null,
                hostDeleteProtectionProofSingleSuccess: false,
                hostDeleteProtectionProofSingleError: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default HostDeleteProtectionPlanReducer;
