import {
  GET_SITE_SETTING_DETAILS,
  GET_SITE_SETTING_DETAILS_FAILED,
  GET_SITE_SETTING_DETAILS_SUCCESSFUL,
} from "./actionTypes";

export const getSiteSettingDetails = user => {
  return {
    type: GET_SITE_SETTING_DETAILS,
    payload: { user },
  };
};

export const getSiteSettingDetailsSuccessful = user => {
  return {
    type: GET_SITE_SETTING_DETAILS_SUCCESSFUL,
    payload: user,
  };
};

export const getSiteSettingDetailsFaield = user => {
  return {
    type: GET_SITE_SETTING_DETAILS_FAILED,
    payload: user,
  };
};
