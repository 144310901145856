import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const Calculator = () => {

  const [vehicalType, setVehicalType] = useState("Utv’s");
  const [days, setDays] = useState(30);
  const [calculateAmount, setCalculateAmount] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [daysArray, setDaysArray] = useState([]);

  let approxEarningData = {
    "Utv’s": {
      from: (4500 / 30),
      to: (7875 / 30),
    },
    "Atv’s": {
      from: (2250 / 30),
      to: (3938 / 30),
    },
    "Snowmobiles": {
      from: (11250 / 30),
      to: (15750 / 30),
    },
    "Boats": {
      from: (11250 / 30),
      to: (15750 / 30),
    },
    "Wave runners": {
      from: (3038 / 30),
      to: (3488 / 30),
    },
    "Campers": {
      from: (11250 / 30),
      to: (15750 / 30),
    },
    "Rv’s": {
      from: (3375 / 30),
      to: (14625 / 30),
    },
    "EBikes": {
      from: (1688 / 30),
      to: (2588 / 30),
    },
    "Trucks": {
      from: (1575 / 30),
      to: (2588 / 30),
    },
    "Trailers": {
      from: (788 / 30),
      to: (3938 / 30),
    },
    "Overland": {
      from: (3375 / 30),
      to: (5625 / 30),
    },
    "SUV": {
      from: (1575 / 30),
      to: (2700 / 30),
    },
  }

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken"); setAccessToken(accessToken);
    const options = [
      { value: 0, label: 'Amount of Days Rented' },
      ...Array.from({ length: 30 }, (_, i) => ({ value: i + 1, label: `${i + 1} Days` })),
    ];
    setDaysArray(options);
  }, []);

  const calculateEarning = () => {
    try {
      if (vehicalType && days) {
        let finalAmount = approxEarningData[vehicalType];
        setCalculateAmount(`$ ${Math.round(finalAmount.from * days)} - $ ${Math.round(finalAmount.to * days)} + `);
      } else {
        setCalculateAmount('');
      }
    } catch (error) {
      setCalculateAmount('');
    }
  };

  const vehiclesList = [
    {
      label: "Product Type",
      value: "",
    },
    {
      label: "UTV’s",
      value: "Utv’s",
    },
    {
      label: "ATV’s",
      value: "Atv’s",
    },
    {
      label: "Campers",
      value: "Campers",
    },
    {
      label: "Rv’s",
      value: "Rv’s",
    },
    {
      label: "WaveRunners",
      value: "Wave runners",
    },
    {
      label: "Boats",
      value: "Boats",
    },
    {
      label: "Trailers",
      value: "Trailers",
    },
    {
      label: "Trucks",
      value: "Trucks",
    },
    {
      label: "SUVs",
      value: "SUV",
    },
    {
      label: "E-Bikes",
      value: "EBikes",
    },
    {
      label: "Snowmobiles",
      value: "Snowmobiles",
    },
    {
      label: "Overland",
      value: "Overland",
    },
  ];

  return (
    <section
      className="calculator-wrapper py-wrapper text-white"
      style={{ "backgroundImage": "url(images/calculator-bg.webp)" }}
    >
      <div className="container z1">
        <div className="heading">
          <h2>Calculator</h2>
          <h3>Become An OutdoorShare Hosting Owner! <br /></h3>
          <p><b>See what you can earn!</b> OutdoorShare Will Help You Offset the Cost of Owning Your Expensive Outdoor Adventure Equipment!
          </p>
          <p>Here is an example of the revenue that a Hosting Owner might expect to make on a typical rental based upon the assumptions below.</p>
        </div>
        <div className="row g-3 mb-2">
          <div className="col-md-4 col-sm-6">
            <Select
              options={vehiclesList}
              value={vehiclesList.find((e) => e.value === vehicalType)}
              onChange={(e) => {
                setVehicalType(e.value);
              }}
              className="form-select"
              placeholder={"Select vehicle"}
              classNamePrefix="my-react-select"
              // menuPortalTarget={document.body}
              menuPosition={'fixed'}
            /* styles={{
              // Fixes the overlapping problem of the component
              menu: provided => ({ ...provided, zIndex: "99999 !important" })
            }} */
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <Select
              options={daysArray}
              value={daysArray.find((e) => e.value === days)}
              onChange={(e) => {
                setDays(e.value);
              }}
              className="form-select"
              placeholder={"Select days"}
              classNamePrefix="my-react-select"
              // menuPortalTarget={document.body}
              menuPosition={'fixed'}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <button onClick={calculateEarning} className="btn btn-green w-100">
              <span>Calculate</span>
            </button>
          </div>
        </div>
        <div>
          {calculateAmount &&
            < h1 className="pt-2" > You can earn up to <b> {calculateAmount}</b> </h1>
          }
        </div>
        {
          accessToken === null &&
          <Link to={'/register'} className="btn btn-green mt-4">
            <span>Become a hosting owner</span>
          </Link>
        }
      </div>
    </section >
  );
};

export default Calculator;
