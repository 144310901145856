import {
  LOGIN_WITH_FACEBOOK,
  LOGIN_WITH_FACEBOOK_SUCCESSFUL,
  LOGIN_WITH_FACEBOOK_FAILED,
  LOGIN_WITH_FACEBOOK_RESET_STATE,
} from "./actionTypes";

export const loginWithFacebook = user => {
  return {
    type: LOGIN_WITH_FACEBOOK,
    payload: { user },
  };
};

export const loginWithFacebookSuccessful = user => {
  return {
    type: LOGIN_WITH_FACEBOOK_SUCCESSFUL,
    payload: user,
  };
};

export const loginWithFacebookField = user => {
  return {
    type: LOGIN_WITH_FACEBOOK_FAILED,
    payload: user,
  };
};

export const loginWithFacebookReset = user => {
  return {
    type: LOGIN_WITH_FACEBOOK_RESET_STATE,
    payload: user,
  };
};


