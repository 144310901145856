// import './App.css';
import RouteComponent from "./Setup/Route.component";
import { ToastContainer } from 'react-toastify';
import ScrollToTop from "./Components/Common/scrollToTop";


function App() {
  return (
    <div className="App">
      <RouteComponent />
      <ToastContainer autoClose={3000} />
      <ScrollToTop />
    </div>
  );
}

export default App;
