import React, { useEffect, useState } from "react";
import RenterBannerslider from "../../../Common/Bannerslider";
import ProductListComponent from "./ProductListComponent";
import { hostListSort, hostListProduct } from "../../../../Redux/host_redux/actions";
import { useLocation, useParams } from "react-router-dom";
import Select from 'react-select';
import "./ProductList.css";
import Header from "../../../../Layouts/Header";
import Footer from "../../../../Layouts/Footer";
import { getCityDetails } from "../../../../Redux/common/getCities/action"
import { useSelector, useDispatch } from "react-redux";

export default function RenterProductList() {

  const locations = useLocation();

  const [search, setSearch] = useState(locations?.state?.filterData?.search_string || '');
  const [location, setLocation] = useState(locations?.state?.filterData?.product_location || '');
  const [startDate] = useState(locations?.state?.filterData?.start_date || '');
  const [endDate] = useState(locations?.state?.filterData?.end_date || '');

  const [sortingField, setSortingField] = useState("created_at");
  const [currentPage, setCurPage] = useState(null);

  const [locationList, setLocationList] = useState([]);
  const [filteredLocationList, setFilteredLocationList] = useState([]);

  const dispatch = useDispatch();
  const { hid } = useParams();
  
  const getCityErrorMessage = useSelector(state => state?.GetCityReducer?.getCityErrorMessage);
  const getCityLoading = useSelector(state => state?.GetCityReducer?.getCityLoading);
  const getCityData = useSelector(state => state?.GetCityReducer?.getCityData);
  const getCitySuccess = useSelector(state => state?.GetCityReducer?.getCitySuccess);


  const handleLocationSearchChange = (event) => {
    let search = event.trim().toLowerCase();
    if (search && search.length > 2) {
      let newArray = locationList.filter(function (el) {
        return el.value.toLowerCase().includes(search);
      });
      setFilteredLocationList(newArray);
    } else {
      setFilteredLocationList(locationList.slice(0, 50));
    }
  };

  useEffect(() => {
    dispatch(getCityDetails());
  }, []);

  //* get city response
  useEffect(() => {
    if (getCitySuccess && !getCityLoading && getCityData) {
      setLocationList(getCityData);
      setFilteredLocationList(getCityData.slice(0, 50));
    }
  }, [getCitySuccess]);

  useEffect(() => {
    if (getCityErrorMessage && !getCityLoading) {
      setLocationList([]);
    }
  }, [getCityErrorMessage]);

  const handleSearchInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event);
  };

  const handleSortingChange = (event) => {
    const selectedField = event.value;
    setSortingField(selectedField);
    handleSearchSubmit(search, location?.value, selectedField);
  };

  const page = (page) => {
    setCurPage(page);
  };

  useEffect(() => {
    const debounceDelay = 1000;

    const timer = setTimeout(() => {
      handleSearchSubmit(search, location?.value, sortingField, startDate, endDate, currentPage);
    }, debounceDelay);
    return () => {
      clearTimeout(timer);
    };
  }, [currentPage, search, location, sortingField, startDate, endDate]);


  //*Sorting
  const handleSearchSubmit = (productName, locationName, sortBy) => {

    if ((productName !== undefined || productName !== "") || (locationName !== undefined || locationName !== "") || (sortBy !== undefined || sortBy !== "")) {

      let locationValue = "";

      if (Array.isArray(locationName) && locationName.length > 0) {
        locationValue = locationName[0].value;
      } else if (locationName && locationName.value) {
        locationValue = locationName.value;
      }

      const apiParams = {
        page: 1,
        search_string: productName,
        product_location: locationValue,
        sort_string: sortBy,
        start_date: startDate,
        end_date: endDate,
        "user_id": (hid) ? atob(hid) : undefined,
      };
      dispatch(hostListSort(apiParams));
    }
  };

  return (
    <div>
      <Header />
      <RenterBannerslider headerName={"Product Listing"} />
      <div className="form-wrapper py-4">
        <div className="container">
          <div className="row g-3  productsPage-form">
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5 ">
              <div className="position-relative search-input input-height">
                <input
                  type="text"
                  className="form-control input-height"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearchInputChange}
                />
                <img
                  src="images/search.svg"
                  alt="icon"
                  className="input-group-img"
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5 ">
              <Select
                value={location}
                options={filteredLocationList}
                onChange={e => {
                  handleLocationChange(e);
                }}
                onInputChange={e => {
                  handleLocationSearchChange(e);
                }}
                placeholder={"Enter city"}
                className="form-select"
                classNamePrefix="my-react-select"
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                isClearable={true}
              />
            </div>
            <div className="col-auto">
              <button className="btn btn-green" onClick={() => {
                handleSearchSubmit(search, location?.value, sortingField);
              }}>
                <span>SUBMIT</span>
              </button>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-3 col-auto ms-auto">
              <Select
                options={[
                  { value: "product_name", label: "Name" },
                  { value: "created_at", label: "Date" },
                ]}
                onChange={handleSortingChange}
                placeholder={"Sort By"}
                className="form-select"
                classNamePrefix="my-react-select"
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
              />
            </div>
          </div>
        </div>
      </div>

      <ProductListComponent
        page={page}
        search_string={search}
        product_location={location}
        sort_string={sortingField}
      />
      <Footer />
    </div>
  );
}
