import {
    HOST_GET_PROTECTION_PROOF,
    HOST_GET_PROTECTION_PROOF_SUCCESSFUL,
    HOST_GET_PROTECTION_PROOF_FAILED,
    HOST_GET_PROTECTION_PROOF_RESET
} from "./actionTypes";

const initialState = {
    hostGetProtectionProofErrorMessage: null,
    hostGetProtectionProofLoading: false,
    hostGetProtectionProofData: null,
    hostGetProtectionProofSuccess: false,
    hostGetProtectionProofError: false,
};


const HostGetProtectionPlanReducer = (state = initialState, action) => {

    switch (action.type) {
        case HOST_GET_PROTECTION_PROOF:
            state = {
                ...state,
                hostGetProtectionProofLoading: true,
                hostGetProtectionProofErrorMessage: null,
            };
            break;
        case HOST_GET_PROTECTION_PROOF_SUCCESSFUL:
            state = {
                ...state,
                hostGetProtectionProofLoading: false,
                hostGetProtectionProofData: action.payload,
                hostGetProtectionProofSuccess: true,
                hostGetProtectionProofErrorMessage: null,
                hostGetProtectionProofError: false,
            };
            break;
        case HOST_GET_PROTECTION_PROOF_FAILED:
            state = {
                ...state,
                hostGetProtectionProofData: null,
                hostGetProtectionProofLoading: false,
                hostGetProtectionProofErrorMessage: action.payload,
                hostGetProtectionProofError: true,
                hostGetProtectionProofSuccess: false,
            };
            break;

        case HOST_GET_PROTECTION_PROOF_RESET:
            state = {
                ...state,
                hostGetProtectionProofErrorMessage: null,
                hostGetProtectionProofLoading: false,
                hostGetProtectionProofData: null,
                hostGetProtectionProofSuccess: false,
                hostGetProtectionProofError: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default HostGetProtectionPlanReducer;
