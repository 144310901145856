import {
    CALCULATE_ORDER_PAYMENT,
    CALCULATE_ORDER_PAYMENT_SUCCESSFUL,
    CALCULATE_ORDER_PAYMENT_FAILED,
} from "./actionTypes";

const initialState = {
    calculateOrderPaymentLoading: false,
    calculateOrderPaymentData: null,
    calculateOrderPaymentSuccess: false,
    calculateOrderPaymentError: false,
};


const CalculateOrderPaymentReducer = (state = initialState, action) => {

    switch (action.type) {
        case CALCULATE_ORDER_PAYMENT:
            state = {
                ...state,
                calculateOrderPaymentLoading: true,
            };
            break;
        case CALCULATE_ORDER_PAYMENT_SUCCESSFUL:
            state = {
                ...state,
                calculateOrderPaymentLoading: false,
                calculateOrderPaymentData: action.payload,
                calculateOrderPaymentSuccess: true,
                sendMsgErrorMessage: null,
                calculateOrderPaymentError: false,
            };
            break;
        case CALCULATE_ORDER_PAYMENT_FAILED:
            state = {
                ...state,
                calculateOrderPaymentData: null,
                calculateOrderPaymentLoading: false,
                sendMsgErrorMessage: action.payload,
                calculateOrderPaymentError: true,
                calculateOrderPaymentSuccess: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default CalculateOrderPaymentReducer;
