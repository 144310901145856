import moment from "moment";

function ConvertResponseForCountry(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        myArray.push({ label: value.name, value: value.isoCode });
    })
    return myArray;
};

function ConvertResponseForStateOfCity(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        myArray.push({ label: value.name, value: value.stateCode });
    })
    return myArray;
};

function ConvertResponseForCity(responses) {
    var myArray = [];
    if (responses) {
        responses.map((item, index) => { myArray.push({ label: item, value: item }) })
        return myArray;
    } else {
        return [];
    }
};


function ConvertResponseForSelect(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        myArray.push({ label: value.name, value: value.equipmentId });
    })
    return myArray;
};

function ConvertResponseForSelectWithImg(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        myArray.push({ label: value.name, value: value.equipmentId, icon: value.icon });
    })
    return myArray;
};

function ConvertResponseForCountryAllCities(responses, defaultArray = []) {
    var myArray = [];
    var returnMyArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        myArray.push(value.name);
    });
    let temp = [...new Set(myArray)];
    for (let itm of temp) {
        returnMyArray.push({ label: itm, value: itm });
    }
    return returnMyArray;
};

function ConvertToBinary(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => resolve(reader.result));
        reader.addEventListener("error", err => reject(err));

        reader.readAsBinaryString(file);
    });
}

function ConvertResponseForOrderList(responses) {

    var finalArray = [];
    let orderListResponse = responses.docs;
    let totalDocs = responses.totalDocs;
    let limit = responses.limit;
    let page = responses.page;
    let totalPages = responses.totalPages;
    let pagingCounter = responses.pagingCounter;
    let hasPrevPage = responses.hasPrevPage;
    let hasNextPage = responses.hasNextPage;
    let prevPage = responses.prevPage;
    let nextPage = responses.nextPage;

    orderListResponse.map((item, index) => {
        item.product_item.map((productItem, productIndex) => {

            let userName;
            let getLoggedUserIdRole = localStorage.getItem("role");
            if (getLoggedUserIdRole === "renter") {
                userName = productItem.product_data.host_detail?.first_name + " " + productItem.product_data.host_detail?.last_name
            } else {
                userName = item?.renter_data?.first_name + " " + item?.renter_data?.last_name
            }
            finalArray.push({
                "orderID": "#" + item._id, "productName": productItem.product_data.product_name, "renterName": "David Dorenli", "date": moment(productItem.pickup_date).format("MM/DD/YYYY") + " - " + moment(productItem.return_date).format("MM/DD/YYYY"),
                "price": productItem.product_data.price_per_day.toFixed(2), "paymentStatus": item.payment_status, "realOrderID": item._id, "productId": productItem.product_data._id, "orderStatus": item.order_status, "userId": productItem.product_data.user_id, "userName": userName,
            })
        })
    })

    let finalObj = {
        "finalArray": finalArray,
        "totalDocs": totalDocs, "limit": limit, "page": page, "totalPages": totalPages, "pagingCounter": pagingCounter, "hasPrevPage": hasPrevPage, "hasNextPage": hasNextPage, "prevPage": prevPage, "nextPage": nextPage,
    }
    return finalObj;

}

function clearLocalStorage() {
    sessionStorage.removeItem("loggedAuthUser");
    localStorage.removeItem("loggedAuthUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("email");
    localStorage.removeItem("loggedUserId");
    localStorage.removeItem("qRImage");
    localStorage.removeItem("qrUrl");
    localStorage.removeItem("isLoginWithFacebook");
    sessionStorage.clear();
    localStorage.clear();
}

export { ConvertResponseForCountry, ConvertResponseForCountryAllCities, ConvertToBinary, ConvertResponseForOrderList, clearLocalStorage, ConvertResponseForSelect, ConvertResponseForSelectWithImg, ConvertResponseForCity, ConvertResponseForStateOfCity };




