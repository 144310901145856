import { call, put, takeEvery } from "redux-saga/effects";
import { VERIFY_OTP } from "./actionTypes";
import { verifyOtpSuccessful, verifyOtpFailed } from "./action";
import { postVerifyOtp } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* verifyOtpDetails({ payload: { user } }) {

    try {
        const response = yield call(postVerifyOtp, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(verifyOtpSuccessful(response));

        } else {
            yield put(verifyOtpFailed(response.resp_message));
        }
    } catch (error) {
        yield put(verifyOtpFailed(error));
    }
}


function* VerifyOTPSaga() {
    yield takeEvery(VERIFY_OTP, verifyOtpDetails);
}

export default VerifyOTPSaga;
