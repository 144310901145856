import {
    GET_CITY,
    GET_CITY_SUCCESSFUL,
    GET_CITY_FAILED,
    GET_CITY_RESET,
} from "./actionTypes";

export const getCityDetails = user => {
    return {
        type: GET_CITY,
        payload: { user },
    }
}

export const getCityDetailsSuccessful = user => {
    return {
        type: GET_CITY_SUCCESSFUL,
        payload: user,
    }
}

export const getCityDetailsFailed = user => {
    return {
        type: GET_CITY_FAILED,
        payload: user,
    }
}

export const getCityDetailsReset = user => {
    return {
        type: GET_CITY_RESET,
        payload: user,
    }
}






