import { call, fork, put, takeEvery, all } from "redux-saga/effects";
import {
  ADD_LIST_HOST_PRODUCT,
  EDIT_IMAGE_HOST_PRODUCT,
  EDIT_LIST_HOST_PRODUCT,
  GET_LIST_HOST_PRODUCT,
  LIST_HOST_PRODUCT,
  LIST_LOCATION_HOST_PRODUCT,
  LIST_SEARCH_HOST_PRODUCT,
  LIST_SORTBY_HOST_PRODUCT,
  DELETE_LIST_HOST_PRODUCT,
  ENABLE_LIST_HOST_PRODUCT,
} from "./actionTypes";
import { API_CODE } from "../../helpers/enum";
import { postAddListHostProduct, postEditListHostProduct, postGetListHostProduct, postListHostProduct, postDeleteListHostProduct } from "../../helpers/fakebackend_helper";
import { addListHostProductFailed, addListHostProductSuccessfil, deleteListHostProductFailed, deleteListHostProductSuccessfil, editListHostProductFailed, editListHostProductSuccessfil, getListHostProductFailed, getListHostProductSuccessfil, listHostProductFailed, listHostProductSuccessfil, productEnableSuccess, productEnableFailed } from "./action";
// import { DELETE_LIST_HOST_PRODUCT } from "../../helpers/url_helper";

// List Product
function* listHostProduct({ payload: { user } }) {

  try {
    const response = yield call(postListHostProduct, user);

    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(listHostProductSuccessfil(response.data));
    } else {
      yield put(listHostProductFailed(response.message));
    }
  } catch (error) {
    yield put(listHostProductFailed(error));
  }
}

// Add_Host_Product
function* addListHostProduct({ payload: { user } }) {

  try {
    const response = yield call(postAddListHostProduct, user);
    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(addListHostProductSuccessfil(response.data));

    } else {
      yield put(addListHostProductFailed(response.message));
    }
  } catch (error) {
    yield put(addListHostProductFailed(error));
  }
}

// get Product
function* getListHostProduct({ payload: { user } }) {

  try {
    const response = yield call(postGetListHostProduct, user);
    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(getListHostProductSuccessfil(response.data));

    } else {
      yield put(getListHostProductFailed(response.message));
    }
  } catch (error) {
    yield put(getListHostProductFailed(error));
  }
}

// Edit Product
function* editListHostProduct({ payload: { user, id } }) {
  try {
    const response = yield call(postEditListHostProduct, user, id);
    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(editListHostProductSuccessfil(response.data));

    } else {
      yield put(editListHostProductFailed(response.message));
    }
  } catch (error) {
    yield put(editListHostProductFailed(error));
  }
}

// Enable Product
function* enableListHostProduct({ payload: { user, id } }) {
  try {
    const response = yield call(postEditListHostProduct, user, id);
    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(productEnableSuccess(response.data));

    } else {
      yield put(productEnableFailed(response.message));
    }
  } catch (error) {
    yield put(productEnableFailed(error));
  }
}

// Edit Images Product
function* editImageListHostProduct({ payload: { user } }) {

  try {
    const response = yield call(postEditListHostProduct, user);
    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(editListHostProductSuccessfil(response.data));

    } else {
      yield put(editListHostProductFailed(response.message));
    }
  } catch (error) {
    yield put(editListHostProductFailed(error));
  }
}

// Edit Images Product
function* deleteListHostProduct({ payload: { user } }) {
  try {
    const response = yield call(postDeleteListHostProduct, user);
    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(deleteListHostProductSuccessfil(response.data));

    } else {
      yield put(deleteListHostProductFailed(response.message));
    }
  } catch (error) {
    yield put(deleteListHostProductFailed(error));
  }
}
//Serch product
function* listSearchDataHostProduct({ payload: { user } }) {

  try {
    const response = yield call(postListHostProduct, user);

    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(listHostProductSuccessfil(response.data));
    } else {
      yield put(listHostProductFailed(response.message));
    }
  } catch (error) {
    yield put(listHostProductFailed(error));
  }
}

//Location product
function* listLocationDataHostProduct({ payload: { user } }) {

  try {
    const response = yield call(postListHostProduct, user);

    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(listHostProductSuccessfil(response.data));
    } else {
      yield put(listHostProductFailed(response.message));
    }
  } catch (error) {
    yield put(listHostProductFailed(error));
  }
}

//sort product
function* listSortByDataHostProduct({ payload: { user } }) {

  try {
    const response = yield call(postListHostProduct, user);

    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(listHostProductSuccessfil(response.data));
    } else {
      yield put(listHostProductFailed(response.message));
    }
  } catch (error) {
    yield put(listHostProductFailed(error));
  }
}

function* onListHostProduct() {
  yield takeEvery(LIST_HOST_PRODUCT, listHostProduct);
}

function* onAddListHostProduct() {
  yield takeEvery(ADD_LIST_HOST_PRODUCT, addListHostProduct);
}


function* onGetListHostProduct() {
  yield takeEvery(GET_LIST_HOST_PRODUCT, getListHostProduct);
}

function* onEditListHostProduct() {
  yield takeEvery(EDIT_LIST_HOST_PRODUCT, editListHostProduct);
}

function* onEnableListHostProduct() {
  yield takeEvery(ENABLE_LIST_HOST_PRODUCT, enableListHostProduct);
}

function* onEditImageListHostProduct() {
  yield takeEvery(EDIT_IMAGE_HOST_PRODUCT, editImageListHostProduct);
}

// product delete
function* onDeleteListHostProduct() {
  yield takeEvery(DELETE_LIST_HOST_PRODUCT, deleteListHostProduct);
}

//search product
function* onListSearchHostProduct() {
  yield takeEvery(LIST_SEARCH_HOST_PRODUCT, listSearchDataHostProduct);
}

//search product
function* onListLocationhHostProduct() {
  yield takeEvery(LIST_LOCATION_HOST_PRODUCT, listLocationDataHostProduct);
}

//sort product
function* onListSortByHostProduct() {
  yield takeEvery(LIST_SORTBY_HOST_PRODUCT, listSortByDataHostProduct);
}

function* HostProductSaga() {

  yield all([
    fork(onListHostProduct),
    fork(onAddListHostProduct),
    fork(onGetListHostProduct),
    fork(onEditListHostProduct),
    fork(onEditImageListHostProduct),
    fork(onDeleteListHostProduct),
    fork(onListSearchHostProduct),
    fork(onListLocationhHostProduct),
    fork(onListSortByHostProduct),
    fork(onEnableListHostProduct),
  ]);
}

export default HostProductSaga;
