import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { GET_RENTER_ORDER_DETAILS } from "./actionTypes";
import { getRenterOrderDetailsSuccessful, getRenterOrderDetailsFailed, } from "./action";
import { postRenterOrderDetails, } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* getRenterOrderDetails({ payload: { user } }) {

    try {
        const response = yield call(postRenterOrderDetails, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(getRenterOrderDetailsSuccessful(response.data));
        } else {
            yield put(getRenterOrderDetailsFailed(response.message));
        }
    } catch (error) {
        yield put(getRenterOrderDetailsFailed(error));
    }
}

export function* watchOnGetRenterOrderDetails() {
    yield takeEvery(GET_RENTER_ORDER_DETAILS, getRenterOrderDetails);
}

function* RenterOrderDetailSaga() {

    yield all([
        fork(watchOnGetRenterOrderDetails),
    ]);
}

export default RenterOrderDetailSaga;
