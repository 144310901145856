import React from "react";
import { Link } from "react-router-dom";
import './OrderCancel.css';

const OrderCancel = () => {

  return (
    // https://codepen.io/Konrud/pen/mwZXgV
    // https://codepen.io/jayDilz/pen/ONjORo
    <div className="cancel_order">
      <section className="c-container ">
        <div className="container">
          <div className="ui middle aligned center aligned grid">
            <div className="ui eight wide column">
              <form className="ui large form">
                <div className="ui icon negative message">
                  <i className="warning icon"></i>
                  <div className="content">

                    <div className="o-circle c-container__circle o-circle__sign--failure">
                      <div className="o-circle__sign"></div>
                    </div>

                    <div className="header">
                      Oops! Something went wrong.
                    </div>
                    <p>While trying to reserve money from your account</p>
                  </div>
                </div>
                <Link to={'/renter/order'} className="ui large teal submit fluid button btn btn-warning">Back to order</Link>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OrderCancel
