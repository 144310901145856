import { call, put, takeEvery, } from "redux-saga/effects";
import { HOST_ORDER_LIST } from "./actionTypes";
import { hostOrderListSuccessful, hostOrderListFailed } from "./action";
import { getHostOrderList } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'
import { ConvertResponseForOrderList } from '../../../helpers/commonfunction'

function* hostOrderDetails({ payload: { user } }) {

    try {
        const response = yield call(getHostOrderList, user);
        var OrderListArray = ConvertResponseForOrderList(response.data);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(hostOrderListSuccessful(OrderListArray));
        } else {
            yield put(hostOrderListFailed(response.message));
        }
    } catch (error) {
        yield put(hostOrderListFailed(error));
    }
}

function* HostOrderSaga() {
    yield takeEvery(HOST_ORDER_LIST, hostOrderDetails);
}

export default HostOrderSaga;
