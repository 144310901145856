import { call, put, takeEvery } from "redux-saga/effects";
import { HOST_LIST_PRODUCT, HOST_LIST_PRODUCT_SORT } from "./actionTypes";
import { hostListProductFaield, hostListProductSuccessful, hostListSortSuccessful, hostListSortFailed } from "./actions";
import { API_CODE } from "../../helpers/enum";
import { postHostListProduct } from "../../helpers/fakebackend_helper";

function* hostListProduct({ payload: { user } }) {
  try {
    const response = yield call(postHostListProduct, user);
    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(hostListProductSuccessful(response.data));
    } else {
      yield put(hostListProductFaield(response.message));
    }
  } catch (error) {
    yield put(hostListProductFaield(error));
  }
}

function* hostListProductSort({ payload: { user } }) {
  try {
    const response = yield call(postHostListProduct, user);
    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(hostListSortSuccessful(response.data));
    } else {
      yield put(hostListSortFailed(response.message));
    }
  } catch (error) {
    yield put(hostListProductFaield(error));
  }
}

function* HostListProductSaga() {
  yield takeEvery(HOST_LIST_PRODUCT, hostListProduct);
  yield takeEvery(HOST_LIST_PRODUCT_SORT, hostListProductSort);
}

export default HostListProductSaga;
