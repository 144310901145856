import {
  HOST_LIST_PRODUCT,
  HOST_LIST_PRODUCT_FAILED,
  HOST_LIST_PRODUCT_SUCCESSFUL,
  HOST_LIST_PRODUCT_SORT,
  HOST_LIST_PRODUCT_SORT_SUCCESSFUL,
  HOST_LIST_PRODUCT_SORT_FAILED,
} from "./actionTypes";

export const hostListProduct = (user) => {
  return {
    type: HOST_LIST_PRODUCT,
    payload: { user },
  };
};

export const hostListProductSuccessful = (user) => {
  return {
    type: HOST_LIST_PRODUCT_SUCCESSFUL,
    payload: user,
  };
};

export const hostListProductFaield = (user) => {
  return {
    type: HOST_LIST_PRODUCT_FAILED,
    payload: user,
  };
};

export const hostListSort = (user) => {
  return {
    type: HOST_LIST_PRODUCT_SORT,
    payload: { user },
  };
};

export const hostListSortSuccessful = (user) => {
  return {
    type: HOST_LIST_PRODUCT_SORT_SUCCESSFUL,
    payload: { user },
  };
};

export const hostListSortFailed = (user) => {
  return {
    type: HOST_LIST_PRODUCT_SORT_FAILED,
    payload: { user },
  };
};





