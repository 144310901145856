import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { GET_CITY } from "./actionTypes";
import { getCityDetailsSuccessful, getCityDetailsFailed, } from "./action";
import { postGetCity } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'
import { ConvertResponseForCity } from "../../../helpers/commonfunction"


function* getCityDetailsOnClick({ payload: { user } }) {
    try {
        const response = yield call(postGetCity, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            var getCityResponse = response.data.cityList; getCityResponse = ConvertResponseForCity(getCityResponse);
            let sortArray = getCityResponse.sort((a, b) => (a.label > b.label ? 1 : -1))
            if (sortArray) { yield put(getCityDetailsSuccessful(sortArray)); }
        } else {
            yield put(getCityDetailsFailed(response.message));
        }
    } catch (error) {
        yield put(getCityDetailsFailed(error));
    }
}

export function* watchOnGetCity() {
    yield takeEvery(GET_CITY, getCityDetailsOnClick);
}

function* GetCitySaga() {

    yield all([
        fork(watchOnGetCity),
    ]);
}

export default GetCitySaga;
