import {
    RESEND_VERIFY_OTP,
    RESEND_VERIFY_OTP_SUCCESSFUL,
    RESEND_VERIFY_OTP_FAILED,
    RESEND_VERIFY_OTP_RESET,
} from "./actionTypes";

const initialState = {
    resendOTPLoading: false,
    resendOTPPasswordSuccess: false,
    resendOTPPasswordError: false,
    resendOTPPasswordErrorMsg: null,
    resendOTPData: null,
};

const ResendVerificationOTPReducer = (state = initialState, action) => {

    switch (action.type) {
        case RESEND_VERIFY_OTP:
            state = {
                ...state,
                resendOTPLoading: true,
                resendOTPPasswordSuccess: false,
                resendOTPPasswordError: false,
                resendOTPPasswordErrorMsg: null,
                resendOTPData: null,
            };
            break;
        case RESEND_VERIFY_OTP_SUCCESSFUL:
            state = {
                ...state,
                resendOTPLoading: false,
                resendOTPPasswordSuccess: true,
                resendOTPPasswordError: false,
                resendOTPPasswordErrorMsg: null,
                resendOTPData: action.payload,
            };
            break;
        case RESEND_VERIFY_OTP_FAILED:
            state = {
                ...state,
                resendOTPLoading: false,
                resendOTPPasswordSuccess: false,
                resendOTPPasswordError: false,
                resendOTPPasswordErrorMsg: action.payload,
                resendOTPData: null,
            };
            break;
        case RESEND_VERIFY_OTP_RESET:
            state = {
                ...state,
                resendOTPLoading: false,
                resendOTPPasswordSuccess: false,
                resendOTPPasswordError: false,
                resendOTPPasswordErrorMsg: null,
                resendOTPData: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default ResendVerificationOTPReducer;
