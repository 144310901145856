import {
    RENTER_ORDER_LIST,
    RENTER_ORDER_LIST_SUCCESSFUL,
    RENTER_ORDER_LIST_FAILED
} from "./actionTypes";


export const renterOrderList = user => {
    return {
        type: RENTER_ORDER_LIST,
        payload: { user },
    }
}

export const renterOrderListSuccessful = user => {
    return {
        type: RENTER_ORDER_LIST_SUCCESSFUL,
        payload: user,
    }
}

export const renterOrderListFailed = user => {
    return {
        type: RENTER_ORDER_LIST_FAILED,
        payload: user,
    }
}