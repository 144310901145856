import {
    FETCH_MESSAGE_DETAILS,
    FETCH_MESSAGE_DETAILS_SUCCESSFUL,
    FETCH_MESSAGE_DETAILS_FAILED
} from "./actionTypes";

export const fetchMessageDetails = (data) => ({
    type: FETCH_MESSAGE_DETAILS,
    payload: data,
});

export const fetchMessageDetailsSuccessful = (data) => ({
    type: FETCH_MESSAGE_DETAILS_SUCCESSFUL,
    payload: data,
});

export const fetchMessageDetailsFailed = (error) => ({
    type: FETCH_MESSAGE_DETAILS_FAILED,
    payload: error,
});
