import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { HOST_ADD_REVIEW } from "./actionTypes";
import { hostAddReviewDetailsSuccessful, hostAddReviewDetailsFailed, } from "./action";
import { postHostProductReview } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* hostAddProductReview({ payload: { user } }) {

    try {
        const response = yield call(postHostProductReview, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(hostAddReviewDetailsSuccessful(response.data));
        } else {
            yield put(hostAddReviewDetailsFailed(response.message));
        }
    } catch (error) {
        yield put(hostAddReviewDetailsFailed(error));
    }
}

export function* watchOnHostAddReview() {
    yield takeEvery(HOST_ADD_REVIEW, hostAddProductReview);
}

function* HostAddReviewSaga() {

    yield all([
        fork(watchOnHostAddReview),
    ]);
}

export default HostAddReviewSaga;
