import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import Header from "../../../Layouts/Header";
import Footer from "../../../Layouts/Footer";
import { toast } from "react-toastify";
import {
  contactUSDetail,
  contactUSReset
} from "../../../Redux/contactUs/action";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { getSiteSettingDetails } from "../../../Redux/siteSettings/action";
import { useFormik } from "formik"
import * as Yup from "yup";


const Contact = (props) => {

  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  }
  
  const contactSchema = Yup.object({
    name: Yup.string().min(2, "First name must be at least 2 characters").max(30).required('Please enter name'),
    email: Yup.string().email("Please enter valid email").required('Please enter email'),
    phoneNumber: Yup.string()
      .test('oneOfRequired', 'Please enter a valid phone number', function (item) {
        const phoneNumber = this.parent.phoneNumber;
        if (phoneNumber === '1' || phoneNumber === undefined) {
          return false; // Return false to trigger the validation error
        }
        return true; // No error
      }),
    message: Yup.string().min(2, "First name must be at least 2 characters").max(200).required('Please enter message'),
  })

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, resetForm, setFieldValue, } = useFormik({
    initialValues: initialValues,
    validationSchema: contactSchema,
    onSubmit: (values) => {
      onClickSubmit(values);
    },
  })

  //*List
  const [siteSetting, setSiteSeetingData] = useState([]);

  const user = useSelector(state => state?.SiteSettings?.user);

  const contactUsErrorMsg = useSelector(state => state?.ContactUsReducer?.contactUsErrorMsg);
  const contactUsLoading = useSelector(state => state?.ContactUsReducer?.contactUsLoading);
  const contactUsSuccess = useSelector(state => state?.ContactUsReducer?.contactUsSuccess);
  const contactUsError = useSelector(state => state?.ContactUsReducer?.contactUsError);

  useEffect(() => {
    if (user !== null && user !== "null") {
      setSiteSeetingData(user);
    } else {
      dispatch(getSiteSettingDetails());
    }
  }, [user]);

  useEffect(() => {
    if (contactUsSuccess && !contactUsLoading) {
      toast.success("Thank you for getting in touch with us!");
      resetForm();
      setFieldValue("phoneNumber", "1");
      dispatch(contactUSReset());
    }
  }, [contactUsSuccess]);

  useEffect(() => {
    if (contactUsError) {
      toast.error(contactUsErrorMsg);
      resetForm();
      setFieldValue("phoneNumber", "1");
    }
  }, [contactUsError, contactUsErrorMsg]);

  //*Onclick Submit
  const onClickSubmit = async (values) => {
    let dataObj = {
      name: values?.name,
      email: values?.email,
      phone_number: values?.phoneNumber,
      message: values?.message,
    };
    dispatch(contactUSDetail(dataObj));
  };

  return (
    <form>
      <div>
        {!props.fromHome && <Header />}
        {!props.fromHome && (
          <section className="innerBanner-wrapper">
            <div className="innerBanner-img img-obj cover">
              <img src="images/header.png" alt="header_image" />
            </div>
            <div className="container h-100 z1 flex-all">
              <h1>Contact Us</h1>
            </div>
          </section>
        )}

        <section className="contact-wrapper py-wrapper">
          <div className="container z1">
            <div className="row g-4 ">
              <div className="col-lg-6">
                <div className="heading mb-lg-5">
                  <h2>Contact Information</h2>
                  <p>
                    Our biggest Priority is to ensure an easy, convenient, and safe process to assist you in creating the lasting memories and amazing adventures that you are seeking. OutdoorShare is here to assist you in any way we can, in order to help you achieve your outdoor adventure goals.
                  </p>
                </div>
                {/* <div className="contact-block">
                  <div className="ratio ratio-1x1 rounded-circle bg-green">
                    <div className="flex-all">
                      <i className="fa-regular fa-location-dot"></i>
                    </div>
                  </div>
                  <p>{siteSetting?.settings?.site_address}</p>
                </div> */}
                <div className="contact-block">
                  <div className="ratio ratio-1x1 rounded-circle bg-green">
                    <div className="flex-all">
                      <i className="fa-regular fa-envelope"></i>
                    </div>
                  </div>
                  <p>
                    <a href="mailto:lorem@example.com">
                      {siteSetting?.settings?.site_email}
                    </a>
                  </p>
                </div>
                <div className="contact-block">
                  <div className="ratio ratio-1x1 rounded-circle bg-green">
                    <div className="flex-all">
                      <i className="fa-regular fa-phone"></i>
                    </div>
                  </div>
                  <p>
                    <a href="tel:8002360011">
                      {siteSetting?.settings?.site_phone}
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box-shadow bg-white">
                  <h3>Send us a Message</h3>
                  <div action="#" className="contact-form">
                    <div className="row g-md-4 g-3">
                      <div className="col-12">
                        <input
                          maxLength={30}
                          name="name"
                          value={values.name}
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedInputValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
                            setFieldValue("name", sanitizedInputValue);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? <p className="error-text">{errors.name}</p> : null}
                      </div>
                      <div className="col-sm-6">
                        <input
                          maxLength={30}
                          name="email"
                          value={values.email}
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          onChange={(e) => {
                            setFieldValue("email", e.target.value);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                      </div>
                      <div className="col-sm-6">
                        <PhoneInput
                          enableAreaCodes={false}
                          country={"us"}
                          onlyCountries={["us"]}
                          name="phoneNumber"
                          id="phoneNumber"
                          value={values.phoneNumber}
                          onChange={(phone) => {
                            setFieldValue("phoneNumber", phone);
                          }}
                          onBlur={handleBlur}
                          className="form-control"
                          inputStyle={{
                            border: "none",
                            padding: "0px 42px",
                          }}
                          disableDropdown={true}
                          countryCodeEditable={false}
                        ></PhoneInput>
                        {errors.phoneNumber && touched.phoneNumber ? <p className="error-text">{errors.phoneNumber}</p> : null}
                      </div>
                      <div className="col-12">
                        <textarea
                          maxLength={1000}
                          name="message"
                          rows="4"
                          className="form-control"
                          placeholder="Message"
                          value={values.message}
                          onChange={(e) => {
                            setFieldValue("message", e.target.value);
                          }}
                          onBlur={handleBlur}
                        ></textarea>
                        {errors.message && touched.message ? <p className="error-text">{errors.message}</p> : null}
                      </div>
                      <div className="col-12">
                        <button
                          type='button'
                          onClick={() => {
                            handleSubmit();
                          }}
                          className="btn btn-green"
                        >
                          <span>SUBMIT</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {!props.fromHome && <Footer />}
      </div >
    </form>
  );
};

export default Contact;
