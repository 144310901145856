import {
    FETCH_MESSAGE_DETAILS,
    FETCH_MESSAGE_DETAILS_SUCCESSFUL,
    FETCH_MESSAGE_DETAILS_FAILED,
} from "./actionTypes";

const initialState = {
    fetchMsgErrorMessage: null,
    fetchMsgLoading: false,
    fetchMsgData: null,
    fetchMsgSuccess: false,
    fetchMsgError: false,
};

const FetchMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MESSAGE_DETAILS:
            state = {
                ...state,
                fetchMsgLoading: true,
                fetchMsgErrorMessage: null,
            };
            break;
        case FETCH_MESSAGE_DETAILS_SUCCESSFUL:
            state = {
                ...state,
                fetchMsgLoading: false,
                fetchMsgData: action.payload,
                fetchMsgSuccess: true,
                fetchMsgErrorMessage: null,
                fetchMsgError: false,
            };
            break;
        case FETCH_MESSAGE_DETAILS_FAILED:
            state = {
                ...state,
                fetchMsgData: null,
                fetchMsgLoading: false,
                fetchMsgErrorMessage: action.payload,
                fetchMsgError: true,
                fetchMsgSuccess: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default FetchMessageReducer;
