import {
    PRODUCT_ADD_TO_CARD,
    PRODUCT_ADD_TO_CARD_DELETE,
    PRODUCT_ADD_TO_CARD_RESET,
} from "./actionTypes";

const initialState = {
    errorMessage: null,
    loading: false,
    addToCardSuccess: false,
    error: false,
    productArray: [],
};


const AddToCard = (state = initialState, action) => {

    switch (action.type) {
        case PRODUCT_ADD_TO_CARD:
            // Find the index of the object you want to replace based on some condition (e.g., ID).
            const objectIndex = state.productArray.findIndex((obj) => obj._id === action.payload._id);
            var finalArray;
            if (objectIndex !== -1) {
                // Create a new array with the old object replaced by the new object.
                const newData = [...state.productArray];
                newData[objectIndex] = action.payload;
                // Update the state with the new array.
                finalArray = newData
            } else {
                finalArray = [...state.productArray, action.payload]
            }

            state = {
                ...state,
                loading: true,
                errorMessage: null,
                addToCardSuccess: true,
                productArray: finalArray,
            };
            break;
        case PRODUCT_ADD_TO_CARD_DELETE:

            const updatedItems = state.productArray.filter(item => item.uniqueId !== action.payload);
            state = {
                ...state,
                loading: true,
                errorMessage: null,
                addToCardSuccess: true,
                productArray: updatedItems.length === 0 ? [] : updatedItems
            };
            break;

        case PRODUCT_ADD_TO_CARD_RESET:
            state = {
                ...state,
                errorMessage: null,
                loading: false,
                addToCardSuccess: false,
                error: false,
                productArray: [],
            };
            break;
        default:
            return state;
    }
    return state;
};

export default AddToCard;
