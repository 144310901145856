import {
    CANCEL_ORDER,
    CANCEL_ORDER_SUCCESSFUL,
    CANCEL_ORDER_FAILED
} from "./actionTypes";

const initialState = {
    isCancelOrderLoading: false,
    cancelOrderData: null,
    cancelOrderErrorData: null,
    isCancelOrderSuccess: false,
    isCancelOrderError: false
};


const CancelOrderReducer = (state = initialState, action) => {

    switch (action.type) {
        case CANCEL_ORDER:
            state = {
                ...state,
                isCancelOrderLoading: true,
                cancelOrderErrorData: null,
            };
            break;
        case CANCEL_ORDER_SUCCESSFUL:
            state = {
                ...state,
                isCancelOrderLoading: false,
                cancelOrderData: action.payload,
                isCancelOrderSuccess: true,
                cancelOrderErrorData: null,
                isCancelOrderError: false,
            };
            break;
        case CANCEL_ORDER_FAILED:
            state = {
                ...state,
                cancelOrderData: null,
                isCancelOrderLoading: false,
                cancelOrderErrorData: action.payload,
                isCancelOrderError: true,
                isCancelOrderSuccess: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default CancelOrderReducer;
