import {
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESSFUL,
    RESET_PASSWORD_FAILED
} from "./actionTypes";

const initialState = {
    resetLoading: false,
    isResetPasswordSuccess: false,
    isResetPasswordError: false,
    resetPasswordErrorMsg: null,
};

const ResetPasswordReducer = (state = initialState, action) => {

    switch (action.type) {
        case RESET_PASSWORD:
            state = {
                ...state,
                resetLoading: true,
                isResetPasswordSuccess: false,
                isResetPasswordError: false,
            };
            break;
        case RESET_PASSWORD_SUCCESSFUL:
            state = {
                ...state,
                resetLoading: false,
                isResetPasswordSuccess: true,
                resetPasswordErrorMsg: null,
            };
            break;
        case RESET_PASSWORD_FAILED:
            state = {
                ...state,
                resetLoading: false,
                isResetPasswordSuccess: false,
                isResetPasswordError: true,
                resetPasswordErrorMsg: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default ResetPasswordReducer;
