export const ROLE_TYPES = {
    RENTER: "1",
    HOST: "2",
}

export const ROLE_TYPES_NO = {
    RENTER: 1,
    HOST: 2,
}

export const API_CODE = {
    SUCCESS: 200,
    SUCCESS_BOOL: true,
}

export const ORDER_STATUS = {
    INPROGRESS: "in_progress",
    COMPLETE: "complete",
    FAILED: "failed",
    CANCELLED: "cancelled",
    PENDING: "pending",
    ACCEPTED: "accepted",
    REJECTED: "rejected",
    AGREEMENT_SENDED: "agreement_sended",
    RETURN_AGREEMENT: "return_agreement",
    CHECK_OUT: "checkout",
    CHECK_IN: "checkin",
    RETURN_DEPOSIT_ACCEPTED: 'return_deposit_accepted',
    RETURN_DEPOSIT_REJECTED: 'return_deposit_rejected',
    FINAL_AMOUNT_AUTO_DEDUCT: 'final_amount_auto_deduct',
    RENTER_AGREEMENT: 'renter_Agreement'
}

export const ORDER_PAYMENT_STATUS = {
    PAID: "paid",
    SECURITY_DEPOSIT_REFUNDED: "security_deposit_refunded",
    SUCCEEDED: "succeeded",
    PENDING: "pending",
}

export const STATIC_PRODUCT = {
    ENCLOSED_CARGO_TRAILER: "16ft Enclosed Cargo Trailer",
}

export const STATIC_PRODUCT_LINK = {
    ENCLOSED_CARGO_TRAILER: "https://tow-n-gorentals.com/?utm_source=affiliate&utm_medium=lead&utm_campaign=outdoorshare",
}

export const HOST_MESSAGE = {

    AGREEMENT_SIGN: "Please rechout to The Renter to arrange checkout using our chat system.",
    CHECKIN: "One last thing Please Accept or reject the safe return of your product.",
    ORDER_ACCEPT: "Please sign rental agreement",
    CHECKOUT:  "Start preparing for check out. Be sure to have this arranged for the time shown below.",
    ORDER_APPROVE: "Rental complete! Pay out coming soon. Congrats!",
    ORDER_REJECT: "Rejected safe return, Currently under review, please look out for communication from outdoorshare."
}

export const RENTER_MESSAGE = {

    ORDER_RENTAL: "Waiting on The Host to approve your rental Request.",
    AGREEMENT_SIGN: "Please rechout to The Host to arrange checkout using our chat system.",
    CHECKIN: "Waiting on Host to Confirm a safe return.",
    CHECKOUT: "Almost time to checkout your equipment! Be sure to have this arranged for the time shown below.",
    ORDER_APPROVE: "Rental complete! Deposit is on it's way back!",
    ORDER_REJECT: "Host rejected safe return, Currently under review."

}

export const INSURANCE_OPTION = {
    INSURANCE_OPTION_ONE: "OutdoorshareProtectionPolicy",
    INSURANCE_OPTION_TWO: "IhaveMyOwnCommercialInsurance",
}

export const PRODUCT_STATUS = {
    ENABLE: "enabled",
    DISABLE: "disabled",
    TEMPORARY_OFF: "temporary_off"
}

export const IDENTITY_DOCUMENT_STATUS = {
    PENDING: "Pending",
    VERIFIED: "Verified",
    REJECTED: "Rejected",
    UNVERIFIED: "Unverified",
    INPROGRESS: "InProgress",
}

export const PROMO_CODE_TYPES = {
    PRODUCT: "product",
    HOST: "host",
}