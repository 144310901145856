import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./OrderSuccess.css";
import { productAddCardReset } from "../../Redux/addToCard/action";
import { useDispatch } from "react-redux";

const OrderSuccess = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productAddCardReset());
  }, []);

  return (
    <section className="success_order" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Adjust the height based on your design
    }}>
      <div className="cardOd" >
        <div className="cardData">
          <i className="checkmark">✓</i>
        </div>
        <h1>Success</h1>
        <div className="lead" >
          <b className="text-success">Thank you! </b><br /> Thank you for sharing the outdoors with us! We appreciate you.
          <p>
            You can check the details and status of your order in the "Orders" section of your profile.
          </p>
          <p>
            <b>Connect with your host</b> by sending a message at the bottom of the <b>order details</b> page.
          </p>
          <p style={{ maxWidth: '900px', margin: '0 auto' }}>
            As you embark on your outdoor journey, remember to <b> stay safe</b>, embrace the  <b>thrill {' '}
            of adventure</b>, and <b>Find New Boundaries</b>. We invite you to explore new horizons and {' '}
            create unforgettable memories. Happy exploring!
          </p>
        </div>
        <Link style={{ fontSize: '18px' }} to={"/renter/order"} className="Link_404">
          Go to Orders Page
        </Link>
      </div>
    </section>
  );
};

export default OrderSuccess;
