import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateProfileReset } from '../../../Redux/profile/action'
import { useNavigate } from "react-router-dom";

const ProfileCard = (props) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  //*State
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [qRCodeImage, setQRCodeImage] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  const [qrUrlSplit, setQrUrlSplit] = useState("");

  useEffect(() => {
    let getRole = localStorage.getItem("role"); if (getRole) { setRole(getRole); }
    let firstName = localStorage.getItem("firstName");
    let lastName = localStorage.getItem("lastName");
    if (firstName && lastName) { setName(firstName + " " + lastName); }
    let email = localStorage.getItem("email"); if (email) { setEmail(email); }
    let getQRCode = localStorage.getItem("qRImage"); setQRCodeImage(getQRCode);
    let getQrUrl = localStorage.getItem("qrUrl"); setQrUrl(getQrUrl);
    let splitUrl = getQrUrl.split('/').pop(); setQrUrlSplit(splitUrl);
  }, []);


  return (
    <div className="col-md-3 col-sm-4">
      <div className="profile-sidebar">
        <p>
          <button
            className="d-md-none float-end navbar-toggler collapsed"
            data-bs-toggle="collapse"
            href="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            type="button"
          >
            <span className="navbar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </p>
        <br />
        <div className="collapse d-md-block" type="button" id="collapseExample" >
          <div className="text-center">
            <div className="ratio ratio-1x1 rounded-circle">
              <div className="flex-all">
                <img src="images/userprofile.jpg" alt="userprofile_image" />
              </div>
            </div>
            <h3 className="break-word">{name}</h3>
            <p className="break-word">{email}</p>
          </div>
          {role !== "renter" && (
            <div className="qrCode-block">
              <div className="qrCode-img img-obj">
                <img src={qRCodeImage} alt="QR code" />
              </div>
              <p>
                {/* <a href={qrUrl} target="_blank" rel="noreferrer" className="active">
                  {qrUrl}
                </a> */}
                <Link className="active" to={`/productlist/${qrUrlSplit}`}>
                  {qrUrl}
                </Link>
              </p>
            </div>
          )}{" "}
        </div>

        <ul>
          <li>
            <Link to={"/info"}
              onClick={() => { dispatch(updateProfileReset()); }}
              className={location.pathname === '/info' ? 'active' : ''}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19">
                <g transform="translate(-3 -2)">
                  <path d="M20,21V19a4,4,0,0,0-4-4H8a4,4,0,0,0-4,4v2" fill="none" strokeWidth="2" />
                  <circle cx="4" cy="4" r="4" transform="translate(8 3)" fill="none" strokeWidth="2" />
                </g>
              </svg>My Profile</Link></li>
          {
            role !== "renter" &&
            <li><Link to={"/host/productlist"} className={location.pathname === '/host/productlist' ? 'active' : props.isTab ? 'active' : ''}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.193 22.11">
              <g transform="translate(-1.904 -0.97)">
                <line x1="9" y1="5.19" transform="translate(7.5 4.21)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
                <path
                  d="M21,16V8a2,2,0,0,0-1-1.73l-7-4a2,2,0,0,0-2,0l-7,4A2,2,0,0,0,3,8v8a2,2,0,0,0,1,1.73l7,4a2,2,0,0,0,2,0l7-4A2,2,0,0,0,21,16Z"
                  fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                <path d="M3.27,6.96,12,12.01l8.73-5.05" fill="none" strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth="2" />
                <line y1="10.08" transform="translate(12 12)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
              </g>
            </svg>Products</Link></li>
          }
          {
            role !== "renter" &&
            <li><Link to={"/host/addproduct"} className={location.pathname === '/host/addproduct' || location.pathname === '/host/editproduct/:id' ? 'active' : ''}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.193 22.11">
              <g transform="translate(-1.904 -0.97)">
                <line x1="9" y1="5.19" transform="translate(7.5 4.21)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
                <path
                  d="M21,16V8a2,2,0,0,0-1-1.73l-7-4a2,2,0,0,0-2,0l-7,4A2,2,0,0,0,3,8v8a2,2,0,0,0,1,1.73l7,4a2,2,0,0,0,2,0l7-4A2,2,0,0,0,21,16Z"
                  fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                <path d="M3.27,6.96,12,12.01l8.73-5.05" fill="none" strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth="2" />
                <line y1="10.08" transform="translate(12 12)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
              </g>
            </svg>Add Products</Link></li>
          }
          <li><Link to={role === "renter" ? "/renter/order" : "/host/order"}
            className={location.pathname === (role === 'renter' ? '/renter/order' : '/host/order') ? 'active' : location.pathname === (role === 'renter' ? '/renter/orderdetails' : '/host/orderdetails') ? 'active'
              : location.pathname === (role === 'renter' ? '/renter/review' : '/host/review') ? 'active' : ''}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14">
              <g transform="translate(-2 -5)">
                <line x2="13" transform="translate(8 6)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
                <line x2="13" transform="translate(8 12)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
                <line x2="13" transform="translate(8 18)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
                <line x2="0.01" transform="translate(3 6)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
                <line x2="0.01" transform="translate(3 12)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
                <line x2="0.01" transform="translate(3 18)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
              </g>
            </svg>Orders </Link></li>
          {/* <li><Link to={role === "renter" ? "/viewpayment" : "/hostpayment"} className={location.pathname === (role === 'renter' ? '/viewpayment' : '/hostpayment') ? 'active' : ''}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 24">
              <g transform="translate(-5)">
                <line y2="22" transform="translate(12 1)" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
                <path d="M17,5H9.5a3.5,3.5,0,0,0,0,7h5a3.5,3.5,0,1,1,0,7H6" fill="none" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2" />
              </g>
            </svg>View Payments </Link></li> */}
        </ul>
      </div>
    </div>
  );
};

export default ProfileCard;
