import { call, put, takeEvery, } from "redux-saga/effects";
import { FORGOT_PASSWORD } from "./actionTypes";
import { forgotPasswordSuccessful, forgotPasswordFailed } from "./action";
import { postForgotPasswordUser } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* userForgotPassword({ payload: { user } }) {

    try {
        const response = yield call(postForgotPasswordUser, user);
        if (response.success === API_CODE.SUCCESS_BOOL) {
            yield put(forgotPasswordSuccessful(response.message));

        } else {
            yield put(forgotPasswordFailed(response.message));
        }
    } catch (error) {
        yield put(forgotPasswordFailed(error));
    }
}


function* ForgotPasswordSaga() {
    yield takeEvery(FORGOT_PASSWORD, userForgotPassword);
}

export default ForgotPasswordSaga;
