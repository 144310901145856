import {
    RENTER_VIEW_PAYMENT,
    RENTER_VIEW_PAYMENT_SUCCESSFUL,
    RENTER_VIEW_PAYMENT_FAILED
} from "./actionTypes";


export const renterViewPayment = user => {
    return {
        type: RENTER_VIEW_PAYMENT,
        payload: { user },
    }
}

export const renterViewPaymentSuccessful = user => {
    return {
        type: RENTER_VIEW_PAYMENT_SUCCESSFUL,
        payload: user,
    }
}

export const renterViewPaymentFailed = user => {
    return {
        type: RENTER_VIEW_PAYMENT_FAILED,
        payload: user,
    }
}