import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useProfile } from "../Hooks/UserHooks"

const AuthProtected = (props) => {
    const { userProfile, loading, token } = useProfile();

    useEffect(() => {
        if (userProfile && !loading && token) {
            setAuthorization(token);
        }
    }, [token, userProfile, loading]);

    /*
     Navigate is un-auth access protected routes via url
     */

    if (!userProfile && loading && !token) {
        return (
            <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
        );
    }

    return <>{props.children}</>;
};

export { AuthProtected };