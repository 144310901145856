export const HOST_DELETE_PROTECTION_PROOF = "HOST_DELETE_PROTECTION_PROOF"
export const HOST_DELETE_PROTECTION_PROOF_SUCCESSFUL = "HOST_DELETE_PROTECTION_PROOF_SUCCESSFUL"
export const HOST_DELETE_PROTECTION_PROOF_FAILED = "HOST_DELETE_PROTECTION_PROOF_FAILED"
export const HOST_DELETE_PROTECTION_PROOF_RESET = "HOST_DELETE_PROTECTION_PROOF_RESET"

export const HOST_DELETE_PROTECTION_PROOF_SINGLE = "HOST_DELETE_PROTECTION_PROOF_SINGLE"
export const HOST_DELETE_PROTECTION_PROOF_SINGLE_SUCCESSFUL = "HOST_DELETE_PROTECTION_PROOF_SINGLE_SUCCESSFUL"
export const HOST_DELETE_PROTECTION_PROOF_SINGLE_FAILED = "HOST_DELETE_PROTECTION_PROOF_SINGLE_FAILED"
export const HOST_DELETE_PROTECTION_PROOF_SINGLE_RESET = "HOST_DELETE_PROTECTION_PROOF_SINGLE_RESET"

