import React, { useState } from "react";
import "./Hostandrender.css";

const Hostandrender = () => {

  const [selectedOption, setSelectedOption] = useState('Host');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <section className="howItWorks-wrapper">
      <div className="bg-darkTheme text-white">
        <div className="container">
          <div className="heading">
            <h2>How it Works</h2>
          </div>
          <div className="row g-4 mb-4">
            <div className="col-auto">
              <label>I am a</label>
            </div>
            <div className="col-auto">
              <div className="form-check form-check-inline form-checkLarge mb-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="Host"
                  checked={selectedOption === 'Host'}
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Host
                </label>
              </div>
              <div className="form-check form-check-inline form-checkLarge mb-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="Renter"
                  checked={selectedOption === 'Renter'}
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Rental
                </label>
              </div>
            </div>
          </div>
          {selectedOption === 'Host' ?
            <div className="row g-xl-4 g-md-5 g-4">
              <div className="col-xl-3 col-sm-6">
                <div className="howItWorks-block">
                  <img src="images/user.svg" alt="Icon" />
                  <h5>Create A Profile</h5>
                  <ul className="listing-wrapper">
                    <li>Set the price</li>
                    <li>Post your face</li>
                    <li>Set location</li>
                    <li>Tell your story</li>
                    <li>Be interesting</li>
                  </ul>
                  <div className="no">1</div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="howItWorks-block">
                  <img src="images/edit.svg" alt="Icon" />
                  <h5>Post Your Product</h5>
                  <ul className="listing-wrapper">
                    <li>Post every outdoor product you own</li>
                    <li>Set your prices with a competitive analysis</li>
                    <li>Get Featured</li>
                    <li>Create Bundles</li>
                  </ul>
                  <div className="no">2</div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="howItWorks-block">
                  <img src="images/tag.svg" alt="Icon" />
                  <h5>Get Your Product Rented</h5>
                  <ul className="listing-wrapper">
                    <li>Promote</li>
                    <li>Manage Interests</li>
                    <li>Arrange pickup/Drop off</li>
                    <li>Rent out as much as you can at a time</li>
                    <li>Fully Insured</li>
                  </ul>
                  <div className="no">3</div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="howItWorks-block">
                  <img src="images/money.svg" alt="Icon" />
                  <h5>Make Money</h5>
                  <ul className="listing-wrapper">
                    <li>Deposit options</li>
                    <li>Make money from what you are not using</li>
                  </ul>
                  <div className="no">4</div>
                </div>
              </div>
            </div>
            :
            <div className="row g-xl-4 g-md-5 g-4">
              <div className="col-xl-3 col-sm-6">
                <div className="howItWorks-block">
                  <img src="images/user.svg" alt="Icon" />
                  <h5>Create an account</h5>
                  <ul className="listing-wrapper">
                    <li>Securely enter your information</li>
                    <li>Get verified</li>
                    <li>Personalize your profile</li>
                  </ul>
                  <div className="no">1</div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="howItWorks-block">
                  <img src="images/edit.svg" alt="Icon" />
                  <h5>Search for a rental</h5>
                  <ul className="listing-wrapper">
                    <li>Select your rental location</li>
                    <li>Find the type of product you want</li>
                    <li className="render-wrap">Filter by any keyword, city, and date</li>
                  </ul>
                  <div className="no">2</div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="howItWorks-block">
                  <img src="images/tag.svg" alt="Icon" />
                  <h5>Book your rental</h5>
                  <ul className="listing-wrapper">
                    <li>Select the dates you want to book</li>
                    <li>Select “Find Now”</li>
                    <li>Find all you need for your outdoor experience</li>
                    <li>Bundle your rentals to create your custom trip</li>
                    <li className="render-wrap">Follow the easy checkout process</li>
                  </ul>
                  <div className="no">3</div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="howItWorks-block">
                  <img src="images/cloud-thinking.svg" className="create_memories_img" alt="Create_Memories" />
                  <h5>Create Memories</h5>
                  <ul className="listing-wrapper">
                    <li>Enjoy the full experience</li>
                    <li>Explore everything the outdoors has to offer</li>
                    <li>Be safe</li>
                  </ul>
                  <div className="no">4</div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </section>
  );
};

export default Hostandrender;
