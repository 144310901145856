import {
    ADD_PROMO_CODE_HOST_RELATION,
    ADD_PROMO_CODE_HOST_RELATION_SUCCESSFUL,
    ADD_PROMO_CODE_HOST_RELATION_FAILED,
    ADD_PROMO_CODE_HOST_RELATION_RESET
} from "./actionTypes";


export const addPromoCodeAndHost = user => {
    return {
        type: ADD_PROMO_CODE_HOST_RELATION,
        payload: { user },
    }
}

export const addPromoCodeAndHostSuccessful = user => {
    return {
        type: ADD_PROMO_CODE_HOST_RELATION_SUCCESSFUL,
        payload: user,
    }
}

export const addPromoCodeAndHostFailed = user => {
    return {
        type: ADD_PROMO_CODE_HOST_RELATION_FAILED,
        payload: user,
    }
}

export const addPromoCodeAndHostReset = user => {
    return {
        type: ADD_PROMO_CODE_HOST_RELATION_RESET,
        payload: user,
    }
}
