import React from "react";

//*Auth
import Login from '../Components/Login/Login';
import Register from '../Components/Register/Register';
import ForgotPassword from "../Components/Forgot Password/forgotPassword";
import HostDirectLogin from "../Components/Basic/HostDirectLogin";
import OrderSuccess from "../Components/Basic/OrderSuccess";
import OrderCancel from "../Components/Basic/OrderCancel";

//*common
import Home from "../Components/Home/Home";
import Contact from "../Components/Home/Contact/Contact";
import Profile from '../Components/Profile/Profile';
import Cart from '../Components/Pages/Cart';
import Aboutepage from '../Components/Home/About/Aboutepage';
import PrivacyPolicy from "../Components/Home/PrivacyPolicy/PrivacyPolicy";
import OrderDetails from "../Components/Pages/orderDetails";

import UserNotFound from "../Components/userNotFound/userNotFound";
import PageNotFound from "../Components/pageNotFound/pageNotFound";

//*Host
import HostAddProduct from '../Components/Pages/HostAddProduct';
import HostEditProduct from '../Components/Pages/HostEditProduct';
import HostProductList from '../Components/Pages/HostProductList';
import HostReviewRender from '../Components/Pages/HostReviewRender';
import HostViewOrder from '../Components/Pages/HostViewOrder';
import HostViewPayment from '../Components/Pages/HostViewPayment';

//*Renter
import RenterProductDetails from '../Components/Pages/Renter/ProductDetails/ProductDetails';
import RenterProductList from '../Components/Pages/Renter/Products/ProductList';
import RenterPaymentDetails from '../Components/Pages/Renter/PaymentDetails/PaymentDetails';
import RenterViewOrder from "../Components/Pages/Renter/OrderList/RenterViewOrder";
import RenterOrderDetails from "../Components/Pages/Renter/OrderDetail/renterOrderDetails";
import RenterReview from "../Components/Pages/Renter/Review/renterReviewRender";

const authProtectedRoutes = [

    { path: "/info", component: <Profile /> },

    //*renter
    { path: "/renter/orderdetails", component: <RenterOrderDetails /> },
    { path: "/renter/order", component: <RenterViewOrder /> },
    { path: "/renter/review", component: <RenterReview /> },
    // { path: "/viewpayment", component: <RenterPaymentDetails /> },

    //*host
    { path: "/host/orderdetails", component: <OrderDetails /> },
    { path: "/host/addproduct", component: <HostAddProduct /> },
    { path: "/host/editproduct/:id", component: <HostEditProduct /> },
    { path: "/host/productlist", component: <HostProductList /> },
    { path: "/host/review", component: <HostReviewRender /> },
    { path: "/host/order", component: <HostViewOrder /> },
    // { path: "/hostpayment", component: <HostViewPayment /> },
];

const nonAuthProtectedRoutes = [
    { path: "/login", component: <Login /> },
    { path: "/forgotpassword", component: <ForgotPassword /> },
    { path: "/register", component: <Register /> },
];

const publicRoutes = [
    { path: "/home", component: <Home /> },
    { path: "/about", component: <Aboutepage /> },
    { path: "/contact", component: <Contact /> },
    { path: "/productlist", component: <RenterProductList /> },
    { path: "/productlist/:hid", component: <RenterProductList /> },
    { path: "/productdetails/:id", component: <RenterProductDetails /> },
    { path: "/usernotfound", component: <UserNotFound /> },
    { path: "/cart", component: <Cart /> },
    { path: "/privacypolicy", component: <PrivacyPolicy /> },
];

const pageNotFoundRoutes = [
    { path: "*", component: <PageNotFound /> },
    { path: "/host_direct_login/:id", component: <HostDirectLogin /> },
    { path: "/order_success", component: <OrderSuccess /> },
    { path: "/order_cancel", component: <OrderCancel /> },
];


export { authProtectedRoutes, publicRoutes, pageNotFoundRoutes, nonAuthProtectedRoutes };

