import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
    SIGN_RENTER_AGREEMENT,
} from "./actionTypes";
import { signRenterAgreementSuccessful, signRenterAgreementFailed, } from "./action";
import { postUpdateOrderStatusSignRenterAgreement, } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* getAgreementDetails({ payload: { user } }) {

    try {
        const response = yield call(postUpdateOrderStatusSignRenterAgreement, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(signRenterAgreementSuccessful(response.data));
        } else {
            yield put(signRenterAgreementFailed(response.message));
        }
    } catch (error) {
        yield put(signRenterAgreementFailed(error));
    }
}

export function* watchOnGetRenterDetails() {
    yield takeEvery(SIGN_RENTER_AGREEMENT, getAgreementDetails);
}

function* OrderRenterAgreementSaga() {

    yield all([
        fork(watchOnGetRenterDetails),
    ]);
}

export default OrderRenterAgreementSaga;
