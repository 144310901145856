import {
    HOST_ORDER_LIST,
    HOST_ORDER_LIST_SUCCESSFUL,
    HOST_ORDER_LIST_FAILED
} from "./actionTypes";


export const hostOrderList = user => {
    return {
        type: HOST_ORDER_LIST,
        payload: { user },
    }
}

export const hostOrderListSuccessful = user => {
    return {
        type: HOST_ORDER_LIST_SUCCESSFUL,
        payload: user,
    }
}

export const hostOrderListFailed = user => {
    return {
        type: HOST_ORDER_LIST_FAILED,
        payload: user,
    }
}