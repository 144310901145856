import {
  PRODUCT_DETAILS,
  PRODUCT_DETAILS_SUCCESSFUL,
  PRODUCT_DETAILS_FAILED,
  PRODUCT_DETAILS_RESET
} from "./actionTypes";

export const productDetail = (user) => {
  return {
    type: PRODUCT_DETAILS,
    payload:  {user} ,
  };
};

export const productDetailSuccessful = (user) => {
  return {
    type: PRODUCT_DETAILS_SUCCESSFUL,
    payload: user,
  };
};

export const productDetailFaield = (user) => {
  return {
    type: PRODUCT_DETAILS_FAILED,
    payload: user,
  };
};

export const productDetailReset = (user) => {
  return {
    type: PRODUCT_DETAILS_RESET,
    payload: user,
  };
};
