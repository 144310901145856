import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { RENTER_ADD_REVIEW } from "./actionTypes";
import { renterAddReviewDetailsSuccessful, renterAddReviewDetailsFailed, } from "./action";
import { postRenterProductReview } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* renterAddProductReview({ payload: { user } }) {
    try {
        const response = yield call(postRenterProductReview, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(renterAddReviewDetailsSuccessful(response.data));
        } else {
            yield put(renterAddReviewDetailsFailed(response.message));
        }
    } catch (error) {
        yield put(renterAddReviewDetailsFailed(error));
    }
}

export function* watchOnRenterAddReview() {
    yield takeEvery(RENTER_ADD_REVIEW, renterAddProductReview);
}

function* RenterAddReviewSaga() {

    yield all([
        fork(watchOnRenterAddReview),
    ]);
}

export default RenterAddReviewSaga;
