import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

function EnableConfirmationModal({ isOpen, onClose, onDelete, productStatus }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Enable Confirmation"
      style={{
        content: {
          width: "500px",   
          height: "250px",
          top:"50%",
          left:"50%",
          transform:"translate(-50%,-50%)",
        },
      }}
    >
      <h2 className='my-4'>{productStatus === "enabled" ? "Disable Product" : "Enable Product"}</h2>
      <p className='my-4'>{productStatus === "enabled" ? "Are you sure you want to disable this Product?" : "Are you sure you want to enable this Product?"}</p>
      <button className='btns btn-lg btn-danger mx-2' onClick={onDelete}>{productStatus === "enabled" ? "Disable" : "Enable"}</button>
      <button className='btns btn-lg btn-secondary' onClick={onClose}>Cancel</button>
    </Modal>
  );
}

export default EnableConfirmationModal;
