import {
    CANCEL_ORDER,
    CANCEL_ORDER_SUCCESSFUL,
    CANCEL_ORDER_FAILED
} from "./actionTypes";


export const cancelOrder = user => {
    return {
        type: CANCEL_ORDER,
        payload: { user },
    }
}

export const cancelOrderSuccessful = user => {
    return {
        type: CANCEL_ORDER_SUCCESSFUL,
        payload: user,
    }
}

export const cancelOrderFailed = user => {
    return {
        type: CANCEL_ORDER_FAILED,
        payload: user,
    }
}