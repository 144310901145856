import React from "react";

const RenterBannerslider = (props) => {
  return (
    <section className="innerBanner-wrapper">
      <div className="innerBanner-img img-obj cover">
        <img src="images/header.png" alt="header_image" />
      </div>
      <div className="container h-100 z1 flex-all">
        <h1>{props?.headerName}</h1>
      </div>
    </section>
  );
};

export default RenterBannerslider;
