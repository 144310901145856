import {
    GET_SITE_SETTING_DETAILS,
    GET_SITE_SETTING_DETAILS_SUCCESSFUL,
    GET_SITE_SETTING_DETAILS_FAILED,
} from "./actionTypes";

const initialState = {
    errorMessage: null,
    loading: false,
    user: null,
    success: false,
    error: false,
};

const SiteSettings = (state = initialState, action) => {

    switch (action.type) {
        case GET_SITE_SETTING_DETAILS:
            state = {
                ...state,
                loading: true,
                errorMessage: null,
            };
            break;
        case GET_SITE_SETTING_DETAILS_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                user: action.payload,
                success: true,
                errorMessage: null,
                error: false,
            };
            break;
        case GET_SITE_SETTING_DETAILS_FAILED:
            state = {
                ...state,
                user: null,
                loading: false,
                errorMessage: action.payload,
                error: true,
                success: false,

            };
            break;

        default:
            return state;
    }
    return state;
};

export default SiteSettings;
