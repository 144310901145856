import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { GET_PROMO_CODE_DETAILS } from "./actionTypes";
import { getPromoCodeDetailsSuccessful, getPromoCodeDetailsFailed, } from "./action";
import { promoCodeDetails } from "../../helpers/fakebackend_helper"
import { API_CODE } from '../../helpers/enum'


function* getOrderPromoCodeDetails({ payload: { user } }) {

    try {
        const response = yield call(promoCodeDetails, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(getPromoCodeDetailsSuccessful(response.data));
        } else {
            yield put(getPromoCodeDetailsFailed(response.message));
        }
    } catch (error) {
        yield put(getPromoCodeDetailsFailed(error));
    }
}

export function* watchOnPromoCodeDetails() {
    yield takeEvery(GET_PROMO_CODE_DETAILS, getOrderPromoCodeDetails);
}

function* PromoCodeSaga() {

    yield all([
        fork(watchOnPromoCodeDetails),
    ]);
}

export default PromoCodeSaga;
