import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { ADD_PROMO_CODE_HOST_RELATION } from "./actionTypes";
import {
    addPromoCodeAndHostSuccessful,
    addPromoCodeAndHostFailed
} from "./action";
import { addPromoCode, addPromoCodeAndHostRelation } from "../../helpers/fakebackend_helper"
import { API_CODE } from '../../helpers/enum'

function* addPromoCodeAndHostDetails({ payload: { user } }) {
    try {
        const response = yield call(addPromoCodeAndHostRelation, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(addPromoCodeAndHostSuccessful(response.data));
        } else {
            yield put(addPromoCodeAndHostFailed(response.message));
        }
    } catch (error) {
        yield put(addPromoCodeAndHostFailed(error));
    }
}

export function* watchOnAddPromoCodeDetails() {
    yield takeEvery(ADD_PROMO_CODE_HOST_RELATION, addPromoCodeAndHostDetails);
}

function* AddPromoCodeSaga() {

    yield all([
        fork(watchOnAddPromoCodeDetails),
    ]);
}

export default AddPromoCodeSaga;
