import {
    GET_CITY,
    GET_CITY_SUCCESSFUL,
    GET_CITY_FAILED,
    GET_CITY_RESET,
} from "./actionTypes";

const initialState = {
    getCityErrorMessage: null,
    getCityLoading: false,
    getCityData: null,
    getCitySuccess: false,
    getCityError: false,
};


const GetCityReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_CITY:
            state = {
                ...state,
                getCityLoading: true,
                getCityErrorMessage: null,
            };
            break;
        case GET_CITY_SUCCESSFUL:
            state = {
                ...state,
                getCityLoading: false,
                getCityData: action.payload,
                getCitySuccess: true,
                getCityErrorMessage: null,
                getCityError: false,
            };
            break;
        case GET_CITY_FAILED:
            state = {
                ...state,
                getCityData: null,
                getCityLoading: false,
                getCityErrorMessage: action.payload,
                getCityError: true,
                getCitySuccess: false,
            };
            break;
        case GET_CITY_RESET:
            state = {
                ...state,
                getCityErrorMessage: null,
                getCityLoading: false,
                getCityData: null,
                getCitySuccess: false,
                getCityError: false,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default GetCityReducer;
