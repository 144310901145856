import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { HOST_GET_PROTECTION_PROOF } from "./actionTypes";
import { hostGetProtectionProofSuccessful, hostGetProtectionProofFailed, } from "./action";
import { postGetListHostProtectionProof } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* hostGetProtectionProof({ payload: { user } }) {

    try {
        const response = yield call(postGetListHostProtectionProof, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(hostGetProtectionProofSuccessful(response.data));
        } else {
            yield put(hostGetProtectionProofFailed(response.message));
        }
    } catch (error) {
        yield put(hostGetProtectionProofFailed(error));
    }
}

export function* watchOnHostGetProtectionProof() {
    yield takeEvery(HOST_GET_PROTECTION_PROOF, hostGetProtectionProof);
}

function* HostProtectionProofSaga() {

    yield all([
        fork(watchOnHostGetProtectionProof),
    ]);
}

export default HostProtectionProofSaga;
