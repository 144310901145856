import {
  CONTACT_US,
  CONTACT_US_SUCCESSFUL,
  CONTACT_US_FAILED,
  CONTACT_US_RESET,
} from "./actionTypes";

const initialState = {
  contactUsErrorMsg: null,
  contactUsLoading: false,
  contactUSData: null,
  contactUsSuccess: false,
  contactUsError: false,
};

const ContactUsReducer = (state = initialState, action) => {

  switch (action.type) {
    case CONTACT_US:
      state = {
        ...state,
        contactUsLoading: true,
        contactUsErrorMsg: null,
        contactUSMessage: null,
        contactUSData: null,
        contactUsSuccess: false,
        contactUsError: false,
      };
      break;

    case CONTACT_US_SUCCESSFUL:

      state = {
        ...state,
        contactUsLoading: false,
        contactUsErrorMsg: null,
        contactUSData: action.payload,
        contactUsSuccess: true,
        contactUsError: false,
      };
      break;

    case CONTACT_US_FAILED:
      state = {
        ...state,
        contactUsLoading: false,
        contactUsErrorMsg: action.payload,
        contactUSData: null,
        contactUsSuccess: false,
        contactUsError: true,
      };
      break;

    case CONTACT_US_RESET:
      state = {
        ...state,
        contactUsErrorMsg: null,
        contactUsLoading: false,
        contactUSData: null,
        contactUsSuccess: false,
        contactUsError: false,
      };
      break;

    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default ContactUsReducer;
