import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import VerticalLayout from '../Layouts/index'
import { authProtectedRoutes, publicRoutes, pageNotFoundRoutes, nonAuthProtectedRoutes } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";
import { NonAuthProtected } from './NonAuthProtected';


export default function RouteComponent() {

    return (
        <React.Fragment>
            <div>

                <Routes>
                    <Route>
                        {pageNotFoundRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={route.component}
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Route>

                    <Route>
                        {authProtectedRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={
                                    <AuthProtected>
                                        <VerticalLayout>{route.component}</VerticalLayout>
                                    </AuthProtected>
                                }
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Route>

                    <Route>
                        {nonAuthProtectedRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={
                                    <NonAuthProtected>
                                        <VerticalLayout>{route.component}</VerticalLayout>
                                    </NonAuthProtected>
                                }
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Route>

                    <Route>
                        {publicRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={route.component}
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Route>

                    <Route path="/" element={<Navigate to="/home" />} />
                </Routes>
            </div>

        </React.Fragment>
    )
}




