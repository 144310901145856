import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hostListProduct } from "../../Redux/host_redux/actions";
import Slider from "react-slick";
import './Product.css';


//*images 
import UTVIMG from '../Home/images/UTV.jpg'
import AVTIMG from '../Home/images/ATV.jpg'
import SnowmobilesIMG from '../Home/images/Snowmobile .jpg'
import BoatsIMG from '../Home/images/Boat .jpg'
import WaveRunnersIMG from '../Home/images/Wave_runner.jpg'
import CampersRvsIMG from '../Home/images/Camper_RV.jpg'
import EBikesIMG from '../Home/images/E-bike .jpg'
import TrucksIMG from '../Home/images/Truck.jpg'
import TrailersIMG from '../Home/images/trailer.jpg'
import OverlandIMG from '../Home/images/Overland .jpg'
import SUVIMG from '../Home/images/SUV.jpg'
import dirtBike from '../Home/images/dirtBike.png'

const Product = () => {

  const dispatch = useDispatch();

  const vehiclesList = [
    {
      label: "UTV’s",
      value: "Utv’s",
      img: UTVIMG,
    },
    {
      label: "ATV’s",
      value: "Atv’s",
      img: AVTIMG,
    },
    {
      label: "Campers/RVs",
      value: "Campers/Rv’s",
      img: CampersRvsIMG,
    },
    {
      label: "WaveRunners",
      value: "Wave runners",
      img: WaveRunnersIMG,
    },
    {
      label: "Boats",
      value: "Boats",
      img: BoatsIMG,
    },
    {
      label: "Trailers",
      value: "Trailers",
      img: TrailersIMG,
    },
    {
      label: "Trucks",
      value: "Trucks",
      img: TrucksIMG,
    },
    {
      label: "SUVs",
      value: "SUV",
      img: SUVIMG,
    },
    {
      label: "E-Bikes",
      value: "EBikes",
      img: EBikesIMG,
    },
    {
      label: "Snowmobiles",
      value: "Snowmobiles",
      img: SnowmobilesIMG,
    },
    {
      label: "Overland",
      value: "Overland",
      img: OverlandIMG,
    },
    {
      label: "Dirt Bike’s",
      value: "DirtBikes",
      img: dirtBike,
    },
  ];

  useEffect(() => {
    dispatch(hostListProduct({ page: 1 }));
  }, []);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        },
      },
      {
        breakpoint: 1029,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        },
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        },
      },
    ],
  };

  const navigate = useNavigate();
  const handleSearchSubmit = (value) => {
    const filterData = {
      page: 1,
      search_string: value,
      product_location: "",
      start_date: "",
      end_date: "",
    };
    navigate("/productlist", { state: { filterData } });
  };

  return (
    <div className="products-wrapper py-wrapper bg-lightTheme overflow-hidden">
      <div className="container">
        <div className="heading">
          <h2>Products</h2>
        </div>
        <div className="products-slider slider">
          <Slider {...settings}>
            {vehiclesList &&
              vehiclesList?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="products-block bg-white" >
                      <div className="ratio cover">
                        <img
                          className="slider_img"
                          src={item?.img}
                          alt={"My Image_" + index}
                          onError={event => {
                            event.target.src = '/images/default_image.webp'
                            event.onerror = null
                          }}
                        />
                      </div>
                      <div className="text-center">
                        <h5>{item?.label}</h5>
                        {/* <div className="rating">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <span>(299)</span>
                        </div> */}
                        {/* <h4>
                          ${item.price_per_day}
                          <span>/ Day</span>
                        </h4> */}
                        <button onClick={() => handleSearchSubmit(item?.value)} className="btn btn-green">
                          <span>FIND NOW</span>
                        </button>
                      </div>
                    </div>
                  </ React.Fragment >
                );
              })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Product;
