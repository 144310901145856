import {
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESSFUL,
    FORGOT_PASSWORD_FAILED,
    FORGOT_PASSWORD_RESET
} from "./actionTypes";


export const forgotPassword = user => {
    return {
        type: FORGOT_PASSWORD,
        payload: { user },
    }
}

export const forgotPasswordSuccessful = user => {
    return {
        type: FORGOT_PASSWORD_SUCCESSFUL,
        payload: user,
    }
}

export const forgotPasswordFailed = user => {
    return {
        type: FORGOT_PASSWORD_FAILED,
        payload: user,
    }
}

export const forgotPasswordReset = user => {
    return {
        type: FORGOT_PASSWORD_RESET,
        payload: user,
    }
}