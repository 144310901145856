import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
    HOST_DELETE_PROTECTION_PROOF,
    HOST_DELETE_PROTECTION_PROOF_SINGLE
} from "./actionTypes";
import {
    hostDeleteProtectionProofSuccessful,
    hostDeleteProtectionProofFailed,
    hostDeleteProtectionProofSingleSuccessful,
    hostDeleteProtectionProofSingleFailed
} from "./action";
import {
    postDeleteHostProtectionProof,
    postDeleteHostProtectionProofSingle
} from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* hostDeleteProtectionProof({ payload: { user } }) {

    try {
        const response = yield call(postDeleteHostProtectionProof, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(hostDeleteProtectionProofSuccessful(response.data));
        } else {
            yield put(hostDeleteProtectionProofFailed(response.message));
        }
    } catch (error) {
        yield put(hostDeleteProtectionProofFailed(error));
    }
}

function* hostDeleteProtectionProofSingle({ payload: { user } }) {
    try {
        const response = yield call(postDeleteHostProtectionProofSingle, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(hostDeleteProtectionProofSingleSuccessful(response.data));
        } else {
            yield put(hostDeleteProtectionProofSingleFailed(response.message));
        }
    } catch (error) {
        yield put(hostDeleteProtectionProofSingleFailed(error));
    }
}

export function* watchOnHostDeleteProtectionProof() {
    yield takeEvery(HOST_DELETE_PROTECTION_PROOF, hostDeleteProtectionProof);
}

export function* watchOnHostDeleteProtectionProofSingle() {
    yield takeEvery(HOST_DELETE_PROTECTION_PROOF_SINGLE, hostDeleteProtectionProofSingle);
}

function* HostDeleteProtectionProofSaga() {

    yield all([
        fork(watchOnHostDeleteProtectionProof),
        fork(watchOnHostDeleteProtectionProofSingle),
    ]);
}

export default HostDeleteProtectionProofSaga;
