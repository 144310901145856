import { call, put, takeEvery, } from "redux-saga/effects";
import { RENTER_ORDER_LIST } from "./actionTypes";
import { renterOrderListSuccessful, renterOrderListFailed } from "./action";
import { getRenterOrderList } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'
import { ConvertResponseForOrderList } from '../../../helpers/commonfunction'

function* renterOrderDetails({ payload: { user } }) {

    try {
        const response = yield call(getRenterOrderList, user);
        var OrderListArray = ConvertResponseForOrderList(response.data);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(renterOrderListSuccessful(OrderListArray));
        } else {
            yield put(renterOrderListFailed(response.message));
        }
    } catch (error) {
        yield put(renterOrderListFailed(error));
    }
}

function* RenterOrderSaga() {
    yield takeEvery(RENTER_ORDER_LIST, renterOrderDetails);
}

export default RenterOrderSaga;
