import {
    GET_PROFILE_DETAILS,
    GET_PROFILE_DETAILS_SUCCESSFUL,
    GET_PROFILE_DETAILS_FAILED,
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESSFUL,
    UPDATE_PROFILE_FAILED,
    UPDATE_KYC,
    UPDATE_KYC_SUCCESSFUL,
    UPDATE_KYC_FAILED,
    UPDATE_PROFILE_RESET
} from "./actionTypes";


export const getUserDetails = user => {
    return {
        type: GET_PROFILE_DETAILS,
        payload: { user },
    }
}

export const getUserDetailsSuccessful = user => {
    return {
        type: GET_PROFILE_DETAILS_SUCCESSFUL,
        payload: user,
    }
}

export const getUserDetailsFailed = user => {
    return {
        type: GET_PROFILE_DETAILS_FAILED,
        payload: user,
    }
}

export const updateProfile = user => {
    return {
        type: UPDATE_PROFILE,
        payload: { user },
    }
}

export const updateProfileSuccessful = user => {
    return {
        type: UPDATE_PROFILE_SUCCESSFUL,
        payload: user,
    }
}

export const updateProfileFailed = user => {
    return {
        type: UPDATE_PROFILE_FAILED,
        payload: user,
    }
}

export const updateProfileReset = user => {
    return {
        type: UPDATE_PROFILE_RESET,
        payload: user,
    }
}

export const updateKYC = user => {
    return {
        type: UPDATE_KYC,
        payload: { user },
    }
}

export const updateKYCSuccessful = user => {
    return {
        type: UPDATE_KYC_SUCCESSFUL,
        payload: user,
    }
}

export const updateKYCFailed = user => {
    return {
        type: UPDATE_KYC_FAILED,
        payload: user,
    }
}