import React from "react";
import Hostandrender from "./Hostandrender";
import About from "../About/About";
import Product from "../Products/Product";
import Calculator from "../Products/Calculator";
import Contact from "./Contact/Contact";
import Bannerslider from "./Bannerslider";
import { getSiteSettingDetails } from "../../Redux/actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";

const Home = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSiteSettingDetails());
  }, []);

  const getVersion = () => {
    return 'V1.3.0'
  }

  console.log('Frontend Version:', getVersion())

  return (
    <div>
      <Header />
      <Bannerslider />
      <Hostandrender />
      <About />
      <Product />
      <Calculator />
      <Contact fromHome={true} />
      <Footer />
    </div>
  );
};

export default Home;
