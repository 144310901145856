import {
  ADD_LIST_HOST_PRODUCT,
  ADD_LIST_HOST_PRODUCT_FAILED,
  ADD_LIST_HOST_PRODUCT_SUCCESSFUL,

  DELETE_LIST_HOST_PRODUCT,
  DELETE_LIST_HOST_PRODUCT_FAILED,
  DELETE_LIST_HOST_PRODUCT_SUCCESSFUL,

  EDIT_IMAGE_HOST_PRODUCT,
  EDIT_IMAGE_HOST_PRODUCT_FAILED,
  EDIT_IMAGE_HOST_PRODUCT_SUCCESSFUL,

  EDIT_LIST_HOST_PRODUCT,
  EDIT_LIST_HOST_PRODUCT_FAILED,
  EDIT_LIST_HOST_PRODUCT_SUCCESSFUL,

  GET_LIST_HOST_PRODUCT,
  GET_LIST_HOST_PRODUCT_FAILED,
  GET_LIST_HOST_PRODUCT_SUCCESSFUL,

  LIST_HOST_PRODUCT,
  LIST_HOST_PRODUCT_FAILED,
  LIST_HOST_PRODUCT_SUCCESSFUL,

  LIST_LOCATION_HOST_PRODUCT,

  LIST_SEARCH_HOST_PRODUCT,
  LIST_SORTBY_HOST_PRODUCT,

  ENABLE_LIST_HOST_PRODUCT,
  ENABLE_LIST_HOST_PRODUCT_SUCCESSFUL,
  ENABLE_LIST_HOST_PRODUCT_FAILED

} from "./actionTypes";

const initialState = {
  listProductError: null,
  loading: false,
  user: null,
  success: false,
  error: false,

  isAddSuccess: false,
  isAddError: false,
  isAddSuccessMsg: null,
  isAddErrorMsg: null,

  isGetSuccess: false,
  isGetError: false,
  isGetSuccessMsg: null,
  isGetErrorMsg: null,

  isEditSuccessMsg: null,
  isEditErrorMsg: null,
  isEditSuccess: false,
  isEditError: false,

  isDeleteSuccess: false,
  isDeleteError: false,
  isDeleteSuccessMsg: null,
  isDeleteErrorMsg: null,

  isProductEnableSuccess: false,
  isProductEnableError: false,
  isProductEnableSuccessMsg: null,
  isProductEnableErrorMsg: null,
};

const HostProductCrud = (state = initialState, action) => {
  switch (action.type) {
    case LIST_HOST_PRODUCT:
      state = {
        ...state,
        loading: true,
        listProductError: null,
        user: null,
        success: false,
        error: false,

        isAddSuccess: false,
        isAddError: false,
        isAddSuccessMsg: null,
        isAddErrorMsg: null,

        isGetSuccess: false,
        isGetError: false,
        isGetSuccessMsg: null,
        isGetErrorMsg: null,

        isEditSuccessMsg: null,
        isEditErrorMsg: null,
        isEditSuccess: false,
        isEditError: false,

        isDeleteSuccess: false,
        isDeleteError: false,
        isDeleteSuccessMsg: null,
        isDeleteErrorMsg: null,

        isProductEnableSuccess: false,
        isProductEnableError: false,
        isProductEnableSuccessMsg: null,
        isProductEnableErrorMsg: null,
      };
      break;

    case LIST_HOST_PRODUCT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        success: true,
        listProductError: true,
        error: false,
      };
      break;

    case LIST_HOST_PRODUCT_FAILED:
      state = {
        ...state,
        loading: false,
        user: null,
        listProductError: action.payload,
        error: true,
        success: false,
      };
      break;

    case ADD_LIST_HOST_PRODUCT:
      state = {
        ...state,
        loading: true,
        isAddSuccessMsg: null,
        isAddErrorMsg: null,
        isAddSuccess: false,
        isAddError: false,
      };
      break;

    case ADD_LIST_HOST_PRODUCT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        isAddSuccessMsg: action.payload,
        isAddSuccess: true,
        isAddError: false,
        isAddErrorMsg: null,
      };
      break;

    case ADD_LIST_HOST_PRODUCT_FAILED:
      state = {
        ...state,
        loading: false,
        isAddSuccessMsg: null,
        isAddSuccess: false,
        isAddError: action.payload,
        isAddErrorMsg: true,
      };
      break;
    // GET_PRODUCT_DETAIL
    case GET_LIST_HOST_PRODUCT:
      state = {
        ...state,
        loading: true,
        isGetSuccessMsg: null,
        isGetErrorMsg: null,
        isGetSuccess: false,
        isGetError: false,
      };
      break;

    case GET_LIST_HOST_PRODUCT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        isGetSuccessMsg: action.payload,
        isGetSuccess: true,
        isGetError: false,
        isGetErrorMsg: null,
      };
      break;

    case GET_LIST_HOST_PRODUCT_FAILED:
      state = {
        ...state,
        loading: false,
        isGetSuccessMsg: null,
        isGetSuccess: false,
        isGetError: action.payload,
        isGetErrorMsg: true,
      };
      break;

    //EDIT_HOST_PRODUCT
    case EDIT_LIST_HOST_PRODUCT:
      state = {
        ...state,
        loading: true,
        isEditSuccessMsg: null,
        isEditErrorMsg: null,
        isEditSuccess: false,
        isEditError: false,
      };
      break;

    case EDIT_LIST_HOST_PRODUCT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        isEditSuccessMsg: action.payload,
        isEditSuccess: true,
        isEditError: false,
        isEditErrorMsg: null,
      };
      break;

    case EDIT_LIST_HOST_PRODUCT_FAILED:
      state = {
        ...state,
        loading: false,
        isEditSuccessMsg: null,
        isEditSuccess: false,
        isEditError: action.payload,
        isEditErrorMsg: true,
      };
      break;

    //EDIT_IMAGE_HOST_PRODUCT
    case EDIT_IMAGE_HOST_PRODUCT:
      state = {
        ...state,
        loading: true,
        isEditImageSuccessMsg: null,
        isEditImageErrorMsg: null,
        isEditImageSuccess: false,
        isEditImageError: false,
      };
      break;

    case EDIT_IMAGE_HOST_PRODUCT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        isEditImageSuccessMsg: action.payload,
        isEditImageSuccess: true,
        isEditImageError: false,
        isEditImageErrorMsg: null,
      };
      break;

    case EDIT_IMAGE_HOST_PRODUCT_FAILED:
      state = {
        ...state,
        loading: false,
        isEditImageSuccessMsg: null,
        isEditImageSuccess: false,
        isEditImageError: action.payload,
        isEditImageErrorMsg: true,
      };
      break;

    // DELETE_HOST_PRODUCT
    case DELETE_LIST_HOST_PRODUCT:
      state = {
        ...state,
        loading: true,
        isDeleteSuccessMsg: null,
        isDeleteErrorMsg: null,
        isDeleteSuccess: false,
        isDeleteError: false,
      };
      break;

    case DELETE_LIST_HOST_PRODUCT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        isDeleteSuccessMsg: action.payload,
        isDeleteSuccess: true,
        isDeleteError: false,
        isDeleteErrorMsg: null,
      };
      break;

    case DELETE_LIST_HOST_PRODUCT_FAILED:
      state = {
        ...state,
        loading: false,
        isAddSuccessMsg: null,
        isAddSuccess: false,
        isAddError: action.payload,
        isAddErrorMsg: true,
      };
      break;

    //LIST_SEARCH_HOST_PRODUCT
    case LIST_SEARCH_HOST_PRODUCT:
      state = {
        ...state,
        loading: true,
        listProductError: null,
      };
      break;

    case LIST_LOCATION_HOST_PRODUCT:
      state = {
        ...state,
        loading: true,
        listProductError: null,
      };
      break;

    case LIST_SORTBY_HOST_PRODUCT:
      state = {
        ...state,
        loading: true,
        listProductError: null,
      };
      break;

    case ENABLE_LIST_HOST_PRODUCT:
      state = {
        ...state,
        loading: true,
        isProductEnableSuccess: false,
        isProductEnableError: false,
        isProductEnableSuccessMsg: null,
        isProductEnableErrorMsg: null,
      };
      break;

    case ENABLE_LIST_HOST_PRODUCT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        isProductEnableSuccess: true,
        isProductEnableError: false,
        isProductEnableSuccessMsg: action.payload,
        isProductEnableErrorMsg: null,
      };
      break;

    case ENABLE_LIST_HOST_PRODUCT_FAILED:
      state = {
        ...state,
        loading: false,
        isProductEnableSuccess: false,
        isProductEnableError: true,
        isProductEnableSuccessMsg: null,
        isProductEnableErrorMsg: action.payload,
      };
      break;

    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default HostProductCrud;
