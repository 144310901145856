import {
  DELETE_LIST_HOST_PRODUCT,
  DELETE_LIST_HOST_PRODUCT_FAILED,
  DELETE_LIST_HOST_PRODUCT_SUCCESSFUL,

  GET_LIST_HOST_PRODUCT,
  GET_LIST_HOST_PRODUCT_SUCCESSFUL,
  GET_LIST_HOST_PRODUCT_FAILED,

  EDIT_LIST_HOST_PRODUCT,
  EDIT_LIST_HOST_PRODUCT_SUCCESSFUL,
  EDIT_LIST_HOST_PRODUCT_FAILED,

  ADD_LIST_HOST_PRODUCT,
  ADD_LIST_HOST_PRODUCT_FAILED,
  ADD_LIST_HOST_PRODUCT_SUCCESSFUL,

  LIST_HOST_PRODUCT,
  LIST_HOST_PRODUCT_FAILED,
  LIST_HOST_PRODUCT_SUCCESSFUL,

  EDIT_IMAGE_HOST_PRODUCT,
  EDIT_IMAGE_HOST_PRODUCT_SUCCESSFUL,
  EDIT_IMAGE_HOST_PRODUCT_FAILED,

  LIST_SEARCH_HOST_PRODUCT,
  LIST_SORTBY_HOST_PRODUCT,
  LIST_LOCATION_HOST_PRODUCT,

  ENABLE_LIST_HOST_PRODUCT,
  ENABLE_LIST_HOST_PRODUCT_SUCCESSFUL,
  ENABLE_LIST_HOST_PRODUCT_FAILED

} from "./actionTypes";

export const listHostProduct = (user) => {
  return {
    type: LIST_HOST_PRODUCT,
    payload: { user },
  };
};

export const listHostProductSuccessfil = (user) => {
  return {
    type: LIST_HOST_PRODUCT_SUCCESSFUL,
    payload: user,
  };
};

export const listHostProductFailed = (user) => {
  return {
    type: LIST_HOST_PRODUCT_FAILED,
    payload: user,
  };
};

// ADD_HOST_PRODUCT
export const addListHostProduct = (user) => {
  return {
    type: ADD_LIST_HOST_PRODUCT,
    payload: { user },
  };
};

export const addListHostProductSuccessfil = (user) => {
  return {
    type: ADD_LIST_HOST_PRODUCT_SUCCESSFUL,
    payload: user,
  };
};

export const addListHostProductFailed = (user) => {
  return {
    type: ADD_LIST_HOST_PRODUCT_FAILED,
    payload: user,
  };
};


// GET_EDIT_HOST_PRODUCT
export const getListHostProduct = (user) => {
  return {
    type: GET_LIST_HOST_PRODUCT,
    payload: { user },
  };
};

export const getListHostProductSuccessfil = (user) => {
  return {
    type: GET_LIST_HOST_PRODUCT_SUCCESSFUL,
    payload: user,
  };
};

export const getListHostProductFailed = (user) => {
  return {
    type: GET_LIST_HOST_PRODUCT_FAILED,
    payload: user,
  };
};


// EDIT_HOST_PRODUCT
export const editListHostProduct = ({ id, user }) => {
  return {
    type: EDIT_LIST_HOST_PRODUCT,
    payload: { user, id },
  };
};

export const editListHostProductSuccessfil = (user) => {
  return {
    type: EDIT_LIST_HOST_PRODUCT_SUCCESSFUL,
    payload: user,
  };
};

export const editListHostProductFailed = (user) => {
  return {
    type: EDIT_LIST_HOST_PRODUCT_FAILED,
    payload: user,
  };
};


// EDIT_IMAGE_HOST_PRODUCT
export const editImageListHostProduct = (user) => {
  return {
    type: EDIT_IMAGE_HOST_PRODUCT,
    payload: { user },
  };
};

export const editImageHostProductSuccessfil = (user) => {
  return {
    type: EDIT_IMAGE_HOST_PRODUCT_SUCCESSFUL,
    payload: user,
  };
};

export const editImageHostProductFailed = (user) => {
  return {
    type: EDIT_IMAGE_HOST_PRODUCT_FAILED,
    payload: user,
  };
};


// DELETE_HOST_PRODUCT
export const deleteListHostProduct = (user) => {
  return {
    type: DELETE_LIST_HOST_PRODUCT,
    payload: { user },
  };
};

export const deleteListHostProductSuccessfil = (user) => {
  return {
    type: DELETE_LIST_HOST_PRODUCT_SUCCESSFUL,
    payload: user,
  };
};

export const deleteListHostProductFailed = (user) => {
  return {
    type: DELETE_LIST_HOST_PRODUCT_FAILED,
    payload: user,
  };
};

//LIST_SEARCH_HOST_PRODUCT

export const listSearchHostProduct = (user) => {
  return {
    type: LIST_SEARCH_HOST_PRODUCT,
    payload: { user },
  };
};

export const listLocationHostProduct = (user) => {
  return {
    type: LIST_LOCATION_HOST_PRODUCT,
    payload: { user },
  };
};

export const listSortByHostProduct = (user) => {
  return {
    type: LIST_SORTBY_HOST_PRODUCT,
    payload: { user },
  };
};

export const productEnable = ({ id, user }) => {
  return {
    type: ENABLE_LIST_HOST_PRODUCT,
    payload: { user, id },
  };
};

export const productEnableSuccess = (user) => {
  return {
    type: ENABLE_LIST_HOST_PRODUCT_SUCCESSFUL,
    payload: { user },
  };
};

export const productEnableFailed = (user) => {
  return {
    type: ENABLE_LIST_HOST_PRODUCT_FAILED,
    payload: { user },
  };
};