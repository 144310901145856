import {
    GET_PROMO_CODE_DETAILS,
    GET_PROMO_CODE_DETAILS_SUCCESSFUL,
    GET_PROMO_CODE_DETAILS_FAILED,
    GET_PROMO_CODE_DETAILS_RESET
} from "./actionTypes";


export const getPromoCodeDetails = user => {
    return {
        type: GET_PROMO_CODE_DETAILS,
        payload: { user },
    }
}

export const getPromoCodeDetailsSuccessful = user => {
    return {
        type: GET_PROMO_CODE_DETAILS_SUCCESSFUL,
        payload: user,
    }
}

export const getPromoCodeDetailsFailed = user => {
    return {
        type: GET_PROMO_CODE_DETAILS_FAILED,
        payload: user,
    }
}

export const getPromoCodeDetailsReset = user => {
    return {
        type: GET_PROMO_CODE_DETAILS_RESET,
        payload: user,
    }
}
