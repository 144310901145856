import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hostListProduct, 
  hostListSort
} from "../../../../Redux/host_redux/actions";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../config";
import { STATIC_PRODUCT_LINK, STATIC_PRODUCT } from "../../../../helpers/enum"

const ProductListComponent = ({
  page,
  search_string,
  product_location,
  sort_string,
}) => {

  //*state
  const [isShowLoader, setIsShowLoader] = useState(false);

  //*list
  const [mappedData, setMappedData] = useState([]);

  const user = useSelector(state => state?.HostListProduct?.user);
  const loading = useSelector(state => state?.HostListProduct?.loading);
  const success = useSelector(state => state?.HostListProduct?.success);
  const isSort = useSelector(state => state?.HostListProduct?.isSort);

  const dispatch = useDispatch();
  const { hid } = useParams();

  useEffect(() => {
    if (user && !loading) {
      let productListData = user?.docs
      if (productListData.length > 0 || productListData !== undefined) {
        if (user.page === 1) {
          setMappedData(productListData);
          setIsShowLoader(true);
        } else {
          setMappedData((prevProducts) => [...prevProducts, ...productListData]);
          setIsShowLoader(true);
        }
      } else {
        setMappedData([]);
        setIsShowLoader(true);
      }

    }
  }, [success, isSort]);

  useEffect(() => {
    if (hid) {
      const decodedId = atob(hid);
      // let userId = state.decodedId
      let dataObj = { "page": 1, "user_id": decodedId }
      setIsShowLoader(false);
      dispatch(hostListSort(dataObj));
    } else {
      dispatch(hostListProduct({ page: 1 }));
    }
  }, [hid]);

  const fetchMoreData = () => {
    if (user && user?.hasNextPage) {
      if (user?.hasNextPage) {
        setIsShowLoader(false);
        dispatch(
          hostListProduct({
            page: user?.nextPage,
            search_string: search_string || "",
            product_location: product_location?.value || "",
            sort_string: sort_string || "created_at",
            "user_id": (hid) ? atob(hid) : undefined,
          })
        );
      } else if (user?.hasPrevPage) {
        setIsShowLoader(false);
        dispatch(
          hostListProduct({
            page: user?.prevPage,
            search_string: search_string || "",
            product_location: product_location?.value || "",
            sort_string: sort_string || "created_at",
            "user_id": (hid) ? atob(hid) : undefined,
          })
        );
      }
    }
  };

  const navigate = useNavigate();

  const handleClick = (id, item) => {
    let productType = item?.product_name
    if (productType === STATIC_PRODUCT.ENCLOSED_CARGO_TRAILER) {
      window.open(STATIC_PRODUCT_LINK.ENCLOSED_CARGO_TRAILER);
    } else {
      navigate(`/productdetails/${id}`);
    }

  };

  return (
    <section className="pyCustom-wrapper product-not-found">
      <div className="container">
        {isShowLoader === false &&
          <div id="loader" data-wordload="Please Wait"></div>
        }
        <InfiniteScroll
          dataLength={mappedData.length}
          next={fetchMoreData}
          hasMore={user && user.hasNextPage}
          style={{ overflow: "hidden" }}
        >
          {
            <div className="row g-4  productsListing-block">
              {mappedData.length > 0 ?
                mappedData.map((item, index) => {

                  const totalRatings = item?.reviews_data?.reduce(
                    (sum, rating) => sum + rating.rate,
                    0
                  );

                  const averageRating =
                    item?.reviews_data?.length > 0
                      ? totalRatings / item?.reviews_data?.length
                      : 0;

                  const filledStars = Math.floor(averageRating);
                  const emptyStars = 5 - filledStars;

                  const stars = [];
                  for (let i = 0; i < filledStars; i++) {
                    stars.push(
                      <i
                        key={i}
                        className="fa-solid fa-star"
                        style={{ color: "#FFA500", fontSize: "18px" }}
                      ></i>
                    );
                  }
                  for (let i = 0; i < emptyStars; i++) {
                    stars.push(
                      <i
                        key={i + filledStars}
                        className="fa-regular fa-star"
                        style={{ fontSize: "18px" }}
                      ></i>
                    );
                  }
                  return (
                    <div className="col-lg-4 col-sm-6" key={index}>
                      <div className="products-block bg-white">
                        <div className="ratio cover">
                          {
                            item?.images_detail?.length > 0 ?
                              <img
                                src={`${item?.images_detail[0]?.path}`}
                                alt={item?._id + '_' + index}
                                onError={event => {
                                  event.target.src = '/images/default_image.webp'
                                  event.onerror = null
                                }}
                              /> :
                              <img
                                src="/images/default_image.webp"
                                alt={item?._id + '_' + index}
                                onError={event => {
                                  event.target.src = '/images/default_image.webp'
                                  event.onerror = null
                                }}
                              />
                          }
                          {item?.is_booked ? (
                            <span className="book-status">Booked</span>
                          ) : null}
                        </div>
                        <div className="text-center">
                          {item?.product_name?.length > 15 ? (
                            <h5 className="product-break-word">{item?.product_name}</h5>
                          ) : (
                            <h5>{item?.product_name}</h5>
                          )}
                          <div className="rating">
                            {stars}
                            <span>({item?.reviews_data?.length})</span>
                          </div>
                          <h4>
                            ${item?.price_per_day} <span>/ Day</span>
                          </h4>
                          <button
                            onClick={() => handleClick(item?._id, item)}
                            className="btn btn-green"
                          >
                            <span>RENT IT</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }) :
                <div className="heading flex center">
                  <h2>
                    {"No data found"}
                  </h2>
                </div>
              }
            </div>
          }
        </InfiniteScroll>
      </div>
    </section>
  );
};

export default ProductListComponent;
