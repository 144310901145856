import { call, put, takeEvery } from "redux-saga/effects";
import { API_CODE } from "../../helpers/enum";
import {postProductDetails } from "../../helpers/fakebackend_helper";
import { productDetailFaield, productDetailSuccessful } from "./action";
import { PRODUCT_DETAILS } from "./actionTypes";

function* productDetail({ payload: { user } }) {

  try {
    const response = yield call(postProductDetails, user);

    if (response.statusCode === API_CODE.SUCCESS) {
      yield put(productDetailSuccessful(response.data));
    } else {
      yield put(productDetailFaield(response.message));
    }
  } catch (error) {
    yield put(productDetailFaield(error));
  }
}

function* ProductDetailSaga() {
  yield takeEvery(PRODUCT_DETAILS, productDetail);
}

export default ProductDetailSaga;
