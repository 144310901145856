import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const About = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => { setIsModalOpen(true); };
  const closeModal = () => { setIsModalOpen(false); };

  useEffect(() => {
    const videoIframe = document.getElementById('videoIframe');
    const videoModal = document.getElementById('exampleModal');
    const handleModalHide = () => {
      // Pause the video when the modal is hidden
      if (videoIframe && videoIframe.contentWindow) {
        videoIframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    if (isModalOpen) {
      // Add event listener when modal becomes visible
      if (videoModal) {
        // Add event listener when modal becomes hidden
        videoModal.addEventListener('hidden.bs.modal', handleModalHide);
      }
    }

    return () => {
      // Remove event listener on component unmount
      if (videoModal) {
        // Add event listener when modal becomes hidden
        videoModal.addEventListener('hidden.bs.modal', handleModalHide);
      }
    };
  }, [isModalOpen]);

  return (
    <div>
      <section className="about-wrapper py-wrapper">
        <div className="container">
          <div className="row g-xl-5 g-4">
            <div className="col-lg-5 pe-xl-0">
              <div className="heading">
                <h2>
                  {/* About <span>Us</span> */}Welcome to <span>OutdoorShare</span> LLC!
                </h2>
              </div>
              <div className="content mb-md-5 mb-4">
                <p>
                  We make it possible for everyone to enjoy all that the
                  outdoors has to offer.
                </p>
                <p>
                  This is an innovative platform designed to connect outdoor
                  enthusiasts from all walks of life by helping others share
                  what they have with others.
                  <br />
                  With OutdoorShare, now <b>everyone</b> can enjoy the outdoors in more
                  ways than anyone ever thought possible. <br />
                  With OutdoorShare <b>you will</b> Find New Boundaries
                </p>
                <p>
                  OutdoorShare is more than just a platform; it's a
                  community-driven ecosystem that celebrates the joy of outdoor
                  exploration, fosters connections, and creates memories.
                  Whether you're embarking on a solo expedition, planning a
                  family camping trip, or seeking adventure with a group of
                  friends, let OutdoorShare be your trusted companion on your
                  outdoor journey.
                  <br />
                  Join OutdoorShare today and unlock a world of outdoor
                  possibilities!
                </p>
              </div>
              <Link to={"/about"} className="btn btn-green">
                <span>LEARN MORE</span>
              </Link>
            </div>
            <div className="col-lg-7">
              <div className="about-img ps-xl-4 ms-xl-2 ">
                <img src="images/about-1.webp" alt="about-1_image" className="z1" />
                <div className="about-bg img-obj cover">
                  <img src="images/homePageThumbnail.jpg" alt="about-2_image" />
                  <Link
                    href="#"
                    className="play-btn flex-all"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={openModal}
                  >
                    <i className="fa-solid fa-play flex-all"></i>
                    <span>CLICK TO PLAY</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}

        <div
          className={`modal modal-video fade ${isModalOpen ? 'show' : ''}`}
          id="exampleModal"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <i className="fa-regular fa-xmark"></i>
              </button>
              <div className="modal-body p-0">
                <div className="ratio ratio-16x9">
                  {isModalOpen && (
                    <iframe
                      id="videoIframe"
                      src="https://www.youtube.com/embed/NDWDtOK_dF0?ab_channel=OutdoorShare"
                      // src="https://www.youtube.com/embed/YOdNvSHMhrg"
                      title="YouTube video player"
                      allowFullScreen
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
