import { faDisplay } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const Review = ({ rating, allReviews }) => {

  const { averageRating, totalReview } = rating;

  const filledStars = Math.round(averageRating * 2) / 2;

  const emptyStars = 5 - filledStars;

  const stars = [];

  // Add filled stars
  for (let i = 0; i < filledStars; i++) {
    stars.push(
      <i
        key={i}
        className="fa-solid fa-star"
        style={{ color: "#FFA500", fontSize: "18px" }}
      ></i>
    );
  }

  // Add empty stars
  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <i
        key={i + filledStars}
        className="fa-regular fa-star"
        style={{ fontSize: "18px" }}
      ></i>
    );
  }

  return (
    <section className="reviews-wrapper pyCustom-wrapper bg-lightTheme ">
      <div className="container">
        <h3>Reviews</h3>
        <div className="rating">
          {stars}&nbsp;
          <span>({totalReview} Reviews)</span>
        </div>
        <div className="mb-md-5 pb-xl-2 mb-4">
          {allReviews &&
            allReviews.map((review, index) => {
              const firstLetter =
                review?.renter_data?.first_name?.charAt(0) || "M";

              return (
                <div className="reviews-block" key={index}>
                  <div className="ratio ratio-1x1 rounded-circle">
                    <div className="flex-all">{firstLetter}</div>
                  </div>
                  <div>
                    <div className="reviews-name">
                      {review?.renter_data?.first_name.length > 15  || review?.renter_data?.last_name.length > 15 ? (
                          <h6 className="header-break-word">{review?.renter_data?.first_name}{' '} {review?.renter_data?.last_name}</h6>
                      ) : (
                          <h6>{review?.renter_data?.first_name}{' '} {review?.renter_data?.last_name}</h6>
                      )}
                    </div>
                    <p>{review?.text}</p>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="text-center" style={{ display: "none" }}>
          <button className="btn btn-green">
            <span>SEE ALL REVIEWS</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Review;
