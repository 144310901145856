import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { SEND_MESSAGE } from "./actionTypes";
import { sendMessageDetailsSuccessful, sendMessageDetailsFailed, } from "./action";
import { postSendMessage } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* sendMsgDetailsOnClick({ payload: { user } }) {
    try {
        const response = yield call(postSendMessage, user);
        yield put(sendMessageDetailsSuccessful(response.message));
    } catch (error) {
        yield put(sendMessageDetailsFailed(error));
    }
}

export function* watchOnSendMsg() {
    yield takeEvery(SEND_MESSAGE, sendMsgDetailsOnClick);
}

function* SendMsgSaga() {

    yield all([
        fork(watchOnSendMsg),
    ]);
}

export default SendMsgSaga;
