import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { renterOrderList } from "../../../../Redux/renter/orderList/action"
import ProfileCard from '../../../Pages/Renter/ProfileCard';
import { renterUpdateReviewDetailsReset } from "../../../../Redux/renter/updateReview/action"
import { renterAddReviewDetailsReset } from "../../../../Redux/renter/addReview/action"
import { signRenterAgreementReset } from "../../../../Redux/common/updateRenterSignAgreement/action"
import { cancelOrder } from "../../../../Redux/actions";
import { clearLocalStorage } from '../../../../helpers/commonfunction'
import { ORDER_STATUS, ORDER_PAYMENT_STATUS } from '../../../../helpers/enum'
import Modal from 'react-modal';

const RenterViewOrder = () => {

  const dispatch = useDispatch();
  const navigation = useNavigate();

  //*List
  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowLoader, setIsShowLoader] = useState(false);

  const [orderId, setOrderId] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const OrderError = useSelector(state => state?.RenterOrderReducer?.OrderError);
  const loading = useSelector(state => state?.RenterOrderReducer?.loading);
  const orderListData = useSelector(state => state?.RenterOrderReducer?.orderListData);
  const isOrderSuccess = useSelector(state => state?.RenterOrderReducer?.isOrderSuccess);
  const isOrderError = useSelector(state => state?.RenterOrderReducer?.isOrderError);

  const isCancelOrderSuccess = useSelector(state => state?.CancelOrderReducer?.isCancelOrderSuccess);
  const isCancelOrderLoading = useSelector(state => state?.CancelOrderReducer?.isCancelOrderLoading);

  useEffect(() => {
    let dataObj = { "page": currentPage }
    setIsShowLoader(false);
    dispatch(renterOrderList(dataObj));
  }, [currentPage, isCancelOrderSuccess])

  useEffect(() => {
    if (!loading && orderListData) {
      var orderListResponse = orderListData?.finalArray;
      if (orderListResponse.length > 0) {
        setIsShowLoader(true);
        setOrderList(orderListResponse);
      } else {
        setIsShowLoader(true);
        setOrderList([]);
      }
    }
  }, [isOrderSuccess]);

  //*expire token
  useEffect(() => {
    if (isOrderError && !loading) {
      if (OrderError === "Request failed with status code 401") {
        navigation("/login");
        clearLocalStorage();
        toast.error('JWT Expire.');
      }
    }
  }, [isOrderError, OrderError]);

  useEffect(() => {
    if(isCancelOrderSuccess) {
      setShowModal(false)
    }
  }, [isCancelOrderSuccess])

  const onCancelOrder = () => {
    dispatch(cancelOrder({
      order_id: orderId
    }))
  }

  //* Navigate to order Details
  const onClickOrderDetails = (orderId, productId) => {
    localStorage.setItem("orderIdForRenter", orderId);
    localStorage.setItem("productIdForRenter", productId);
    navigation("/renter/orderdetails");
    dispatch(renterUpdateReviewDetailsReset());
    dispatch(renterAddReviewDetailsReset());
  };

  return (
    <div>
      <section className="innerBanner-wrapper">
        <div className="innerBanner-img img-obj cover">
          <img src="images/header.png" alt="header_image" />
        </div>
        <div className="container h-100 z1 flex-all">
          <h1>Orders</h1>
        </div>
      </section>
      <section className="profile-wrapper py32-wrapper profile-order-btn px-18 order-not-found">
        <div className="container-fluid">
          <div className="row g-4">

            <ProfileCard />
            <div className="col-md-9 col-sm-8 profile-main">
              <div className="card ms-xl-1">
                <div className="card-header">
                  <h3>ORDERS</h3>
                </div>
                <div className="card-body">
                  {isShowLoader === false &&
                    <div id="loader" data-wordload="Please Wait"></div>
                  }
                  <div className="mb-4">
                      {
                        orderList.map((item, index) => {
                          return (
                            orderList.length > 0 ?
                              <div className="container mt-4 border p-3" key={index}>
                                <div className="d-flex justify-content-between">
                                  <div className="fw-bold fs-4">{item?.productName}</div>
                                  <div className="fw-bold fs-4">${item?.price}/ Day</div>
                                </div>

                                <hr />
                                <div className="row mb-md-1">
                                  <div className="col-12 col-md-6 mb-1 mb-md-0">
                                    <span className="fw-bold">Order ID:</span> {item?.orderID}
                                  </div>
                                  <div className="col-12 col-md-6 mb-1 mb-md-0">
                                    <span className="fw-bold">Order Status:</span>{" "}
                                    {item?.orderStatus === ORDER_STATUS.PENDING ? (
                                      <div className="status status-progress">Deposit Amount Paid</div>
                                    ) : item?.orderStatus === ORDER_STATUS.ACCEPTED ? (
                                      <div className="status status-accept">Accepted</div>
                                    ) : item?.orderStatus === ORDER_STATUS.REJECTED ? (
                                      <div className="status status-danger">Rejected</div>
                                    ) : item?.orderStatus === ORDER_STATUS.RENTER_AGREEMENT ? (
                                      <div className="status status-accept">Renter Agreement</div>
                                    ) : item?.orderStatus === ORDER_STATUS.CHECK_OUT ? (
                                      <div className="status status-checkout">Check out</div>
                                    ) : item?.orderStatus === ORDER_STATUS.FINAL_AMOUNT_AUTO_DEDUCT ? (
                                      <div className="status status-success">Final Amount Auto Deduct</div>
                                    ) : item?.orderStatus === ORDER_STATUS.CHECK_IN ? (
                                      <div className="status status-checkin">Check in</div>
                                    ) : item?.orderStatus === ORDER_STATUS.RETURN_DEPOSIT_ACCEPTED ? (
                                      <div className="status status-success">Deposit Accepted</div>
                                    ) : item?.orderStatus === ORDER_STATUS.RETURN_DEPOSIT_REJECTED ? (
                                      <div className="status status-danger">Deposit Rejected</div>
                                    ) : item?.orderStatus === ORDER_STATUS.CANCELLED ? (
                                      <div className="status status-danger">Cancelled</div>
                                    ) : (
                                      <div className="status status-success">Final Amount Paid</div>
                                    )}
                                  </div>
                                </div>
                                <div className="row mb-md-1">
                                  <div className="col-12 col-md-6 mb-1 mb-md-0">
                                    <span className="fw-bold">Host Name:</span> {item?.userName}
                                  </div>
                                  <div className="col-12 col-md-6 mb-1 mb-md-0">
                                    <span className="fw-bold">Payment Status:</span>{" "}
                                    {item?.paymentStatus === ORDER_PAYMENT_STATUS.PAID ? (
                                      <div className="status status-success">Paid</div>
                                    ) : item?.paymentStatus === ORDER_PAYMENT_STATUS.SECURITY_DEPOSIT_REFUNDED ? (
                                      <div className="status status-progress">Security Deposit Refunded</div>
                                    ) : item?.paymentStatus === ORDER_PAYMENT_STATUS.SUCCEEDED ? (
                                      <div className="status status-success">Succeeded</div>
                                    ) : (
                                      <div className="status status-danger">Pending</div>
                                    )}
                                  </div>
                                </div>
                                <div className="row mb-1">
                                  <div className="col-12">
                                    <span className="fw-bold">Date:</span> {item?.date}
                                  </div>
                                </div>

                            <div className="row d-flex justify-content-end order-buttons">
                            
                            <button
                              onClick={() => {
                                localStorage.setItem("userIdHost", item?.userId);
                                dispatch(signRenterAgreementReset());
                                onClickOrderDetails(item?.realOrderID, item?.productId);
                              }}
                              className="btn btn-warning btn-icon"
                              title="View Order Details"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            // data-bs-toggle="modal"
                            // data-bs-target="#deleteModal"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8S1 12 1 12z" />
                                <circle cx="12" cy="12" r="3" />
                              </svg>

                            </button>
                            <button
                              onClick={() => {
                                setOrderId(item?.realOrderID)
                                setShowModal(true);
                              }}
                              disabled={
                                !(
                                  item?.orderStatus === ORDER_STATUS.PENDING || 
                                  item?.orderStatus === ORDER_STATUS.ACCEPTED || 
                                  item?.orderStatus === ORDER_STATUS.RENTER_AGREEMENT
                                )
                              }
                              className="btn btn-danger btn-icon"
                              title="Cancel Order"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                              </svg>

                            </button>
                            </div>

                              </div> :
                              <div className="heading flex center">
                                <h2>
                                  {"No data available"}
                                </h2>
                              </div>
                          )
                        })
                      }
                  </div>
                  {
                    orderList.length > 0 &&
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li
                          className={`page-item ${!orderListData?.hasNextPage ? "disabled" : ""
                            }`}
                        >
                          <button
                            className="page-link prev-link"
                            aria-label="Previous"
                            onClick={() => setCurrentPage(currentPage - 1)}
                          >
                            <span aria-hidden="true">
                              <i className="fa-solid fa-chevron-left"></i>
                            </span>
                          </button>
                        </li>
                        {Array.from({ length: orderListData?.totalPages }, (_, index) => (
                          <li
                            key={index}
                            className={`page-item ${currentPage === index + 1 ? "active" : ""
                              }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                        <li
                          className={`page-item ${!orderListData?.hasNextPage ? "disabled" : ""
                            }`}
                        >
                          <button
                            className="page-link next-link"
                            aria-label="Next"
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                            <span aria-hidden="true">
                              <i className="fa-solid fa-chevron-right"></i>
                            </span>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={showModal}
        onRequestClose={() => { setShowModal(false) }}
        style={{
          content: {
            width: "430px", height: "190px", top: "50%", left: "50%", transform: "translate(-50%,-50%)",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "top",
          },
        }}
      >
        <div class="container">
          <h3>ORDER CANCELLATION</h3>
          <div>Canceling an order may result in a partial or no refund, as determined by our cancellation policy.</div>
          
          <div class="d-flex mt-3 cancel-order-buttons">
            <button
              onClick={onCancelOrder}
              class="btn btn-danger me-2"
              disabled={isCancelOrderLoading}
            >
              Yes
            </button>
            <button
              onClick={() => { setShowModal(false) }}
              class="btn btn-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RenterViewOrder;
