import {
    SIGN_RENTER_AGREEMENT,
    SIGN_RENTER_AGREEMENT_SUCCESSFUL,
    SIGN_RENTER_AGREEMENT_FAILED,
    SIGN_RENTER_AGREEMENT_RESET
} from "./actionTypes";


export const signRenterAgreement = user => {
    return {
        type: SIGN_RENTER_AGREEMENT,
        payload: { user },
    }
}

export const signRenterAgreementSuccessful = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_SUCCESSFUL,
        payload: user,
    }
}

export const signRenterAgreementFailed = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_FAILED,
        payload: user,
    }
}

export const signRenterAgreementReset = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_RESET,
        payload: user,
    }
}
