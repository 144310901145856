import React from 'react'
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";

const UserNotFound = () => {
  return (
    <div>
      <Header />
      <h2>User not verified</h2>
      <Footer />
    </div>
  )
}

export default UserNotFound
